import styled from 'styled-components'

export const List = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	> li + li {
		margin-top: 16px;
	}
`

export const ListItem = styled.li`
	display: block;
`

export const Meta = styled.div`
	opacity: 0.8;
	font-size: 80%;
`
