import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import dayjs from 'dayjs'

import Container from 'src/components/Container'
import LayoutwithSidebar from 'src/components/LayoutWithSidebar'
import Sidebar from 'src/components/Sidebar'
import { useGetAllEventsQuery, useDeleteEventMutation, GetAllEventsDocument } from 'shared/schema'
import { Filters, CalendarIcon, EditActions, Action, ActionLink } from './styled'
import Filter from './filter'
import { mapValues, some, pickBy, keys } from 'lodash'
import { EVENT_TYPES } from './defs'
import CreateNewEvent from './form'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import { useModal } from 'src/components/Modal'
import RichText from 'src/components/RichText'
import { RoleGate, useUserRoles } from 'src/components/Roles'

const locales = {
	'en-US': enUS
}

const localizer = dateFnsLocalizer({
	format,
	parse,
	startOfWeek,
	getDay,
	locales
})

const calendarSidebarLinks = [
	{ title: 'Calendar', to: '/calendar' },
	{ title: 'Create New Event', to: '/calendar/publish', adminOnly: true }
]

function CalendarRoute() {
	const [eventDetail, setEventDetail] = useState(null)
	const { useRole } = useUserRoles()
	const [deleteEvent] = useDeleteEventMutation({
		refetchQueries: [{ query: GetAllEventsDocument }],
		awaitRefetchQueries: true
	})
	const [eventDetailModal, setShowingEventDetailModal] = useModal({
		header: eventDetail?.title,
		children: eventDetail && (
			<>
				<div style={{ color: '#868686' }}>
					<CalendarIcon /> {dayjs(eventDetail.start).format(eventDetail.allDay ? 'LL' : 'LLL')}
					{' - '}
					{dayjs(eventDetail.end).format(eventDetail.allDay ? 'LL' : 'LLL')}
				</div>
				{eventDetail.type !== 'PTO' && (
					<RoleGate role={['SuperAdmin', 'Admin']}>
						<EditActions>
							<Action to={`edit/${eventDetail.id}`}>Edit</Action>
							<ActionLink
								onClick={async () => {
									if (confirm('Are you sure you want to delete this event?')) {
										await deleteEvent({
											context: {
												useRole
											},
											variables: {
												id: eventDetail.id
											}
										})
										setShowingEventDetailModal(false)
									}
								}}
							>
								Delete
							</ActionLink>
						</EditActions>
					</RoleGate>
				)}
				<div style={{ paddingTop: '2rem' }}>
					{eventDetail.description?.length > 0 ? (
						<RichText html={eventDetail.description} />
					) : (
						'This event does not have a description'
					)}
				</div>
			</>
		)
	})

	const [filters, setFilters] = useState(mapValues(EVENT_TYPES, () => false))

	const { data: eventsData } = useGetAllEventsQuery()

	const showTypes = !some(filters) ? keys(EVENT_TYPES) : keys(pickBy(filters))

	const events = (eventsData?.events ?? [])
		.map((d) => {
			return {
				id: d.id,
				title: d.title,
				start: dayjs(d.startsAt).toDate(),
				end: dayjs(d.endsAt).toDate(),
				allDay: d.allDay,
				type: d.type,
				description: d.description
			}
		})
		.filter((d) => showTypes.includes(d.type))

	const eventPropGetter = (ev) => {
		const style = {
			backgroundColor: ev.type === 'ADPPTO' ? 'red' : EVENT_TYPES[ev?.type]?.color
		}
		return { style }
	}

	return (
		<Container>
			{eventDetailModal}
			<LayoutwithSidebar>
				<Sidebar links={calendarSidebarLinks}>
					<Filters>
						<h3>Calendar Filters</h3>
						{Object.entries(EVENT_TYPES).map(([eventType, eventTypeConfig]) => (
							<Filter
								toggle={() => {
									setFilters({
										...filters,
										[eventType]: !filters[eventType]
									})
								}}
								toggled={filters[eventType]}
								key={eventType}
								title={eventTypeConfig.label}
								color={eventTypeConfig.color}
							/>
						))}
					</Filters>
				</Sidebar>
				<Calendar
					localizer={localizer}
					events={events}
					startAccessor="start"
					endAccessor="end"
					eventPropGetter={eventPropGetter}
					onSelectEvent={async (event) => {
						await setEventDetail(event)
						await setShowingEventDetailModal(true)
					}}
					style={{ height: 800, width: 1200 }}
					views={{ month: true, week: true, day: true }}
				/>
			</LayoutwithSidebar>
		</Container>
	)
}

function CalendarForm() {
	return (
		<Container>
			<LayoutwithSidebar>
				<Sidebar links={calendarSidebarLinks} />
				<CreateNewEvent />
			</LayoutwithSidebar>
		</Container>
	)
}

function GalleryRoute() {
	return (
		<Container>
			<Routes>
				<Route path="/" element={<CalendarRoute />} />
				<Route path="/publish" element={<CalendarForm />} />
				<Route path="/edit/:eventId" element={<CalendarForm />} />
			</Routes>
		</Container>
	)
}

export default GalleryRoute
