import styled from 'styled-components'

import Container from 'src/components/Container'
import HeroImage from './hero-quote.jpg'

export const HeroQuoteBackground = styled.div`
	position: relative;
	width: 100%;
	height: 320px;
	margin: 50px 0px;
	background-size: cover;
	background-position: center;
	background-image: url(${HeroImage});
	&:before {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.7);
	}
`

export const QuoteContainer = styled(Container)`
	height: 320px;
	position: relative;
	z-index: 2;
	div {
		width: 400px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	p {
		font-size: 28px;
		margin: 0;
		color: #fff;
		font-weight: 300;
	}
	h4 {
		font-weight: 300;
		font-size: 23px;
		margin-bottom: 10px;
		&:before {
			content: '';
			display: block;
			width: 50px;
			height: 4px;
			margin-bottom: 30px;
		}
	}
`

export const HeroQuotePrimary = styled.div`
	left: 0;
	padding: 45px 30px;
	background: ${({ theme }) => theme.colors.primary};
	h4 {
		color: ${({ theme }) => theme.colors.white};
		opacity: 0.7;
		&:before {
			background: rgba(255, 255, 255, 0.5);
		}
	}
`

export const HeroQuoteSecondary = styled.div`
	left: 460px;
	padding: 30px;
	background: ${({ theme }) => theme.colors.primaryTextColor};
	h4 {
		color: ${({ theme }) => theme.colors.primary};
		&:before {
			background-color: ${({ theme }) => theme.colors.primary};
			opacity: 0.7;
		}
	}
`

export const CoreValuesContainer = styled.div`
	color: ${({ theme }) => theme.colors.primaryTextColor};
	h3 {
		line-height: 36px;
		font-size: 28px;
		font-weight: 400;
		margin-bottom: 20px;
	}
`

export const CoreValues = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`
export const CoreValue = styled.div`
	width: 48%;
	padding-bottom: 60px;
	h4 {
		color: ${({ theme }) => theme.colors.primary};
		line-height: 35px;
		font-size: 30px;
		font-weight: 300;
		&:before {
			content: '';
			display: block;
			width: 50px;
			height: 4px;
			margin-bottom: 30px;
			background: ${({ theme }) => theme.colors.primary};
		}
	}
	p {
		line-height: 30px;
		font-size: 16px;
		margin-top: 10px;
	}
`
