import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

const httpLink = new HttpLink({ uri: process.env.GQL_API_URL })

const authMiddleware = new ApolloLink((operation, forward) => {
	const context = operation.getContext()
	const roleHeaders = context.useRole ? { 'x-hasura-role': context.useRole } : {}
	operation.setContext(({ headers = {} }) => {
		const accessToken = localStorage.getItem('accessToken')
		return {
			headers: {
				...headers,
				...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
				...roleHeaders
			}
		}
	})

	return forward(operation)
})

const errorLink = onError(({ graphQLErrors }) => {
	if (graphQLErrors?.some((error) => error?.extensions?.code === 'invalid-jwt')) {
		window.dispatchEvent(new Event('invalid-jwt'))
	}
})

const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: from([errorLink, authMiddleware, httpLink])
})

export default client
