import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const NewsDetailContainer = styled.div`
	img.article-image {
		width: 40%;
		float: right;
		padding: 0px 20px 20px 10px;
	}
`

export const NewsContentContainer = styled.div`
	font-weight: 300;
	line-height: 25px;
`

export const NewsByline = styled.div`
	display: flex;
	color: ${({ theme }) => theme.colors.lightestGray};
	padding-bottom: 1rem;
	img {
		width: 40px;
		padding-left: 0px;
		padding-right: 20px;
	}
	span {
		line-height: 40px;
		padding-right: 10px;
	}
`

export const NewsEditActions = styled.div`
	display: flex;
	padding-bottom: 1rem;
`

export const NewsAction = styled(NavLink)`
	text-decoration: none;
	color: ${({ theme }) => theme.colors.primary};
	line-height: 40px;
	padding-right: 20px;
`

export const NewsActionLink = styled.a`
	text-decoration: none;
	cursor: pointer;
	color: ${({ theme }) => theme.colors.primary};
	line-height: 40px;
	padding-right: 20px;
`

export const ResourcesHeader = styled.h2`
	color: ${({ theme }) => theme.colors.primaryTextColor};
	font-size: 20px;
	font-weight: lighter;
	padding-bottom: 1rem;
`
