import styled from 'styled-components'

const Container = styled.div`
	min-width: var(--container-min-width);
	max-width: var(--container-max-width);
	margin: 0 auto;
	padding-left: var(--grid-basis);
	padding-right: var(--grid-basis);
`

export default Container
