import React from 'react'
import styled from 'styled-components'

const StyledSelect = styled.select`
	background-color: white;
	border: 1px solid ${({ theme }) => theme.colors.lightGray};
	padding: 10px;
	border-radius: 3px;
`

function Select({ children, ...props }: React.ComponentPropsWithoutRef<'select'>) {
	return <StyledSelect {...props}>{children}</StyledSelect>
}

export default Select
