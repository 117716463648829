import styled from 'styled-components'
import { Pencil, Trash } from 'src/icons'

export const GalleryGrid = styled.div`
	display: flex;
	flex-wrap: wrap;
`
export const GalleryCardImage = styled.div`
	height: 100%;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	transition: all 0.5s;
	&:before {
		content: '';
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.4);
	}
`
export const GalleryPhotoImage = styled(GalleryCardImage)`
	&:before {
		background-color: rgba(0, 0, 0, 0);
	}
`
export const GalleryCardContent = styled.div`
	text-align: center;
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.3);
	user-select: none;
`

export const GalleryCardTitle = styled.div`
	color: #ffffff !important;
	font-size: 150%;
	margin-bottom: 6px;
	padding-left: 12px;
	padding-right: 12px;
`
export const GalleryCardSubTitle = styled.div`
	color: #cccccc !important;
	font-size: 90%;
`
export const GalleryButtonsContainer = styled.div`
	display: none;
	justify-content: space-between;
	height: 55px;
	width: 40px;
	position: absolute;
	top: 0;
	right: 0;
	padding-top: 15px;
	padding-right: 15px;
`

export const GalleryCardBase = styled.div`
	flex-grow: 0;
	height: 200px;
	overflow: hidden;
	position: relative;
	cursor: pointer;

	&:hover ${GalleryCardImage} {
		transform: scale(1.1);
	}
	&:hover ${GalleryButtonsContainer} {
		display: flex;
	}
`
export const GalleryCard = GalleryCardBase

export const GalleryPhotoCard = styled(GalleryCardBase)`
	cursor: pointer;
`

export const Edit = styled(Pencil)`
	width: 15px;
	height: 15px;
	color: #fff;
	vertical-align: top;
`

export const Delete = styled(Trash)`
	width: 15px;
	height: 15px;
	color: #fff;
`

export const GalleryHeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
`
