import React, { useState, useRef } from 'react'
import ReactQuill from 'react-quill'
import ReactCrop from 'react-image-crop'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { useNavigate } from 'react-router'
import { Paperclip } from 'src/icons'
import 'react-quill/dist/quill.snow.css'
import 'react-image-crop/dist/ReactCrop.css'
import FormLabel from 'src/components/FormLabel'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import styles from './styles.module.scss'
import _ from 'lodash'

import {
	FormikField,
	FormikCheckboxField,
	FormFieldWrapper,
	FileInputField,
	DateTimeInputsContainer,
	ButtonsContainer
} from './styled'
import Button from 'src/components/Button'

const CheckboxFormLabel = styled(FormLabel)`
	display: flex;
	align-items: center;
`

const PaperclipIcon = styled(Paperclip)`
	width: 20px;
	height: 20px;
	margin-right: 10px;
`

const FlexContainer = styled.div`
	display: flex;
	align-items: center;
	label {
		margin-right: 10px;
	}
`

function getCroppedImg(image, crop) {
	const canvas = document.createElement('canvas')
	const scaleX = image.naturalWidth / image.width
	const scaleY = image.naturalHeight / image.height
	canvas.width = crop.width
	canvas.height = crop.height
	const ctx = canvas.getContext('2d')

	const pixelRatio = window.devicePixelRatio
	canvas.width = crop.width * pixelRatio
	canvas.height = crop.height * pixelRatio
	ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
	ctx.imageSmoothingQuality = 'high'

	ctx.drawImage(
		image,
		crop.x * scaleX,
		crop.y * scaleY,
		crop.width * scaleX,
		crop.height * scaleY,
		0,
		0,
		crop.width,
		crop.height
	)

	return new Promise((resolve) => {
		canvas.toBlob(
			(blob) => {
				resolve(blob)
			},
			'image/jpeg',
			1
		)
	})
}

// TODO: fix these prop types
function FormField(props: {
	type: string
	label?: string
	name?: string
	placeholder?: string
	onChange?: any
	value?: string
	className?: string
	multiple?: boolean
	disabled?: boolean
	options?: { value: string; label: string }[]
	onCancel?: any
	submitLabel?: string
	hideBorderTop?: boolean
	buttonLabel?: string
	children?: any
	autoWidth?: boolean
	image?: {
		aspect: number
		existing?: string
	}
	isMulti?: boolean
	isLoading?: boolean
	formatOptionLabel?: any
	formatCreateLabel?: any
	$hideCancel?: boolean
	$isClearable?: boolean
}) {
	const initialCrop = { aspect: props.image?.aspect }
	const imgRef = useRef(null)
	const [crop, setCrop] = useState<any>(initialCrop)
	const { setFieldValue, values, isSubmitting } = useFormikContext()
	const navigate = useNavigate()
	const [fileUploadId] = useState<string>(
		'fileUpload-' +
			Math.random()
				.toString(36)
				.replace(/[^a-z]+/g, '')
	)

	if (props.type === 'checkbox') {
		return (
			<FormFieldWrapper className={props.className}>
				<CheckboxFormLabel>
					<FormikCheckboxField {...props} />
					{props.label}
				</CheckboxFormLabel>
			</FormFieldWrapper>
		)
	}

	if (props.type === 'richText') {
		return (
			<FormFieldWrapper className={props.className}>
				<FormLabel>{props.label}</FormLabel>
				<ReactQuill
					theme="snow"
					className={styles.quill}
					style={{ backgroundColor: 'white' }}
					value={props.value}
					onChange={props.onChange}
					placeholder={props.placeholder}
				/>
			</FormFieldWrapper>
		)
	}

	if (props.type === 'select2') {
		return (
			<FormFieldWrapper className={props.className}>
				<FormLabel>{props.label}</FormLabel>
				<Select
					options={props.options}
					onChange={(value) => {
						if (props.onChange) {
							props.onChange(value)
						}
						setFieldValue(props.name, value)
					}}
					value={_.get(values, props.name)}
					isMulti={props.isMulti}
					formatOptionLabel={props.formatOptionLabel}
					isClearable={props.$isClearable}
				/>
			</FormFieldWrapper>
		)
	}
	if (props.type === 'select2-create') {
		return (
			<FormFieldWrapper className={props.className}>
				<FormLabel>{props.label}</FormLabel>
				<CreatableSelect
					options={props.options}
					placeholder={props.placeholder}
					onChange={(value) => {
						if (props.onChange) {
							props.onChange(value)
						}
						setFieldValue(props.name, value)
					}}
					isClearable
					formatOptionLabel={props.formatOptionLabel}
					formatCreateLabel={props.formatCreateLabel}
				/>
			</FormFieldWrapper>
		)
	}
	if (props.type === 'select') {
		return (
			<FormFieldWrapper className={props.className}>
				<FormLabel>{props.label}</FormLabel>
				<FormikField forwardedAs="select" {...props}>
					{props.options.map((option) => (
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					))}
				</FormikField>
			</FormFieldWrapper>
		)
	}
	if (props.type === 'file') {
		return (
			<FormFieldWrapper className={props.className}>
				<FormLabel>{props.label}</FormLabel>
				{props.image &&
					(values[props.name] ? (
						<ReactCrop
							src={values[props.name].url}
							crop={crop}
							locked
							onChange={(newCrop) => setCrop(newCrop)}
							onImageLoaded={(image) => {
								imgRef.current = image
								setCrop({
									...initialCrop,
									height: image.height
								})
								return false
							}}
							onComplete={async (crop) => {
								const blob = await getCroppedImg(imgRef.current, crop)
								const { file, url } = values[props.name]
								blob.name = file.name
								setFieldValue(props.name, {
									file: blob,
									url: url
								})
							}}
						/>
					) : props.image.existing ? (
						<img src={props.image.existing} />
					) : null)}
				<FileInputField>
					<FlexContainer>
						<FormLabel htmlFor={fileUploadId}>
							<PaperclipIcon />
							{props.buttonLabel || 'Upload'}
						</FormLabel>
						{values[props.name] && !props.multiple && <span>{values[props.name].file.name}</span>}
					</FlexContainer>
					<input
						id={fileUploadId}
						type="file"
						multiple={props.multiple}
						onChange={(e) => {
							if (props.onChange) {
								return props.onChange(e)
							}
							const fieldValue = props.multiple
								? {}
								: {
										file: e.currentTarget.files[0],
										url: URL.createObjectURL(e.currentTarget.files[0])
								  }

							setFieldValue(props.name, fieldValue)
						}}
					/>
				</FileInputField>
			</FormFieldWrapper>
		)
	}

	if (props.type === 'timeDateGroup') {
		const { allDay } = values
		return (
			<FormFieldWrapper className={props.className}>
				<FormLabel>{props.label}</FormLabel>
				<DateTimeInputsContainer>
					<FormikField name="from.date" type="date" placeholder="Select Date" />
					{!allDay && <FormikField name="from.time" type="time" placeholder="Select time" />} <span>to</span>
					<FormikField name="to.date" type="date" placeholder="Select Date" />
					{!allDay && <FormikField name="to.time" type="time" placeholder="Select Time" />}{' '}
				</DateTimeInputsContainer>
				<FormField name="allDay" type="checkbox" label="All day" />
			</FormFieldWrapper>
		)
	}

	if (props.type === 'formButtons') {
		const onClick =
			typeof props.onCancel === 'string'
				? () => navigate(props.onCancel)
				: props.onCancel || (() => navigate('..'))

		return (
			<ButtonsContainer $hideBorderTop={props.hideBorderTop}>
				<Button
					mode="secondary"
					type="submit"
					disabled={isSubmitting || props.isLoading}
					loading={isSubmitting || props.isLoading}
					$autoWidth={props.autoWidth}
				>
					{props.submitLabel || 'Submit'}
				</Button>
				{!props.$hideCancel && (
					<Button mode="tertiary" onClick={onClick} disabled={isSubmitting}>
						Cancel
					</Button>
				)}
			</ButtonsContainer>
		)
	}

	return (
		<FormFieldWrapper className={props.className}>
			{props.label && <FormLabel>{props.label}</FormLabel>}
			<FormikField {...props} />
		</FormFieldWrapper>
	)
}
export const Separator = ButtonsContainer
export default FormField
