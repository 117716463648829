import React, { useState, useEffect } from 'react'
import { Form } from 'formik'
import MobileDetect from 'mobile-detect'

import { useAuthGate } from 'src/components/AuthGate'
import { useNavigate } from 'react-router-dom'
import { usePagesSignInMastheadQuery, useIosRedemptionCodeLazyQuery } from 'shared/schema'

import signInLogo from './logo_signin.png'
import GooglePlay from './GooglePlay.png'
import AppleLogo from './apple_logo'
import { Formik } from 'formik'
import { Background, SignInContainer, CenterVertically, SignInLogo, SigninButton } from './styled'
import { useAuthenticateUserMutation } from 'shared/schema'

function SignInRoute() {
	const [idToken, setIdToken] = useState(window.__ID_TOKEN)
	const [promptNextLogin] = useState(localStorage.__promptNextLogin)
	const [authenticateUser] = useAuthenticateUserMutation({
		update(cache) {
			cache.reset()
		}
	})

	if (localStorage.__promptNextLogin) {
		delete localStorage.__promptNextLogin
	}

	const [
		getIosRedemptionCode,
		{ data: iosRedemptionCodeData, loading: iosRedemptionCodeLoading }
	] = useIosRedemptionCodeLazyQuery()
	const { setAccessToken } = useAuthGate()
	const navigate = useNavigate()
	const { data: mastheadData } = usePagesSignInMastheadQuery()

	useEffect(() => {
		if (!idToken) {
			return
		}

		delete window.__ID_TOKEN
		setIdToken(null)

		authenticateUser({
			variables: {
				username: '_MS_TOKEN',
				password: idToken
			}
		}).then(({ data }) => {
			if (data.authenticateUser.accessToken) {
				setAccessToken(data.authenticateUser.accessToken)
				navigate('/home')
			}
		})
	}, [])

	const md = new MobileDetect(window.navigator.userAgent)
	const isiOS = md.os() === 'iOS'
	const isAndroid = md.os() === 'AndroidOS'

	if (iosRedemptionCodeData?.iosRedemptionCode?.url) {
		window.location.href = iosRedemptionCodeData.iosRedemptionCode.url
	}

	return (
		<Background style={{ backgroundImage: `url(${mastheadData?.signinPage.masthead.publicUrl})` }}>
			<CenterVertically>
				<SignInContainer>
					<SignInLogo src={signInLogo} />
					<Formik>
						{() => {
							if (isiOS) {
								return (
									<div
										style={{ padding: '3rem', cursor: 'pointer', fontSize: '200%' }}
										onClick={async () => {
											if (iosRedemptionCodeLoading) {
												return
											}

											getIosRedemptionCode()
										}}
									>
										<p>Tap here to download the app:</p>
										<AppleLogo />
									</div>
								)
							}
							if (isAndroid) {
								return (
									<div style={{ padding: '3rem' }}>
										<p>Tap here to download the app:</p>
										<a href="https://play.google.com/store/apps/details?id=com.onenibbi.app">
											<img src={GooglePlay} />
										</a>
									</div>
								)
							}
							return (
								<Form>
									<SigninButton
										type="button"
										onClick={() => {
											const nonce =
												(Math.random() + 1).toString(36).substring(2) +
												(Math.random() + 1).toString(36).substring(2)
											location.href =
												'https://login.microsoftonline.com/' +
												'5c99c30a-e366-4543-b7b8-5c75fee2faae/oauth2/v2.0/authorize' +
												'?client_id=5595bc06-d9d2-40b5-9bcf-88143a0fd55d' +
												'&response_type=id_token' +
												'&redirect_uri=https%3A%2F%2Fwww.onenibbi.com' +
												'&response_mode=fragment' +
												'&scope=openid%20email' +
												`&nonce=${nonce}` +
												(promptNextLogin ? '&prompt=select_account' : '')
										}}
										style={{ marginTop: '2rem' }}
										loading={idToken}
										disabled={idToken}
									>
										Login
									</SigninButton>
								</Form>
							)
						}}
					</Formik>
				</SignInContainer>
			</CenterVertically>
		</Background>
	)
}

export default SignInRoute
