export const EVENT_TYPES = {
	NIBBI_EVENT: {
		label: 'Nibbi Event',
		color: '#EA6C37'
	},
	BIRTHDAY: {
		label: 'Birthday',
		color: '#0a079e'
	},
	INDUSTRY_EVENT: {
		label: 'Industry Event',
		color: '#19b747'
	},
	TRAINING: {
		label: 'Training',
		color: '#ffb745'
	},
	PTO: {
		label: 'PTO',
		color: '#916fcc'
	},
	HOLIDAY: {
		label: 'Holiday',
		color: '#e21717'
	},
	OAKLAND_OFFICE: {
		label: 'Oakland Office',
		color: '#f8a91c'
	}
}
