import styled from 'styled-components'

export const DirectoryContainer = styled.div`
	h1 {
		color: ${({ theme }) => theme.colors.primary};
		font-weight: lighter;
		font-size: 40px;
		line-height: 40px;
		padding-bottom: 30px;
	}
`
export const PeopleContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	h1 {
		color: ${({ theme }) => theme.colors.primaryTextColor};
		font-size: 18px;
		font-weight: 400;
		line-height: 25px;
		padding-bottom: 0;
	}
`

export const SearchFiltersContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: 20px;
	select {
		width: 40%;
		margin-right: 20px;
	}
`

export const Divider = styled.div`
	margin: 20px 0px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
`

export const SortOptionsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 40%;
	padding-bottom: 30px;
	input {
		margin-left: 30px;
	}
`
