import React from 'react'
import { Formik, Form } from 'formik'

import { useDepartment } from 'src/routes/department'
import FormField from 'src/components/FormField'
import ContentHeader from '../ContentHeader'
import { useUpdateDepartmentMutation } from 'shared/schema'
import { useUpload } from 'src/components/Upload'
import { useNavigate } from 'react-router'
import { useUserRoles } from 'src/components/Roles'

function EditDepartment() {
	const [upload] = useUpload()
	const department = useDepartment()
	const [updateDepartment] = useUpdateDepartmentMutation({
		refetchQueries: ['departmentDetails'],
		awaitRefetchQueries: true
	})
	const { useRole } = useUserRoles()
	const navigate = useNavigate()
	const prefillInitialValues = {
		title: department.title,
		image: null,
		missionStatement: department.missionStatement
	}
	return (
		<div>
			<ContentHeader>Edit Department</ContentHeader>
			<Formik
				initialValues={prefillInitialValues}
				onSubmit={async (values) => {
					let mastheadId = department.masthead?.id ?? 0
					if (values.image) {
						mastheadId = (await upload(values.image.file)).uploadId
					}
					await updateDepartment({
						context: {
							useRole
						},
						variables: {
							departmentId: department.id,
							logoUploadId: department.logo?.id ?? null,
							mastheadUploadId: mastheadId,
							missionStatement: values.missionStatement,
							title: values.title
						}
					})
					navigate(`/departments/${department.id}/overview`)
				}}
			>
				{({ values, setFieldValue }) => (
					<Form>
						<FormField name="title" label="Title" placeholder="Title" type="text" />
						{/* TODO: change to file upload type */}
						<FormField
							name="image"
							label="Masthead Image"
							type="file"
							image={{ aspect: 160 / 15, existing: department.masthead?.publicUrl }}
						/>

						<FormField
							name="missionStatement"
							label="Mission Statement"
							type="richText"
							onChange={(val) => setFieldValue('missionStatement', val)}
							value={values.missionStatement}
						/>
						<FormField
							type="formButtons"
							submitLabel="Save Department"
							onCancel={() => navigate(`/departments/${department.id}/overview`)}
						/>
					</Form>
				)}
			</Formik>
		</div>
	)
}

export default EditDepartment
