import { useState } from 'react'
import _ from 'lodash'

import Container from 'src/components/Container'
import LayoutwithSidebar from 'src/components/LayoutWithSidebar'
import PersonCard from 'src/components/PersonCard'
import Sidebar from 'src/components/Sidebar'
import Select from 'src/components/Select'
import TextInput from 'src/components/TextInput'
import { useUsersGetAllQuery, useJobsitesQuery } from 'shared/schema'
import JobsitePanel from 'src/components/JobsitePanel'

import { DirectoryContainer, PeopleContainer, SearchFiltersContainer, Divider, SortOptionsContainer } from './styled'
import { StateSpinner } from 'src/components/Spinner'

const sidebarLinks = [
	{ title: 'Company Directory', to: '/company-directory' },
	{ title: 'Organizational Chart', to: '/org-chart' }
]

const NO_SELECTION = '-'

const sortOptions = [
	{ label: 'Last Name, A-Z', value: 'lastName' },
	{ label: 'First Name, A-Z', value: 'firstName' },
	{ label: 'Department, A-Z', value: ['department.title', 'lastName'] }
]

function Directory() {
	const { data, loading } = useUsersGetAllQuery()
	const { data: jobsiteData } = useJobsitesQuery()
	const [filters, setFilters] = useState({
		titleOrDepartment: '-',
		jobsite: '-',
		name: '',
		sort: sortOptions[0]
	})
	const activeJobSite = jobsiteData?.jobsites.find((jobsite) => jobsite.title === filters.jobsite)
	const filteredUsers =
		data?.users?.filter((u) => {
			const fullName = `${u.firstName} ${u.lastName}`.toLowerCase()
			if (filters.titleOrDepartment !== NO_SELECTION) {
				if (u?.jobTitle !== filters.titleOrDepartment && u?.department?.title !== filters.titleOrDepartment) {
					return false
				}
			}

			if (filters.jobsite !== NO_SELECTION) {
				if (u?.jobsite?.title !== filters.jobsite) {
					return false
				}
			}

			if (filters.name.length) {
				const lowercaseNameSearch = filters.name.toLowerCase().trim()
				if (!fullName.includes(lowercaseNameSearch)) {
					return false
				}
			}

			return true
		}) ?? []

	const filteredAndSortedUsers = _.orderBy(filteredUsers, filters.sort.value)

	const jobTitleOptions = _.chain(data?.users)
		.map((user) => user?.jobTitle)
		.compact()
		.groupBy()
		.pickBy((list) => list.length > 2)
		.keys()
		.value()

	const departmentOptions = _.chain(data?.users)
		.map((user) => user?.department?.title)
		.compact()
		.uniq()
		.value()

	const jobSiteOptions = _.chain(data?.users)
		.map((user) => user?.jobsite?.title)
		.compact()
		.uniq()
		.value()

	return (
		<Container>
			<LayoutwithSidebar>
				<Sidebar links={sidebarLinks} />
				<DirectoryContainer>
					<h1>Company Directory</h1>
					{loading ? (
						<StateSpinner />
					) : (
						<>
							<SearchFiltersContainer>
								<div>
									<Select
										onChange={(e) => setFilters({ ...filters, titleOrDepartment: e.target.value })}
									>
										<option value={NO_SELECTION}>Any Job Title & Department</option>
										<optgroup label="Job titles">
											{jobTitleOptions.map((option) => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
										</optgroup>
										<optgroup label="Departments">
											{departmentOptions.map((option) => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
										</optgroup>
									</Select>
									<Select onChange={(e) => setFilters({ ...filters, jobsite: e.target.value })}>
										<option value={NO_SELECTION}>Any Job Site</option>
										{jobSiteOptions.sort().map((option) => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</Select>
								</div>
								<TextInput
									type="text"
									placeholder="Search by name"
									onChange={(e) => setFilters({ ...filters, name: e.target.value })}
								/>
							</SearchFiltersContainer>
							<Divider />
							{activeJobSite && <JobsitePanel jobsite={activeJobSite} />}
							<SortOptionsContainer>
								Sort results by
								<Select onChange={(e) => setFilters({ ...filters, sort: sortOptions[e.target.value] })}>
									{sortOptions.map((sortOption, index) => (
										<option key={`${sortOption.value}-${index}`} value={index}>
											{sortOption.label}
										</option>
									))}
								</Select>
							</SortOptionsContainer>
							<PeopleContainer>
								{filteredAndSortedUsers.map((user) => (
									<PersonCard key={user.id} person={user} />
								))}
							</PeopleContainer>
						</>
					)}
				</DirectoryContainer>
			</LayoutwithSidebar>
		</Container>
	)
}

export default Directory
