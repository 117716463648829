import React from 'react'
import { Facebook, Twitter, Linkedin, Youtube } from 'src/icons'

import {
	FooterWrapper,
	Container,
	FooterLink,
	FooterNavLink,
	FooterTopContainer,
	FooterBottomContainer,
	SocialMediaIcon,
	SocialMediaLink
} from '../styled'
import { RoleGate } from 'src/components/Roles'

function Footer() {
	const thisYear = new Date().getFullYear()
	return (
		<FooterWrapper>
			<Container>
				<FooterTopContainer>
					<div>
						<FooterNavLink to="/feedback">Intranet Feedback</FooterNavLink>
						<FooterNavLink to="/etiquette">Intranet Etiquette</FooterNavLink>
						<RoleGate role="SuperAdmin">
							<FooterNavLink to="/admin">Intranet Admin</FooterNavLink>
						</RoleGate>
						<RoleGate role="HR">
							<FooterNavLink to="/hr">HR Tools</FooterNavLink>
						</RoleGate>
					</div>
					<div>
						<FooterLink href="https://www.nibbi.com">Visit: www.nibbi.com</FooterLink>
						<FooterLink href="https://www.nibbiconcrete.com">Visit: www.nibbiconcrete.com</FooterLink>
					</div>
				</FooterTopContainer>
				<FooterBottomContainer>
					<div>© {thisYear} Nibbi Brothers General Contractors</div>
					<div>
						Follow Nibbi:
						<SocialMediaLink href="https://www.facebook.com/pages/Nibbi-Brothers-General-Contractors/225301220837912">
							<SocialMediaIcon as={Facebook} />
						</SocialMediaLink>
						<SocialMediaLink href="https://twitter.com/#!/nibbibrothers">
							<SocialMediaIcon as={Twitter} />
						</SocialMediaLink>
						<SocialMediaLink href="http://www.linkedin.com/company/130071">
							<SocialMediaIcon as={Linkedin} />
						</SocialMediaLink>
						<SocialMediaLink href="https://www.youtube.com/nibbibrothers/">
							<SocialMediaIcon as={Youtube} />
						</SocialMediaLink>
					</div>
				</FooterBottomContainer>
			</Container>
		</FooterWrapper>
	)
}

export default Footer
