import { ReactNode } from 'react'
import { ThemeProvider, createTheme } from '@mui/material'

const theme = createTheme({
	palette: {
		primary: {
			main: '#EA6C37'
		}
	},
	typography: {
		fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`
	},
	components: {
		MuiLink: {
			defaultProps: {
				underline: 'none'
			}
		},
		MuiSvgIcon: {
			defaultProps: {
				color: 'primary'
			}
		}
	}
})

function MuiBaseTheme(props: { children: ReactNode }) {
	return <ThemeProvider theme={theme} {...props} />
}

export default MuiBaseTheme
