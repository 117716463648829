import React from 'react'
import { AnyStyledComponent } from 'styled-components'
import NavLink from 'src/components/NavLink'
import { NavSubMenu, NavIcon, DownCaret, SideCaret, DropDownNav, SideSubNav, NavSubSideMenu } from '../styled'

function NavDropdown({
	title,
	content,
	subMenuStyle
}: {
	title: string
	content: { title: string; to?: string; content?: { title: string; to: string }[] }[]
	subMenuStyle: AnyStyledComponent
}) {
	return (
		<DropDownNav>
			<NavLink primary title={title}>
				{content && <NavIcon as={DownCaret} />}
			</NavLink>
			<NavSubMenu as={subMenuStyle}>
				{content.map((dropdownItem) =>
					dropdownItem.content ? (
						<SideSubNav key={`submenu-${dropdownItem.title}`}>
							<NavLink
								key={`${title}-${dropdownItem.title}`}
								title={dropdownItem.title}
								to={dropdownItem.to}
							>
								{dropdownItem.content && <NavIcon as={SideCaret} />}
							</NavLink>
							<NavSubSideMenu>
								{dropdownItem.content.map((sideLink) => (
									<NavLink
										key={`${dropdownItem.title}-${sideLink.title}`}
										title={sideLink.title}
										to={sideLink.to}
									/>
								))}
							</NavSubSideMenu>
						</SideSubNav>
					) : (
						<NavLink
							key={`${title}-${dropdownItem.title}`}
							title={dropdownItem.title}
							to={dropdownItem.to}
						/>
					)
				)}
			</NavSubMenu>
		</DropDownNav>
	)
}

export default NavDropdown
