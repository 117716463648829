import React from 'react'

import { FilterContainer, FilterLabel } from './styled'

function Filter(props: { toggled: boolean; toggle: () => void; color: string; title: string }) {
	return (
		<FilterContainer onClick={props.toggle}>
			<div
				style={{
					height: '1rem',
					width: '1rem',
					backgroundColor: props.toggled ? props.color : 'white',
					display: 'inline-block',
					border: '1px solid #ccc'
				}}
			></div>
			<FilterLabel>{props.title}</FilterLabel>
		</FilterContainer>
	)
}

export default Filter
