import { Box } from '@mui/material'
import { groupBy } from 'lodash'
import { useOrgChartUsersQuery, OrgChartUsersQueryResult } from 'shared/schema'
import Container from 'src/components/Container'
import OrgChart, { OrgChartNode } from 'src/components/OrgChart'
import { OrgChartPDFButton } from './OrgChartPDFButton'

type OrgChartUsers = OrgChartUsersQueryResult['data']['users']

function serialize(allUsers: OrgChartUsers) {
	const users = allUsers
	const grouped = groupBy(users, 'supervisorId')
	const rootUsers = grouped['null'].filter((user) => [5].includes(user.id))
	const recursivelyAdd = (tree: OrgChartUsers) => {
		if (!tree?.length) {
			return null
		}
		return tree.map((branch) => {
			const directReports = grouped[branch.id]
			const cell: OrgChartNode = {
				...branch,
				children: recursivelyAdd(directReports)
			}
			return cell
		})
	}
	return recursivelyAdd(rootUsers)
}

function OrgChartRoute() {
	const { data } = useOrgChartUsersQuery()
	if (data?.users) {
		const serialized = serialize(data.users)
		return (
			<Container>
				<Box sx={{ textAlign: 'right' }}>
					<OrgChartPDFButton />
				</Box>
				<OrgChart data={serialized} />
			</Container>
		)
	}
	return null
}

export default OrgChartRoute
