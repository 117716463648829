import React from 'react'
import styled from 'styled-components'
import { X } from 'src/icons'
import Modal from 'src/components/Modal'
import PersonCard from 'src/components/PersonCard'
import { SearchQuery } from 'shared/schema'

export const PersonCardContainer = styled.div`
	position: fixed;
	top: 25%;
	left: 30%;
	background-color: ${({ theme }) => theme.colors.white};
	width: 450px;
	height: 250px;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
	border-radius: 3px;
	border: 1px solid ${({ theme }) => theme.colors.borderGray};
	padding: 40px;
	color: ${({ theme }) => theme.colors.primaryTextColor};
	h1 {
		margin-top: 0px;
		text-align: left;
	}
`
export const CloseIcon = styled(X)`
	width: 30px;
	height: 30px;
	color: ${(props) => props.theme.colors.borderGray};
	top: 0;
	right: 0;
	position: absolute;
	float: right;
	padding-top: 15px;
	padding-right: 15px;
	&:hover {
		cursor: pointer;
	}
`

const UserModal = ({ person, onClose }: { person: SearchQuery['search'][number]['user']; onClose: any }) => {
	return (
		<Modal backgroundColor="dark">
			<PersonCardContainer>
				<CloseIcon onClick={onClose} />
				<PersonCard person={person} fullWidth />
			</PersonCardContainer>
		</Modal>
	)
}

export default UserModal
