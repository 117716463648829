import styled from 'styled-components'
import { Pencil, Trash } from 'src/icons'

export const Table = styled.table`
	width: 100%;
	table-layout: fixed;
	font-size: 14px;
	line-height: 2.3;
	font-weight: 300;
	text-align: left;
	padding-bottom: 0.5rem;
	border-collapse: collapse;
`

export const Head = styled.thead``

export const Header = styled.th<{ $width?: number }>`
	font-weight: 500;
	width: ${(props) => (props.$width ? `${props.$width}px` : undefined)};
`
export const SmallHeader = styled.th`
	font-weight: 500;
	width: 100px;
`
export const ActionsHeader = styled.th`
	width: 64px;
`

export const Body = styled.tbody``

export const Row = styled.tr`
	border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
`

export const EditIcon = styled(Pencil)`
	color: ${({ theme }) => theme.colors.primary};
	width: 15px;
	height: 15px;
	margin-right: 15px;
	cursor: pointer;
`
export const DeleteIcon = styled(Trash)`
	color: ${({ theme }) => theme.colors.primary};
	width: 15px;
	height: 15px;
	cursor: pointer;
`

export const Actions = styled.td`
	text-align: right;
`
