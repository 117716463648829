import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { NavItemLink, NavItem, NavItemStyles } from 'src/components/NavLink/styled'

export const SidebarContainer = styled.div`
	padding-right: 25px;
`

export const SidebarMenuLinks = styled.div`
	background: ${({ theme }) => theme.colors.lightGray};
	width: 300px;
	${NavItemLink}, ${NavItem} {
		border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
		padding: 12px 20px;
		font-size: 14px;
		&:last-child {
			border-bottom: none;
		}
		&.current {
			font-weight: bold;
			border-left: 5px solid ${({ theme }) => theme.colors.primary};
			color: ${({ theme }) => theme.colors.primary};
		}
	}
`

export const SidebarMenuLogo = styled.img`
	padding-bottom: 20px;
`

export const SubLinks = styled.div`
	display: none;
	&.displayLinks {
		display: block;
		background: ${({ theme }) => theme.colors.darkGray};
		padding: 10px 0px;
	}
`

export const NavItemSubLink = styled(NavLink)`
	${NavItemStyles}
	padding: 5px 20px;
	color: ${({ theme }) => theme.colors.lightestGray};
	font-size: 14px;
`

export const LinkWithSublinksWrapper = styled.div``

export const SublinksOpenIndicator = styled.span`
	font-weight: 600;
	font-size: 25px;
	color: ${({ theme }) => theme.colors.lightestGray};
`
