import React from 'react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router'

import { BulletinDetailsFragment } from 'shared/schema'
import { ImageCardContainer, ItemSubtitle, Link, TitleLink, ContentPreview, Image } from './styled'

function truncate(html: string) {
	const element = document.createElement('div')
	element.innerHTML = html
	const firstLine = element.innerText.split(/\n+/)[0]
	return `<p>${firstLine.substr(0, 120)}...</p>`
}

function ImageCard({
	data,
	$stacked,
	$wide,
	$noBorder,
	departmentId,
	$botPadding
}: {
	data: BulletinDetailsFragment
	$stacked?: boolean
	$wide?: boolean
	$noBorder?: boolean
	departmentId?: number
	$botPadding?: boolean
}) {
	if (!data) {
		return null
	}

	const subtitle = [`${dayjs(data.createdAt).format('ll')}`]

	if (data?.author?.firstName) {
		subtitle.push(`by ${data.author.firstName} ${data.author?.lastName}`)
	}

	const imageSrc = $stacked ? data.leadWebUrl : data.leadThumbUrl
	const keepReadingHref = departmentId ? `/departments/${departmentId}/news/${data.id}` : `/news/${data.id}`
	const navigate = useNavigate()

	return (
		<ImageCardContainer $stacked={$stacked} $noBorder={$noBorder}>
			{imageSrc && (
				<Image $stacked={$stacked} $wide={$wide} src={imageSrc} onClick={() => navigate(keepReadingHref)} />
			)}
			<div style={{ paddingBottom: $botPadding ? '1rem' : '' }}>
				<TitleLink to={keepReadingHref}>{data.title}</TitleLink>
				<ItemSubtitle $stacked={$stacked}>{subtitle.join(' • ')}</ItemSubtitle>
				<ContentPreview html={truncate(data.post)}></ContentPreview>
				<Link $stacked={$stacked} to={keepReadingHref}>
					Keep reading
				</Link>
			</div>
		</ImageCardContainer>
	)
}

export default ImageCard
