import React, { useState, forwardRef } from 'react'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

import { useOrderedGetGalleryQuery } from 'shared/extended'
import PageHeader from 'src/components/PageHeader'
import { GalleryPhotoCard, GalleryPhotoImage, GalleryButtonsContainer, Edit, Delete } from '../styled'
import { useRemoveGalleryPhotoMutation, GetGalleryQuery, useUpdateGalleryPhotoOrderMutation } from 'shared/schema'
import { useUserRoles } from 'src/components/Roles'
import { SortableGrid } from '../sortable-grid'
type PhotoCardProps = { onClick: any; data: GetGalleryQuery['gallery']['photos'][number]; style?: any }
const PhotoCard = forwardRef<HTMLDivElement, PhotoCardProps>(
	({ data, onClick, style, ...props }: PhotoCardProps, ref) => {
		const { isGalleryAdmin: canEdit } = useUserRoles()
		const [deletePhoto] = useRemoveGalleryPhotoMutation({
			refetchQueries: ['getGallery'],
			awaitRefetchQueries: true
		})

		const inlineStyles = {
			height: 200,
			position: 'relative',
			...style
		}

		const thumbnailUrl = data.image.publicUrl.replace('/original_', '/thumb_')
		return (
			<div ref={ref} style={inlineStyles} {...props}>
				<GalleryPhotoCard onClick={onClick}>
					<GalleryPhotoImage
						style={{
							backgroundImage: `url(${thumbnailUrl})`
						}}
					/>

					{canEdit && (
						<GalleryButtonsContainer>
							<Edit onClick={() => console.log('edit')} />
							<Delete
								onClick={(ev) => {
									ev.stopPropagation()
									if (confirm('Are you sure you want to delete this photo?')) {
										deletePhoto({
											variables: {
												id: data.id
											}
										})
									}
								}}
							/>
						</GalleryButtonsContainer>
					)}
				</GalleryPhotoCard>
			</div>
		)
	}
)

function GalleryView() {
	const { galleryId } = useParams()
	const { data } = useOrderedGetGalleryQuery(+galleryId)
	const [lightboxOpen, setLightboxOpen] = useState(false)
	const [lightboxPhotoIndex, setLightboxPhotoIndex] = useState(0)

	if (!data?.gallery?.title) {
		return null
	}
	const pageTitle = `${data.gallery.title} (${dayjs(data.gallery.date).format('ll')})`
	const numPhotos = data.gallery.photos.length

	const images = data.gallery.photos.map((photos) => photos.image.publicUrl)

	return (
		<>
			<PageHeader parents={[{ to: '/gallery', title: 'All Albums' }]} primaryColor={false}>
				{pageTitle}
			</PageHeader>
			<SortableGrid
				GridItemComponent={PhotoCard}
				reorderHook={useUpdateGalleryPhotoOrderMutation}
				reorderHookConfig={{
					refetchQueries: ['getGallery'],
					awaitRefetchQueries: true
				}}
				reorderMutationVariables={{
					id: +galleryId
				}}
				initialItems={data?.gallery?.photos}
				gridColumns={5}
				onGridItemClick={(index) => {
					setLightboxOpen(!lightboxOpen)
					setLightboxPhotoIndex(index)
				}}
			/>
			{lightboxOpen && (
				<Lightbox
					mainSrc={images[lightboxPhotoIndex]}
					nextSrc={images[(lightboxPhotoIndex + 1) % numPhotos]}
					prevSrc={images[(lightboxPhotoIndex + numPhotos - 1) % numPhotos]}
					onCloseRequest={() => setLightboxOpen(false)}
					onMovePrevRequest={() => setLightboxPhotoIndex((lightboxPhotoIndex + numPhotos - 1) % numPhotos)}
					onMoveNextRequest={() => setLightboxPhotoIndex((lightboxPhotoIndex + 1) % numPhotos)}
					imageCaption={`Image ${lightboxPhotoIndex} of ${numPhotos}`}
				/>
			)}
		</>
	)
}

export default GalleryView
