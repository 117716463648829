import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`

export const SpinnerWrapper = styled.div<{ size: string; type: string }>`
	display: inline-block;
	position: relative;
	width: ${(props) => (props.size === 'xsm' ? '1rem' : props.size === 'sm' ? '25px' : '64px')};
	height: ${(props) => (props.size === 'xsm' ? '1rem' : props.size === 'sm' ? '25px' : '64px')};
	& > div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 85%;
		height: 85%;
		margin: 10%;
		border: 3px solid
			${(props) => (props.type === 'primary' ? props.theme.colors.primary : props.theme.colors.white)};
		border-radius: 50%;
		animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: ${(props) => (props.type === 'primary' ? props.theme.colors.primary : props.theme.colors.white)}
			transparent transparent transparent;
		&:nth-child(1) {
			animation-delay: -0.45s;
		}
		&:nth-child(2) {
			animation-delay: -0.3s;
		}
		&:nth-child(3) {
			animation-delay: -0.15s;
		}
	}
`
