import React from 'react'
import { useAuthenticateUserMutation, useCurrentUserQuery, CurrentUserQuery } from 'shared/schema'
import jwt_decode from 'jwt-decode'
import _ from 'lodash'

export interface IAuthGateContext {
	isLoading: boolean
	user: CurrentUserQuery['currentUser']['user']
	setAccessToken: (accessToken?: string) => void
}

export interface IAuthGate {
	children: (props: IAuthGateContext) => React.ReactNode
}

const Context = React.createContext<IAuthGateContext>(null)

function AuthGate({ children }: IAuthGate) {
	const [accessToken, setAccessToken] = React.useState<string>(localStorage.accessToken)

	React.useEffect(() => {
		const badJwtHandler = () => {
			setAccessToken(null)
		}
		window.addEventListener('invalid-jwt', badJwtHandler)
		return () => window.removeEventListener('invalid-jwt', badJwtHandler)
	})

	if (accessToken) {
		localStorage.setItem('accessToken', accessToken)
	} else {
		localStorage.removeItem('accessToken')
	}

	const { data, loading: isLoading } = useCurrentUserQuery({
		skip: !accessToken
	})

	const [authenticateUser] = useAuthenticateUserMutation({
		update(cache) {
			cache.reset()
		}
	})

	// Refresh accessToken if roles on token do not match current user roles
	React.useEffect(() => {
		if (data?.currentUser?.user?.roles) {
			const jwt = jwt_decode(accessToken)
			const tokenRoles = jwt['https://hasura.io/jwt/claims']['x-hasura-allowed-roles']
			const compareRoles = tokenRoles.filter((r) => r != 'user').sort()
			const roles = data.currentUser.user.roles.map((ur) => ur.role).sort()

			if (!_.isEqual(roles, compareRoles)) {
				console.log('refetch jwt')
				authenticateUser({ variables: { username: '', password: '' } }).then(({ data }) =>
					setAccessToken(data.authenticateUser.accessToken)
				)
			}
		}
	}, [data?.currentUser?.userId])

	const state: IAuthGateContext = {
		isLoading,
		setAccessToken,
		user: data?.currentUser?.user
	}
	return <Context.Provider value={state}>{children(state)}</Context.Provider>
}

export function useAuthGate() {
	return React.useContext(Context)
}

export default AuthGate
