import React from 'react'
import _ from 'lodash'
import { useAuthGate } from '../AuthGate'

export const useUserRoles = () => {
	const { user } = useAuthGate()
	const roles = _.map(user.roles, 'role')

	const hasRole = (neededRoles: string | string[]) => _.intersection(roles, _.flatten([neededRoles])).length > 0
	const useRole = hasRole('SuperAdmin')
		? 'SuperAdmin'
		: hasRole('Admin')
		? 'Admin'
		: hasRole('Gallery')
		? 'Gallery'
		: 'user'

	return {
		roles,
		hasRole,
		isAdmin: hasRole(['SuperAdmin', 'Admin']),
		isGalleryAdmin: hasRole(['SuperAdmin', 'Admin', 'Gallery']),
		useRole: useRole,
		useRoleUnless: (condition: boolean) => (condition ? undefined : useRole)
	}
}

export function RoleGate(props: { role: string | string[]; children: any }) {
	const { hasRole } = useUserRoles()

	if (hasRole(props.role)) {
		return <>{props.children}</>
	} else {
		return null
	}
}
