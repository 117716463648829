import React from 'react'
import { Formik, Form } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { useDepartment } from 'src/routes/department'
import FormField from 'src/components/FormField'
import ContentHeader from '../../ContentHeader'

import { useAddQuicklinkMutation, useUpdateQuicklinkMutation } from 'shared/schema'
import { useUserRoles } from 'src/components/Roles'

const Error = styled.span`
	color: red;
	font-size: 12px;
`

function validate(values) {
	const errors: { url?: string; title?: string } = {}

	if (!values?.url?.startsWith('http')) {
		errors.url = `Please enter a valid url starting with 'http' or 'https'`
	}

	if (!values?.url) {
		errors.url = 'Required'
	}

	if (!values?.title) {
		errors.title = 'Required'
	}

	return errors
}

function QuickLinksForm() {
	const department = useDepartment()
	const navigate = useNavigate()
	const params = useParams()
	const [addQuicklink] = useAddQuicklinkMutation({
		refetchQueries: ['departmentDetails'],
		awaitRefetchQueries: true
	})
	const [updateQuicklink] = useUpdateQuicklinkMutation({
		refetchQueries: ['departmentDetails'],
		awaitRefetchQueries: true
	})
	const { useRole } = useUserRoles()

	const quickLink = department.quicklinks.find((ql) => ql.id === +params?.id)

	const prefillInitialValues = {
		title: quickLink?.title || '',
		description: quickLink?.description || '',
		url: quickLink?.url || ''
	}

	return (
		<div>
			<ContentHeader>Publish Quick Link</ContentHeader>
			<Formik
				initialValues={prefillInitialValues}
				validate={validate}
				onSubmit={async (values) => {
					if (quickLink) {
						await updateQuicklink({
							context: {
								useRole
							},
							variables: {
								id: quickLink.id,
								title: values.title,
								description: values.description,
								url: values.url
							}
						})
					} else {
						await addQuicklink({
							context: {
								useRole
							},
							variables: {
								departmentId: department.id,
								title: values.title,
								description: values.description,
								url: values.url
							}
						})
					}
					navigate(`/departments/${department.id}/quick-links`)
				}}
			>
				{({ values, setFieldValue, errors, touched }) => (
					<Form>
						<FormField name="title" label="Title" placeholder="Title" type="text" />
						{errors.title && touched.title && <Error>{errors.title}</Error>}
						<FormField
							name="description"
							label="Description"
							placeholder="Link description..."
							type="richText"
							onChange={(val) => setFieldValue('description', val)}
							value={values.description}
						/>
						<FormField name="url" label="Link Address" placeholder="URL" type="text" />
						{errors.url && touched.url && <Error>{errors.url}</Error>}
						<FormField
							type="formButtons"
							onCancel={() => navigate(`/departments/${department.id}/quick-links`)}
						/>
					</Form>
				)}
			</Formik>
		</div>
	)
}

export default QuickLinksForm
