import React from 'react'
import styled from 'styled-components'

const Layout = styled.div`
	display: flex;
	> *:nth-child(2) {
		width: 100%;
	}
`

interface ILayout {
	children: React.ReactNode
}

function LayoutWithSidebar({ children }: ILayout) {
	return <Layout>{children}</Layout>
}

export default LayoutWithSidebar
