import React, { useState } from 'react'
import RichText from '../RichText'
import styled from 'styled-components'
import dayjs from 'dayjs'
import _ from 'lodash'
import {
	Card,
	ProfilePhoto,
	PersonTitles,
	SideBySide,
	Divider,
	ContactInfo,
	LocationIcon,
	CellIcon,
	HomePhoneIcon,
	EmailIcon,
	ContactInfoContainer,
	CommentsAndReactions
} from './styled'
import { useAddNominationThumbsUpMutation, useRemoveNominationThumbsUpMutation } from 'shared/schema'
import { useAuthGate } from 'src/components/AuthGate'

import { RoleGate } from 'src/components/Roles'
import Comments from 'src/components/Comments'

interface IPersonCardProps {
	person: {
		profilePhotoUrl?: string
		firstName: string
		lastName: string
		jobTitle: string
		department: {
			title: string
		}
		jobsite: {
			title: string
		}
		phoneExt: string
		phoneCell: string
		phoneDirect: string
		email: string
	}
	fullWidth: boolean
}

function PersonCard({ person, fullWidth = false }: IPersonCardProps) {
	return (
		<Card $fullWidth={fullWidth}>
			<SideBySide>
				<ProfilePhoto src={person?.profilePhotoUrl} />
				<PersonTitles>
					<h1>
						{person.firstName} {person.lastName}
					</h1>
					<h2>{person.jobTitle}</h2>
					<h3>{person.department?.title}</h3>
				</PersonTitles>
			</SideBySide>
			<Divider />
			{person?.jobsite?.title}
			<ContactInfoContainer>
				{person.phoneExt && (
					<ContactInfo href={`tel:${person.phoneDirect}`}>
						<LocationIcon />
						Ext. {person.phoneExt}
					</ContactInfo>
				)}
				{person.phoneDirect && (
					<ContactInfo href={`tel:${person.phoneDirect}`}>
						<HomePhoneIcon />
						{person.phoneDirect}
					</ContactInfo>
				)}
				{person.phoneCell && (
					<ContactInfo href={`tel:${person.phoneCell}`}>
						<CellIcon />
						{person.phoneCell}
					</ContactInfo>
				)}
				{person.email && (
					<ContactInfo href={`mailto:${person.email}`}>
						<EmailIcon />
						{person.email}
					</ContactInfo>
				)}
			</ContactInfoContainer>
		</Card>
	)
}

const OrangeLink = styled.a`
	text-decoration: none;
	cursor: pointer;
	color: ${({ theme }) => theme.colors.primary};
	line-height: 40px;
	padding-right: 20px;
`

function noop() {}

export function NomCard({
	person,
	values,
	full = false,
	onRemove = noop,
	clicked,
	usersById
}: {
	person: IPersonCardProps['person']
	values: any
	full?: boolean
	onRemove?: any
	usersById?: any
	clicked?: any
}) {
	const { user } = useAuthGate()

	const [addThumbsUp] = useAddNominationThumbsUpMutation({
		refetchQueries: ['getNominations'],
		awaitRefetchQueries: true
	})

	const [removeThumbsUp] = useRemoveNominationThumbsUpMutation({
		refetchQueries: ['getNominations'],
		awaitRefetchQueries: true
	})

	const count = values.thumbsup.length
	const thumbsUpFromMe = values.thumbsup.find((t) => t.user_id === user.id)
	const toggle = async () => {
		if (thumbsUpFromMe) {
			await removeThumbsUp({
				variables: {
					nomId: values.id,
					userId: user.id
				}
			})
		} else {
			await addThumbsUp({
				variables: {
					nomination_id: values.id,
					user_id: user.id
				}
			})
		}
	}
	const fromUser = usersById[values.from_user_id] || {}

	return (
		<Card $fullWidth $flat>
			<SideBySide onClick={clicked || noop} $clickable={!!clicked}>
				<ProfilePhoto src={person?.profilePhotoUrl} />
				<PersonTitles>
					<h1>
						{person.firstName} {person.lastName}
					</h1>
					<h2>{values.category}</h2>

					<h2 style={{ color: '#777', fontSize: '75%' }}>
						by {fromUser.firstName || ''} {fromUser.lastName || ''} on{' '}
						{dayjs(values.created_at).format('ll')}
					</h2>
				</PersonTitles>
			</SideBySide>
			{full && (
				<div style={{ paddingBottom: '1rem' }}>
					<div style={{ paddingTop: '1rem' }}>
						<RichText html={values.description} />
					</div>
					<RoleGate role={['SuperAdmin', 'Admin']}>
						<div style={{ paddingTop: '1rem' }}>
							<OrangeLink onClick={onRemove}>Remove Nomination</OrangeLink>
						</div>
					</RoleGate>
					<CommentsAndReactions>
						<div
							style={{ userSelect: 'none', display: 'flex', alignItems: 'center', paddingBottom: '4px' }}
						>
							<span
								onClick={toggle}
								style={{
									borderRadius: '8px',
									backgroundColor: thumbsUpFromMe ? 'rgb(99, 210, 130)' : undefined,
									border: '1px solid rgb(210, 210, 210)',
									padding: '0.5rem'
								}}
							>
								👍
							</span>
							<div style={{ paddingLeft: '1rem' }}>
								{values.thumbsup.length > 0 && (
									<div style={{ fontSize: '80%', lineHeight: '1.5' }}>
										{_.chain(values.thumbsup)
											.map((tu) => usersById[tu.user_id])
											.compact()
											.map((user) => `${user.firstName} ${user.lastName}`)
											.value()
											.join(', ')}
									</div>
								)}
							</div>
						</div>
						<Comments
							nominationId={values.id}
							comments={values.comments}
							refetch={['getNominations']}
							tiny
						></Comments>
					</CommentsAndReactions>
				</div>
			)}
		</Card>
	)
}

export default PersonCard
