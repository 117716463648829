import styled from 'styled-components'
import { Field } from 'formik'

export const FormikField = styled(Field)`
	width: ${(props) => (props.type === 'checkbox' ? '14px' : '100%')};
	margin-right: 5px;
	padding: 0.75rem 1rem;
	background: #fff;
	border: 1px solid ${({ theme }) => theme.colors.borderGray};
	box-shadow: none;
	border-radius: 3px;
	&:focus {
		outline: none;
	}
	&:disabled {
		background: #eee;
	}
`

export const FileInputField = styled.div`
	margin-top: 5px;
	input {
		display: none;
	}
	label {
		padding: 0.5rem 0.75rem;
		background: #fff;
		border: 1px solid ${({ theme }) => theme.colors.borderGray};
		box-shadow: none;
		border-radius: 3px;
		font-size: 14px;
		&:hover {
			cursor: pointer;
			background-color: ${({ theme }) => theme.colors.lightGray};
		}
		&:focus {
			outline: none;
		}
		&:disabled {
			background: #eee;
		}
	}
`

export const FormFieldWrapper = styled.div`
	width: 90%;
	padding-top: 20px;
	/* terrible */
	&.third {
		width: 30%;
		display: inline-block;
	}
	&.twothird {
		width: 60%;
		display: inline-block;
	}
	&.quarter {
		width: 22.5%;
		display: inline-block;
	}
	&.half {
		width: 45%;
		display: inline-block;
	}
`

export const FormikCheckboxField = styled(Field)`
	appearance: none;
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.borderGray};
	border-radius: 3px;
	height: 20px;
	width: 20px;
	margin-right: 5px;
	&:checked {
		background-color: ${({ theme }) => theme.colors.white};
		font-size: 14px;
		&::after {
			content: '✔';
			/* TODO: figure out why this primary color isn't applying to the content checkmark */
			color: ${({ theme }) => theme.colors.primary};
			font-size: 10px;
			margin-left: 2px;
			line-height: 20px;
			transition: background-color 0.5s ease-in-out;
		}
	}
`

export const DateTimeInputsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	& span {
		padding: 0px 10px;
		align-self: center;
	}
`

export const ButtonsContainer = styled.div<{ $hideBorderTop?: boolean }>`
	padding-top: 20px;
	border-top: ${(props) => !props.$hideBorderTop && `1px solid ${props.theme.colors.borderGray}`};
	margin-top: 30px;
`
