import React from 'react'
import { useDepartment } from 'src/routes/department'
import ContentHeader from '../ContentHeader'

import Accordian from 'src/components/Accordian'
import { Form, Formik } from 'formik'
import FormField from 'src/components/FormField'
import { useNavigate, useParams } from 'react-router-dom'
import { RoleGate, useUserRoles } from 'src/components/Roles'
import styled from 'styled-components'
import { useCreateFaqMutation, useDeleteFaqMutation, useUpdateFaqMutation } from 'shared/schema'

const Action = styled.span`
	color: ${({ theme }) => theme.colors.primary};
	font-size: 90%;
	text-decoration: none;
	cursor: pointer;
`

export function FAQPublish() {
	const { useRole } = useUserRoles()
	const department = useDepartment()
	const navigate = useNavigate()
	const { faqId } = useParams()
	const refetchConfig = {
		refetchQueries: ['departmentDetails'],
		awaitRefetchQueries: true
	}
	const [createFaq] = useCreateFaqMutation(refetchConfig)
	const [updateFaq] = useUpdateFaqMutation(refetchConfig)

	const existing = department.faqs.find((faq) => faq.id === +faqId)
	const initialValues = {
		question: existing?.question || '',
		answer: existing?.answer || ''
	}
	return (
		<div>
			<ContentHeader>Publish FAQ</ContentHeader>
			<Formik
				initialValues={initialValues}
				onSubmit={async (values) => {
					if (existing) {
						await updateFaq({
							variables: {
								id: existing.id,
								question: values.question,
								answer: values.answer
							},
							context: {
								useRole
							}
						})
					} else {
						await createFaq({
							variables: {
								departmentId: department.id,
								question: values.question,
								answer: values.answer
							},
							context: { useRole }
						})
					}
					await navigate('..')
				}}
			>
				{({ values, setFieldValue }) => (
					<Form>
						<FormField name="question" label="Question" placeholder="Question" type="text" />
						<FormField
							name="answer"
							label="Answer"
							placeholder="Answer"
							type="richText"
							onChange={(val) => setFieldValue('answer', val)}
							value={values.answer}
						/>
						<FormField type="formButtons" />
					</Form>
				)}
			</Formik>
		</div>
	)
}

function FAQ() {
	const department = useDepartment()
	const navigate = useNavigate()
	const [deleteFaq] = useDeleteFaqMutation({
		refetchQueries: ['departmentDetails'],
		awaitRefetchQueries: true
	})
	const { useRole } = useUserRoles()

	return (
		<div>
			<ContentHeader>FAQ</ContentHeader>
			{department.faqs.map((faq) => (
				<Accordian key={faq.question} previewText={faq.question} expandedText={faq.answer}>
					<RoleGate role={['SuperAdmin', 'Admin']}>
						<p>
							<Action onClick={() => navigate(`/departments/${department.id}/faqs/${faq.id}`)}>
								Edit
							</Action>{' '}
							•{' '}
							<Action
								onClick={async () => {
									if (confirm('Are you sure you want to delete this FAQ?')) {
										await deleteFaq({
											context: { useRole },
											variables: {
												id: faq.id
											}
										})
									}
								}}
							>
								Delete
							</Action>
						</p>
					</RoleGate>
				</Accordian>
			))}
		</div>
	)
}

export default FAQ
