import styled from 'styled-components'

const BodyText = styled.div`
	line-height: 1.625;
	color: ${({ theme }) => theme.colors.primaryTextColor};
	ul {
		li + li {
			margin-top: 8px;
		}
	}
	h3 {
		font-weight: normal;
		line-height: 40px;
		font-size: 32px;
	}
	h4 {
		font-weight: normal;
		line-height: 30px;
		font-size: 25px;
	}
`

export default BodyText
