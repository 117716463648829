import React from 'react'
import { Routes, Route, useParams } from 'react-router-dom'
import Container from 'src/components/Container'
import LayoutWithSidebar from 'src/components/LayoutWithSidebar'
import Sidebar from 'src/components/Sidebar'
import { useSiteContext } from 'src/components/AppWrapper'
import PageHeader from 'src/components/PageHeader'
import { DiscussionDetailsFragment, useDiscussionsQuery } from 'shared/schema'
import { DepartmentLinks, DepartmentLink } from './styled'
import { DiscussionPublish, DiscussionList, DiscussionsDetail } from 'src/components/Discussions'
import { StateSpinner } from 'src/components/Spinner'

const sidebarLinks = [
	{ title: 'Discussions', to: '/discussions' },
	{ title: 'Create New Discussion', to: '/discussions/publish' }
]

function DiscussionsListRoute(props: { discussions?: DiscussionDetailsFragment[] }) {
	if (!props.discussions) {
		return null
	}

	return (
		<div>
			<DiscussionList discussions={props.discussions} />
		</div>
	)
}

function DiscussionsDetailRoute(props: { discussions?: DiscussionDetailsFragment[] }) {
	const { discussionId } = useParams()
	const discussion = props.discussions?.find((d) => d.id === +discussionId)

	if (!discussion) {
		return null
	}

	return (
		<div>
			<PageHeader parents={[{ to: '/discussions', title: 'Discussions' }]} primaryColor={false}>
				{discussion.title}
			</PageHeader>
			<DiscussionsDetail discussion={discussion} />
		</div>
	)
}

function Discussions() {
	const { siteMeta } = useSiteContext()
	const departments = siteMeta.departments
	const { data, loading } = useDiscussionsQuery()

	return (
		<Container>
			<LayoutWithSidebar>
				<Sidebar links={sidebarLinks}>
					<DepartmentLinks>
						<h3>Jump to Department Board</h3>
						{departments.map((d) => (
							<DepartmentLink key={d.id} to={`/departments/${d.id}/discussions`}>
								{d.title}
							</DepartmentLink>
						))}
					</DepartmentLinks>
				</Sidebar>
				<Routes>
					<Route
						path="/"
						element={loading ? <StateSpinner /> : <DiscussionsListRoute discussions={data?.discussions} />}
					/>
					<Route path="/publish" element={<DiscussionPublish />} />
					<Route path="/:discussionId" element={<DiscussionsDetailRoute discussions={data?.discussions} />} />
				</Routes>
			</LayoutWithSidebar>
		</Container>
	)
}

export default Discussions
