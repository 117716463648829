import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const DepartmentLinks = styled.div`
	border: 1px solid ${({ theme }) => theme.colors.borderGray};
	padding: 20px;
	margin-top: 20px;
	width: 260px;
	h3 {
		margin-bottom: 15px;
		font-weight: normal;
		font-size: 16px;
	}
`

export const DepartmentLink = styled(NavLink)`
	display: block;
	border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
	color: ${({ theme }) => theme.colors.primary};
	padding: 15px 0px;
	text-decoration: none;
	font-size: 14px;
	&:last-child {
		border-bottom: none;
	}
`
