import React, { useState } from 'react'
import { useResourceSearchQuery, DepartmentDetailsFragment } from 'shared/schema'
import { Formik, Form } from 'formik'
import _ from 'lodash'

import styled from 'styled-components'
import FormLabel from 'src/components/FormLabel'
import FormField from 'src/components/FormField'
import Button from 'src/components/Button'
import LinkCard from 'src/components/LinkCard'
import { useModal } from 'src/components/Modal'
import { Search as SearchIcon, Plus as PlusIcon } from 'src/icons'
import { useUpload } from 'src/components/Upload'

const Link = styled.a`
	color: ${({ theme }) => theme.colors.primary};
	text-decoration: none;
	cursor: pointer;
`
const Icon = styled.svg`
	height: 1.1rem;
	width: 1.1rem;
	color: ${({ theme }) => theme.colors.primaryTextColor};
	position: absolute;
	top: 0.5rem;
	left: 0.5rem;
`
const SearchInput = styled.input`
	line-height: 1.5;
	font-size: 18px;
	padding-left: 2rem;
`

const FormFieldWrapper = styled.div`
	width: 90%;
	padding-top: 20px;
	&:last-of-type {
		padding-bottom: 30px;
	}
`

export const useAddResources = (
	existingResources: DepartmentDetailsFragment['contents'][number]['contentResources'][number]['resource'][]
) => {
	const [creatingResource, setCreatingResource] = useState(false)
	const [resourceSearch, setResourceSearch] = useState('')
	const { data: resourceSearchData } = useResourceSearchQuery({
		skip: resourceSearch.length < 3,
		variables: {
			_ilike: `%${resourceSearch}%`
		}
	})

	const [upload] = useUpload(false)

	const [resourceActions, setResourceActions] = useState<{
		[resourceId: string]: { action: string; data?: any; resource?: any }
	}>(
		_.chain(existingResources)
			.keyBy('id')
			.mapValues((existing) => ({ resource: existing, action: 'none', data: {} }))
			.value()
	)

	const [addingResourceModal, setAddingResource, addingResource] = useModal({
		tall: true,
		header: creatingResource ? 'Create related resource' : 'Add related resource',
		children: creatingResource ? (
			<Formik
				initialValues={{
					title: '',
					file: null,
					description: ''
				}}
				onSubmit={async (values) => {
					const file = values.file.file
					const { uploadId } = await upload(file)
					const fakeId = Math.floor(Math.random() * 100000000) + 10000000
					setResourceActions({
						...resourceActions,
						[fakeId]: {
							action: 'new',
							data: {
								uploadId,
								title: values.title,
								description: values.description
							},
							resource: {
								__typename: 'resources',
								createdAt: new Date().toISOString(),
								description: '',
								id: fakeId,
								title: values.title,
								file: {
									key: '',
									size: file.size,
									type: file.type,
									url: ''
								}
							}
						}
					})
					setAddingResource(false)
				}}
			>
				{({ values, setFieldValue }) => {
					return (
						<Form>
							<FormField name="title" type="text" label="Resource Title" />
							<FormField name="file" type="file" label="Upload document" />
							<FormField
								name="description"
								type="richText"
								label="Description"
								value={values.description}
								placeholder="Tell us about the document..."
								onChange={(val) => setFieldValue('description', val)}
							/>
							<FormField
								hideBorderTop
								type="formButtons"
								submitLabel="Create Resource"
								onCancel={() => setAddingResource(false)}
							/>
						</Form>
					)
				}}
			</Formik>
		) : (
			<div>
				<div style={{ position: 'relative' }}>
					<Icon as={SearchIcon} />
					<SearchInput type="text" onChange={(e) => setResourceSearch(e.target.value)} />
				</div>
				{resourceSearchData?.resources &&
					resourceSearchData.resources
						.filter(
							(resource) =>
								!resourceActions[resource.id] ||
								!['none', 'add'].includes(resourceActions[resource.id].action)
						)
						.map((resource) => (
							<LinkCard
								key={resource.id.toString()}
								data={resource}
								editActions={{
									add: async () => {
										if (resourceActions[resource.id]?.action === 'remove') {
											setResourceActions({
												...resourceActions,
												[resource.id]: {
													resource: resource,
													action: 'none',
													data: {}
												}
											})
										} else {
											setResourceActions({
												...resourceActions,
												[resource.id]: {
													resource: resource,
													action: 'add',
													data: {}
												}
											})
										}
										setAddingResource(false)
									}
								}}
							/>
						))}
				<div style={{ paddingTop: '3rem' }}>
					Looking to create a new resource?{' '}
					<Link onClick={() => setCreatingResource(true)}>You can do that here.</Link>
				</div>
			</div>
		)
	})

	if (!addingResource) {
		if (resourceSearch != '') {
			setResourceSearch('')
		}
		if (creatingResource) {
			setCreatingResource(false)
		}
	}

	const addResourcesFormFields = (
		<>
			<FormFieldWrapper>
				{/* <pre>{JSON.stringify(resourceActions, null, 2)}</pre> */}
				<FormLabel>Related Resources</FormLabel>
				{_.chain(resourceActions)
					.filter((ra) => ['none', 'add', 'new'].includes(ra.action))
					.orderBy('resource.title')
					.map((resourceAction) => (
						<LinkCard
							key={`${resourceAction.resource.id}`}
							data={resourceAction.resource}
							editActions={{
								remove: () => {
									if (['add', 'new'].includes(resourceAction.action)) {
										setResourceActions(_.omit(resourceActions, resourceAction.resource.id))
									} else if (resourceAction.action === 'none') {
										setResourceActions({
											...resourceActions,
											[resourceAction.resource.id]: {
												action: 'remove',
												data: {
													resourceId: resourceAction.resource.id
												}
											}
										})
									}
								}
							}}
						/>
					))
					.value()}
			</FormFieldWrapper>
			<FormFieldWrapper>
				<Button $autoWidth mode="outline" type="button" onClick={() => setAddingResource(true)}>
					<PlusIcon style={{ height: '1rem' }} /> Add Resource
				</Button>
			</FormFieldWrapper>
		</>
	)

	return [addResourcesFormFields, addingResourceModal, resourceActions]
}
