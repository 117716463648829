import { useState } from 'react'
import { Box, Typography, Stack } from '@mui/material'
import { OrgChartUserFragment } from 'shared/schema'
import UserProfilePhoto from '../UserProfilePhoto'

export interface OrgChartNode extends OrgChartUserFragment {
	children?: OrgChartNode[]
}

export type OrgChartData = OrgChartNode[]

function OrgChartCell({ user, root }: { user: OrgChartNode; root: boolean }) {
	const [active, setActive] = useState<boolean>(root === true)
	const hasChildren = !!user.children?.length
	return (
		<Box
			component="li"
			sx={{
				pt: 4,
				position: 'relative',
				pl: root ? 0 : 6,
				'&::before': {
					display: root ? 'none' : 'block',
					content: `''`,
					borderTop: '1px solid #bfbebe',
					position: 'absolute',
					left: '16px',
					width: '32px',
					top: '68px'
				},
				'&:last-child::before': {
					backgroundColor: '#fff',
					bottom: 0
				}
			}}
		>
			{!root && hasChildren && (
				<Box
					sx={{
						position: 'absolute',
						color: '#bfbebe',
						border: '1px solid currentColor',
						width: '16px',
						height: '16px',
						top: '60px',
						left: '24px',
						background: '#fff',
						cursor: 'pointer',
						textAlign: 'center',
						lineHeight: '12px'
					}}
					onClick={() => {
						setActive(!active)
					}}
				>
					{active ? `-` : `+`}
				</Box>
			)}
			<Box
				sx={{ border: '1px solid #bfbebe', py: 1, px: 2, cursor: hasChildren ? 'pointer' : undefined }}
				onClick={() => {
					setActive(!active)
				}}
			>
				<Stack direction="row" alignItems="center" spacing={2}>
					<Box
						component={UserProfilePhoto}
						src={user.profilePhotoUrl}
						sx={{ width: '56px', height: '56px' }}
					/>
					<Typography sx={{ color: '#222' }}>
						<Box component="strong" sx={{ fontWeight: '400', fontSize: 18 }}>
							{user.firstName} {user.lastName}
						</Box>
						{user.jobTitle && <Box sx={{ fontWeight: '300' }}>{user.jobTitle}</Box>}
						{user.department && <Box sx={{ color: '#868686' }}>{user.department.title}</Box>}
					</Typography>
				</Stack>
			</Box>
			{active && hasChildren && <OrgChart data={user.children} root={false} />}
		</Box>
	)
}

function OrgChart({ data, root = true }: { data: OrgChartData; root?: boolean }) {
	return (
		<Box
			component="ul"
			sx={{
				margin: 0,
				padding: 0,
				listStyle: 'none',
				position: 'relative',
				'&::before': {
					display: root ? 'none' : 'block',
					content: `''`,
					borderLeft: '1px solid #bfbebe',
					position: 'absolute',
					top: 0,
					bottom: 0,
					left: '16px'
				}
			}}
		>
			{data.map((user) => (
				<OrgChartCell user={user} root={root} key={user.id} />
			))}
		</Box>
	)
}

export default OrgChart
