import React from 'react'
import NavLink from 'src/components/NavLink'
import classNames from 'classnames'

import {
	SidebarContainer,
	SidebarMenuLinks,
	SidebarMenuLogo,
	SubLinks,
	NavItemSubLink,
	LinkWithSublinksWrapper,
	SublinksOpenIndicator
} from './styled'
import { useUserRoles } from '../Roles'

export interface Link {
	title: string
	to?: string
	subLinks?: Link[]
}

interface ISidebarProps {
	logoUrl?: string
	links: Link[]
	children?: React.ReactNode
}

function getSublinksOpenIndicator(showSubLinks, subLinks, sublinkIsActive) {
	if (showSubLinks || sublinkIsActive) {
		return '-'
	}
	if (subLinks?.length > 0) {
		return '+'
	}
	return null
}

function Sidebar({ logoUrl, links, children }: ISidebarProps) {
	const { isAdmin } = useUserRoles()

	const adminFilter = isAdmin ? () => true : (r) => !r.adminOnly
	const filteredLinks = links.filter(adminFilter)

	return (
		<SidebarContainer>
			{logoUrl && <SidebarMenuLogo src={logoUrl} />}
			{filteredLinks.length > 0 && (
				<SidebarMenuLinks>
					{filteredLinks.map((link) => {
						const showSubLinks = location.pathname === link.to && link.subLinks?.length > 0
						const sublinkIsActive =
							location.pathname.startsWith(link.to + '/') &&
							link.subLinks?.length > 0 &&
							location.pathname.split('/').length != link.to.split('/').length
						return (
							<LinkWithSublinksWrapper key={`sidebar-links-${link.title}`}>
								<NavLink
									key={`sidebar-nav-${link.title}`}
									to={link.to}
									sublinkIsActive={sublinkIsActive}
								>
									{link.title}
									<SublinksOpenIndicator>
										{getSublinksOpenIndicator(showSubLinks, link.subLinks, sublinkIsActive)}
									</SublinksOpenIndicator>
								</NavLink>
								<SubLinks className={classNames({ ['displayLinks']: showSubLinks || sublinkIsActive })}>
									{link.subLinks?.map((subLink) => (
										<NavItemSubLink
											key={`sublink-${link.title}-${subLink.title}`}
											to={subLink.to}
											activeClassName="current"
											end
										>
											{subLink.title}
										</NavItemSubLink>
									))}
								</SubLinks>
							</LinkWithSublinksWrapper>
						)
					})}
				</SidebarMenuLinks>
			)}
			{children}
		</SidebarContainer>
	)
}

export default Sidebar
