import React, { useState } from 'react'
import { Search as SearchIcon } from 'src/icons'
import { useDebounce } from '@react-hook/debounce'
import classNames from 'classnames'

import { useSearchQuery, SearchQuery } from 'shared/schema'

import { Icon, SearchContainer, SearchInput, CloseSearch } from './styled'
import _ from 'lodash'

const Context = React.createContext<{
	searchKeywords: string
	setSearchKeywords: any
	searchSort: string
	setSearchSort: any
	searchOpen: any
	setSearchOpen: any
	results: SearchQuery['search']
	loading: boolean
	error: any
}>(null)

const orderResults = (results: any, searchSort: string) => {
	const typeIteree = 'modelType'
	const bulletinIteree = (result) => {
		if (result.modelType === 'bulletin') {
			if (searchSort.startsWith('name')) {
				return result.bulletin?.title
			} else if (searchSort.startsWith('date')) {
				return result.bulletin?.createdAt
			}
		}
	}
	const resourceIteree = (result) => {
		if (result.modelType === 'resources') {
			if (searchSort.startsWith('name')) {
				return result.resource?.title
			} else if (searchSort.startsWith('date')) {
				return result.resource?.createdAt
			}
		}
	}
	const userIteree = (result) => {
		if (result.modelType === 'users') {
			return result.user?.firstName
		}
	}
	const userSort = searchSort === 'nameDesc' ? 'desc' : 'asc'
	const dir = ['nameDesc', 'dateDesc'].includes(searchSort) ? 'desc' : 'asc'
	return _.orderBy(results, [typeIteree, bulletinIteree, resourceIteree, userIteree], ['desc', dir, dir, userSort])
}

export function SearchProvider({ children }: { children: React.ReactNode }) {
	const [searchKeywords, setSearchKeywords] = useDebounce('', 250)
	const [searchSort, setSearchSort] = useState('dateDesc')
	const [searchOpen, setSearchOpen] = useState(false)

	const { data, loading, error } = useSearchQuery({
		variables: { keywords: `%${searchKeywords}%` },
		skip: searchKeywords.length < 2
	})

	const results = data?.search ? orderResults(data.search, searchSort) : undefined

	return (
		<Context.Provider
			value={{
				searchKeywords,
				setSearchKeywords,
				searchSort,
				setSearchSort,
				searchOpen,
				setSearchOpen,
				results,
				loading,
				error
			}}
		>
			{children}
		</Context.Provider>
	)
}

export function useSearch() {
	return React.useContext(Context)
}

function Search() {
	const { setSearchKeywords, searchOpen, setSearchOpen } = useSearch()

	const toggleSearch = () => {
		setSearchOpen(!searchOpen)
	}

	return (
		<SearchContainer className={classNames({ ['searchOpen']: searchOpen })}>
			<div onClick={toggleSearch}>
				<Icon as={SearchIcon} />
				{!searchOpen && <span>Search</span>}
			</div>
			{searchOpen && (
				<SearchInput
					type="text"
					placeholder="Start typing..."
					onChange={(e) => setSearchKeywords(e.target.value)}
					autoFocus
				/>
			)}
			{searchOpen && <CloseSearch onClick={toggleSearch} />}
		</SearchContainer>
	)
}

export default Search
