import React from 'react'
import { Link } from 'react-router-dom'

import { useDepartment } from 'src/routes/department'
import ContentHeader from '../../ContentHeader'

import * as Table from 'src/components/DataTable/styled'

import { useDeleteQuicklinkMutation } from 'shared/schema'
import RichText from 'src/components/RichText'
import { useUserRoles } from 'src/components/Roles'

function QuickLinksList() {
	const department = useDepartment()
	const [deleteQuicklink] = useDeleteQuicklinkMutation({
		refetchQueries: ['departmentDetails'],
		awaitRefetchQueries: true
	})
	const { useRole } = useUserRoles()

	return (
		<div>
			<ContentHeader>Quick Links</ContentHeader>
			<Table.Table>
				<Table.Head>
					<Table.Row>
						<Table.Header>Title</Table.Header>
						<Table.Header>Description</Table.Header>
						<Table.Header />
					</Table.Row>
				</Table.Head>
				<Table.Body>
					{department.quicklinks.map((ql) => (
						<Table.Row key={ql.title}>
							<td>{ql.title}</td>
							<td>
								<RichText html={ql.description} />
							</td>
							<Table.Actions>
								<Link to={`${ql.id}/publish`}>
									<Table.EditIcon />
								</Link>
								<Table.DeleteIcon
									onClick={async () => {
										if (confirm('Are you sure you want to delete this quicklink?')) {
											deleteQuicklink({
												context: {
													useRole
												},
												variables: {
													id: ql.id
												}
											})
										}
									}}
								/>
							</Table.Actions>
						</Table.Row>
					))}
				</Table.Body>
			</Table.Table>
		</div>
	)
}

export default QuickLinksList
