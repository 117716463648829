import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { CaretDownFill, CaretRightFill } from 'src/icons'
import NavLogo from '../Logo'

const ExternalLink = styled.a`
	transition: all 0.1s ease-in-out;
	text-decoration: none;
`

const InternalLink = styled(Link)`
	transition: all 0.1s ease-in-out;
	text-decoration: none;
`

export const AppWrapperContainer = styled.div`
	position: relative;
	min-height: 100vh;
`

export const AppWrapperContentContainer = styled.div`
	padding-bottom: 200px;
`

export const Container = styled.div`
	min-width: var(--container-min-width);
	max-width: var(--container-max-width);
	padding-left: var(--grid-basis);
	padding-right: var(--grid-basis);
	position: relative;
	margin: 0 auto;
`

const FlexSpaceBetween = css`
	display: flex;
	justify-content: space-between;
`

export const TopHeader = styled.div`
	background: #f7f7f7;
	position: relative;
	height: 40px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
	line-height: 40px;
`

export const MainNav = styled.nav`
	height: 70px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
`

export const HeaderContainer = styled(Container)`
	${FlexSpaceBetween}
	&.searchOpen {
		justify-content: start;
	}
	#logoLink {
		padding-left: 0px;
	}
`

export const UtilitiesContainer = styled.div`
	&:first-child {
		padding-left: 0px;
	}
`

const UtilityItemStyle = css`
	padding-right: 20px;
	color: ${({ theme }) => theme.colors.primaryTextColor};
	font-size: 12px;
	min-width: 90px;
	display: inline-block;
	&:hover {
		cursor: pointer;
	}
	&:last-child {
		padding-right: 0;
	}
`

export const UserContainer = styled.div``

export const UtilityItemInfo = styled.span`
	${UtilityItemStyle}
	&:hover {
		color: ${({ theme }) => theme.colors.primary};
	}
`

export const UtilityItemLink = styled(InternalLink)`
	${UtilityItemStyle}
`

export const UtilityItemExternalLink = styled(ExternalLink)`
	${UtilityItemStyle}
`

export const UtilityDropdownContent = styled.div`
	display: none;
	opacity: 0;
	visibility: hidden;
`

export const UtilityDropdown = styled.div`
	display: inline-block;
	${UtilityItemStyle}
	&:hover ${UtilityDropdownContent} {
		display: block;
		opacity: 100;
		visibility: visible;
		position: absolute;
		top: 39px;
		padding: 20px;
		min-width: 150px;
		background: #fff;
		border: 1px solid ${({ theme }) => theme.colors.borderGray};
		z-index: 3;
		font-size: 14px;
		color: #868686;
	}
`

export const WeatherTopContainer = styled.div`
	display: flex;
	font-weight: lighter;
	align-items: end;
	font-size: 40px;
	color: ${({ theme }) => theme.colors.primaryTextColor};
`

export const Logo = styled(NavLogo)`
	width: 70px;
	height: 70px;
	padding-right: 15px;
`

export const NavIcon = styled.svg`
	height: 10px;
	width: 10px;
	color: #868686;
	padding-right: 5px;
	${UtilityItemLink}:hover & {
		color: ${({ theme }) => theme.colors.primaryTextColor};
	}
	${UtilityItemInfo}:hover & {
		color: ${({ theme }) => theme.colors.primaryTextColor};
	}
	${UtilityItemExternalLink}:hover & {
		color: ${({ theme }) => theme.colors.primaryTextColor};
	}
	${UtilityDropdown}:hover & {
		color: ${({ theme }) => theme.colors.primaryTextColor};
	}
`

export const DownCaret = styled(CaretDownFill)`
	padding-left: 5px;
	padding-right: 0px;
	color: ${({ theme }) => theme.colors.primaryTextColor};
`

export const SideCaret = styled(CaretRightFill)`
	padding-left: 5px;
	padding-right: 0px;
	color: ${({ theme }) => theme.colors.primaryTextColor};
`

export const NavLinks = styled.div`
	height: 70px;
	display: flex;
`

export const NavSubMenu = styled.div`
	display: none;
	opacity: 0;
	visibility: hidden;
	transition: visibility 0ms 350ms, opacity 350ms 0ms, top 350ms 0ms;
	border-radius: 3px;
	position: absolute;
	top: 55px;
	background: #fff;
	border: 1px solid ${({ theme }) => theme.colors.borderGray};
	box-shadow: 0 3px 2px rgba(1, 1, 1, 0.05);
	&:hover {
		display: block;
	}
`

export const PeopleSubMenu = styled.div`
	width: max-content;
	a {
		padding: 15px;
		line-height: 10px;
	}
`

export const NavSubSideMenu = styled.div`
	background: #f7f7f7;
	display: none;
	opacity: 0;
	visibility: hidden;
	left: 300px;
	top: -1px;
	position: absolute;
	border: 1px solid ${({ theme }) => theme.colors.borderGray};
	box-shadow: 0 3px 2px rgba(1, 1, 1, 0.05);
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	min-width: 300px;
	width: max-content;
	line-height: 20px;
	min-height: 450px;
	z-index: 2;
	&:hover {
		display: block;
	}
	a {
		padding: 15px;
		line-height: 10px;
	}
`

export const DepartmentsSubMenu = styled.div`
	min-height: 450px;
	width: 300px;
	&:hover ${NavSubSideMenu} {
		display: block;
	}
`

export const DropDownNav = styled.div`
	position: relative;
	&:hover ${NavSubMenu} {
		display: block;
		visibility: visible;
		opacity: 100%;
		z-index: 2;
	}
	&:hover > div > ${NavIcon} {
		color: ${({ theme }) => theme.colors.primary};
	}
`

export const SideSubNav = styled.div`
	line-height: 40px;
	&:hover ${NavSubSideMenu} {
		display: block;
		visibility: visible;
		opacity: 100%;
	}
`

export const FooterWrapper = styled.div`
	margin-top: 50px;
	padding: 30px 0;
	background: ${({ theme }) => theme.colors.primaryTextColor};
	color: #868686;
	font-size: 12px;
	position: absolute;
	bottom: 0;
	width: 100%;
`

export const FooterTopContainer = styled.div`
	padding-bottom: 20px;
	border-bottom: 1px solid #383838;
	${FlexSpaceBetween}
`

export const FooterBottomContainer = styled.div`
	padding-top: 20px;
	padding-bottom: 20px;
	${FlexSpaceBetween}
`

export const FooterNavLink = styled(InternalLink)`
	padding-right: 20px;
	&:hover {
		color: white;
	}
`

export const FooterLink = styled(ExternalLink)`
	padding-right: 20px;
	&:last-child {
		padding-right: 0px;
	}
	&:hover {
		color: white;
	}
`

export const SocialMediaLink = styled(ExternalLink)`
	padding-right: 5px;
	&:last-child {
		padding-right: 0px;
	}
	&:hover {
		color: white;
	}
`

export const SocialMediaIcon = styled.svg`
	transition: all 0.1s ease-in-out;
	height: 15px;
	color: #868686;
	padding-left: 5px;
	&:hover {
		color: white;
	}
`
