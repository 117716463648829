import React from 'react'

interface INavLogo {
	type: 'light' | 'dark'
	className?: string
}

function NavLogo(props: INavLogo) {
	return (
		<svg
			width="1em"
			height="1em"
			viewBox="0 0 70 37"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			className={props.className}
		>
			<defs>
				<path id="logo_svg__a" d="M11.98.291H.076v35.962H11.98V.291z" />
				<path id="logo_svg__c" d="M12.134.291H.229v35.962h11.905V.291z" />
				<path id="logo_svg__e" d="M0 36.253h69.865V.291H0z" />
			</defs>
			<g fill="none" fillRule="evenodd">
				<path d="M29.851 5.726h4.802v-5.1h-4.802v5.1zm0 30.511h4.802V7.627h-4.802v28.61z" fill="#EA6C37" />
				<g transform="translate(0 .334)">
					<g transform="translate(36.878)">
						<mask id="logo_svg__b" fill="#fff">
							<use xlinkHref="#logo_svg__a" />
						</mask>
						<path
							d="M7.179 12.795c0-1.5-.55-1.752-1.151-1.752-.6 0-1.15.252-1.15 1.752v17.606c0 1.5.55 1.75 1.15 1.75.6 0 1.15-.25 1.15-1.75V12.795zM.076.291h4.802v8.803h.1c.7-1.1 1.9-2.151 3.45-2.151 1.852 0 3.552 1 3.552 3.351v22.609c0 2.349-1.7 3.35-3.551 3.35-1.45 0-2.7-1.001-3.451-2.051h-.1v1.701H.076V.291z"
							fill="#EA6C37"
							mask="url(#logo_svg__b)"
						/>
					</g>
					<g transform="translate(50.63)">
						<mask id="logo_svg__d" fill="#fff">
							<use xlinkHref="#logo_svg__c" />
						</mask>
						<path
							d="M7.332 12.795c0-1.5-.55-1.752-1.15-1.752-.6 0-1.15.252-1.15 1.752v17.606c0 1.5.55 1.75 1.15 1.75.6 0 1.15-.25 1.15-1.75V12.795zM.229.291h4.803v8.803h.099c.7-1.1 1.9-2.151 3.451-2.151 1.85 0 3.552 1 3.552 3.351v22.609c0 2.349-1.701 3.35-3.552 3.35-1.45 0-2.7-1.001-3.451-2.051h-.1v1.701H.23V.291z"
							fill="#EA6C37"
							mask="url(#logo_svg__d)"
						/>
					</g>
					<mask id="logo_svg__f" fill="#fff">
						<use xlinkHref="#logo_svg__e" />
					</mask>
					<g mask="url(#logo_svg__f)">
						<path
							d="M65.063 35.903h4.802V7.293h-4.802v28.61zm0-30.51h4.802V.29h-4.802v5.101zM15.822 7.207h4.802v1.65h.1c.7-1.35 2-2 3.301-2 3.652 0 3.701 2.15 3.701 5.151v23.81h-4.802v-23.11c0-.8-.05-1.751-1.05-1.751-1.2 0-1.25.95-1.25 1.751v23.11h-4.802V7.206z"
							fill="#EA6C37"
						/>
						<path
							d="M6.965 29.183h-.246c-2.99 0-4.392.966-4.392 2.233 0 1.269 1.382 2.215 4.373 2.215h.245c2.99 0 4.391-.965 4.391-2.233 0-1.268-1.381-2.215-4.371-2.215m.095 7.04h-.436C2.442 36.223 0 34.256 0 31.398s2.442-4.807 6.605-4.807h.435c4.164 0 6.625 1.967 6.625 4.825s-2.442 4.807-6.605 4.807M.208 24.9v-2.346l7.23-3.766H.208v-2.48h13.249v2.195l-7.533 3.918h7.533v2.48zM.208 14.258v-6.89h2.347v4.373h3.066V7.88h2.347v3.861h3.142V7.293h2.347v6.965z"
							fill={props.type === 'light' ? '#fff' : '#888'}
						/>
					</g>
				</g>
			</g>
		</svg>
	)
}

export default NavLogo
