import styled, { css } from 'styled-components'
import { Megaphone, Chat, PersonSquare } from 'src/icons'
import { NavLink } from 'react-router-dom'
import { buildFileIcon } from 'src/components/Resource/icon'

import { LinkCardSize, CardColor } from './types'
const getIconSizeAndColor = ($displaySize, $iconColor, theme) => {
	return `
	max-width: ${theme.linkCardSize.iconSize[$displaySize]};
	min-width: ${theme.linkCardSize.iconSize[$displaySize]};
	max-height: ${theme.linkCardSize.iconSize[$displaySize]};
	min-height: ${theme.linkCardSize.iconSize[$displaySize]};
	color: ${theme.colors[$iconColor]};
	border: 1px solid ${theme.colors[$iconColor]};
	`
}

const IconStyles = css`
	padding: 7px;
	border-radius: 3px;
	margin-right: 15px;
	align-self: center;
`

const makeIcon = (sourceIcon) => {
	return styled(sourceIcon)<{ $displaySize: LinkCardSize; $iconColor: CardColor }>`
		${IconStyles}
		${({ $displaySize, $iconColor, theme }) => getIconSizeAndColor($displaySize, $iconColor, theme)}
	`
}

export const NewsIcon = makeIcon(Megaphone)
export const DiscussionIcon = makeIcon(Chat)
export const PersonIcon = makeIcon(PersonSquare)
export const FileIcon = buildFileIcon(makeIcon)

export const ProfilePictureIcon = styled.div<{ $profilePhotoUrl: string }>`
	${IconStyles}
	width: 40px;
	height: 40px;
	background: url(${(props) => props.$profilePhotoUrl});
	background-size: cover;
`

export const LinkCardWrapper = styled.div`
	display: flex;
	border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
	line-height: normal;
	padding: 15px 0px;
	&:last-child {
		border-bottom: none;
	}
`

export const ItemLink = styled(NavLink)<{ $displaySize: LinkCardSize }>`
	transition: all 0.1s ease-in-out;
	display: block;
	text-decoration: none;
	padding-bottom: 5px;
	font-size: ${({ $displaySize }) => ($displaySize === 'lg' ? '22px' : '16px')};
	color: ${({ theme }) => theme.colors.primaryTextColor};
	&:hover {
		color: ${({ theme }) => theme.colors.primary};
	}
`

export const ItemSubtitle = styled.div`
	font-size: 14px;
	color: ${({ theme }) => theme.colors.lightestGray};
	& + & {
		padding-top: 0.5rem;
	}
`

export const TitleWrapper = styled.div<{ $hasSubtitle: boolean }>`
	display: flex;
	align-items: center;
	line-height: ${(props) => (props.$hasSubtitle ? 'normal' : '50px')};
`

export const TypeBadge = styled.div<{ $color?: CardColor }>`
	background-color: ${({ theme, $color }) => theme.colors[$color]};
	color: ${({ theme }) => theme.colors.white};
	display: inline-block;
	padding: 2px 5px;
	border-radius: 2px;
	font-size: 14px;
	line-height: 14px;
	margin-left: 10px;
	height: 16px;
`

export const Link = styled.a`
	color: ${({ theme }) => theme.colors.primary};
	text-decoration: none;
	cursor: pointer;
`
export const ActionIcon = styled.svg`
	height: 1rem;
	height: 1rem;
`
export const ActionIconWithPadding = styled.svg`
	height: 1rem;
	height: 1rem;
	padding-right: 0.5rem;
`
export const CommentNavLink = styled(NavLink)`
	color: ${({ theme }) => theme.colors.primary};
	text-decoration: none;
`
