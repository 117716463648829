import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { useDepartment } from 'src/routes/department'
import ImageCard from 'src/components/ImageCard'
import NewsPublish from 'src/routes/news/publish'
import NewsDetailRoute from './detail'
import ContentHeader from '../ContentHeader'

function Newslist() {
	const department = useDepartment()

	return (
		<div>
			<ContentHeader>News &amp; Announcements</ContentHeader>
			{department.bulletins.map((b) => (
				<ImageCard key={b.id} data={b} departmentId={department.id} />
			))}
		</div>
	)
}

function News() {
	const department = useDepartment()

	return (
		<Routes>
			<Route path="/" element={<Newslist />} />
			<Route path="/:bulletinId/*" element={<NewsDetailRoute />} />
			<Route path="/publish" element={<NewsPublish departmentId={department?.id} />} />
		</Routes>
	)
}

export default News
