import React from 'react'
import { UtilityDropdownContent, UtilityItemInfo } from '../../styled'
import { useAuthGate } from 'src/components/AuthGate'
import { useUpdateProfilePicMutation } from 'shared/schema'
import { Form, Formik } from 'formik'
import FormField from 'src/components/FormField'
import { useModal } from 'src/components/Modal'
import { useUpload } from 'src/components/Upload'

function UserDropdownContent() {
	const { setAccessToken, user } = useAuthGate()
	const [profilePicModal, setUpdatingProfilePic] = useModal({
		header: 'Update Profile Picture',
		children: (
			<Formik
				initialValues={{
					file: null
				}}
				onSubmit={async (values) => {
					const { uploadId } = await upload(values.file.file)

					await updateProfilePic({
						variables: {
							userId: user.id,
							uploadId
						}
					})
					setUpdatingProfilePic(false)
				}}
			>
				<Form>
					<FormField
						name="file"
						type="file"
						label="Update Profile Picture"
						buttonLabel="Upload an image"
						image={{ aspect: 1 }}
					></FormField>
					<FormField type="formButtons" onCancel={() => setUpdatingProfilePic(false)} hideBorderTop />
				</Form>
			</Formik>
		)
	})
	const [upload] = useUpload()
	const [updateProfilePic] = useUpdateProfilePicMutation()

	const signout = () => {
		setAccessToken(null)
		localStorage.__promptNextLogin = 'yes'
	}

	return (
		<>
			<UtilityDropdownContent>
				<UtilityItemInfo onClick={() => setUpdatingProfilePic(true)}>Update Profile Picture</UtilityItemInfo>
				<UtilityItemInfo onClick={signout}>Logout</UtilityItemInfo>
			</UtilityDropdownContent>
			{profilePicModal}
		</>
	)
}

export default UserDropdownContent
