import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const NewsAndDiscussionsPreviews = styled.div`
	border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
	font-size: 16px;
	display: flex;
	margin-top: 20px;
`

export const Section = styled.div`
	padding: 0px 10px;
	margin-top: 10px;
`

export const SeeAllLink = styled(NavLink)`
	text-decoration: none;
	font-weight: 300;
	color: ${({ theme }) => theme.colors.primary};
`

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 15px 0px;
	h2 {
		font-weight: 500;
		font-size: 16px;
	}
`
