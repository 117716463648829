import React from 'react'
import Container from 'src/components/Container'
import AppLoading from 'src/components/AppLoading'
import { List, ListItem, Meta } from './styled'
import { useUsersGetAllQuery } from 'shared/schema'

function CompanyDirectory() {
	const { loading, data } = useUsersGetAllQuery()
	const users = data?.users
	return loading ? (
		<AppLoading />
	) : (
		<Container>
			<List>
				{users.map((user) => {
					const manager = users.find((u) => u.id === user.managerId)
					return (
						<ListItem key={user.id}>
							{user.firstName} {user.lastName}
							<Meta>
								{user.department?.title ?? 'No Department'} - {user.title}
								{manager && (
									<div>
										Manager: {manager.firstName} {manager.lastName}
									</div>
								)}
							</Meta>
						</ListItem>
					)
				})}
			</List>
		</Container>
	)
}

export default CompanyDirectory
