import styled from 'styled-components'
import { X } from 'src/icons'

export const Icon = styled.svg`
	height: 15px;
	width: 15px;
	color: ${({ theme }) => theme.colors.primaryTextColor};
	padding-right: 5px;
`
export const SearchContainer = styled.div`
	display: flex;
	align-items: center;
	font-size: 13px;
	&:hover {
		cursor: pointer;
	}
	&.searchOpen {
		width: 100%;
	}
`

export const SearchInput = styled.input`
	width: 100%;
	border: none;
	outline: none;
	line-height: 1.5;
	font-size: 18px;
`

export const CloseSearch = styled(X)`
	width: 25px;
	height: 25px;
`
