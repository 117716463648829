import React from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router'
import Container from 'src/components/Container'
import LayoutWithSidebar from 'src/components/LayoutWithSidebar'
import Sidebar, { Link } from 'src/components/Sidebar'
import {
	useConfigByKeyQuery,
	useJobsitesQuery,
	useUsersGetAllQuery,
	UsersGetAllQuery,
	JobsitesQuery,
	useCreateJobsiteMutation,
	useUpdateJobsiteMutation,
	useDeleteJobsiteMutation,
	useAddUserRoleMutation,
	useRemoveUserRoleMutation,
	Roles_Enum,
	useUpdateProfilePicMutation,
	useUpdatePageMastheadMutation,
	useCreateDepartmentMutation
} from 'shared/schema'
import * as Table from 'src/components/DataTable/styled'
import { Form, Formik } from 'formik'
import FormField, { Separator } from 'src/components/FormField'
import { Link as RouterLink } from 'react-router-dom'
import _ from 'lodash'
import { useUpload } from 'src/components/Upload'
import { useSiteContext } from 'src/components/AppWrapper'

const sidebarLinks: Link[] = [
	{ title: 'Users', to: '/admin/users' },
	{
		title: 'Job Sites',
		to: '/admin/jobsites',
		subLinks: [{ title: 'Create New Job Site', to: '/admin/jobsites/new' }]
	},
	{ title: 'Pages', to: '/admin/pages' },
	{
		title: 'Departments',
		to: '/admin/departments',
		subLinks: [{ title: 'Create New Department', to: '/admin/departments/new' }]
	}
]

const jobsiteMutationParams = {
	awaitRefetchQueries: true,
	refetchQueries: ['jobsites']
}
const usersMutationParams = {
	awaitRefetchQueries: true,
	refetchQueries: ['usersGetAll']
}
const context = {
	useRole: 'SuperAdmin'
}
function JobsitePublish({ jobsites }: { jobsites: JobsitesQuery['jobsites'] }) {
	const location = useLocation()
	const navigate = useNavigate()

	const [createJobsite] = useCreateJobsiteMutation(jobsiteMutationParams)
	const [updateJobsite] = useUpdateJobsiteMutation(jobsiteMutationParams)
	const existingId = +location.pathname.split('/').pop()
	const existing = existingId > 0 && jobsites.find((js) => js.id === existingId)
	const { data: adConfigData } = useConfigByKeyQuery({
		variables: {
			key: 'adJobsites'
		}
	})
	const missingJobsites: string[] = adConfigData
		? _.chain(JSON.parse(adConfigData.configByKey.value))
				.difference(_.chain(jobsites).map('title').value())
				.orderBy()
				.value()
		: []

	return (
		<Formik
			enableReinitialize
			initialValues={{
				title: existing?.title || '',
				siteNumber: existing?.siteNumber || '',
				phone: existing?.phone || '',
				mainAddress: existing?.mainAddress || '',
				mainAddress2: existing?.mainAddress2 || '',
				mainAddressCity: existing?.mainAddressCity || '',
				mainAddressState: existing?.mainAddressState || '',
				mainAddressZip: existing?.mainAddressZip || '',
				officeAddress: existing?.officeAddress || '',
				officeAddress2: existing?.officeAddress2 || '',
				officeAddressCity: existing?.officeAddressCity || '',
				officeAddressState: existing?.officeAddressState || '',
				officeAddressZip: existing?.officeAddressZip || '',
				alt_names: existing?.alt_names || ''
			}}
			onSubmit={async (values) => {
				const variables = { ...values }

				for (const field of [
					'mainAddress2',
					'officeAddress',
					'officeAddress2',
					'officeAddressCity',
					'officeAddressState',
					'officeAddressZip'
				]) {
					if (variables[field].length === 0) {
						variables[field] = null
					}
				}

				if (existing) {
					await updateJobsite({
						context,
						variables: {
							...variables,
							id: existing.id
						}
					})
				} else {
					await createJobsite({
						context,
						variables
					})
				}
				navigate('/admin/jobsites')
			}}
		>
			{({ setFieldValue, values }) => {
				return (
					<Form>
						<FormField className="twothird" name="title" type="text" label="Site Name" />
						<FormField
							className="third"
							name="siteNumber"
							type="text"
							label="Site Number"
							onChange={(e) => {
								const nextVal = /^\d\d$/.test(e.target.value) ? `${e.target.value}-` : e.target.value
								setFieldValue('siteNumber', nextVal)
							}}
						/>
						<FormField
							name="phone"
							type="text"
							label="Phone Number"
							onChange={(e) => {
								const digits = e.target.value.replace(/[^\d]/g, '')
								const nextVal =
									digits.length === 3
										? `(${digits.substr(0, 3)}) `
										: digits.length === 6
										? `(${digits.substr(0, 3)}) ${digits.substr(3, 3)}-`
										: digits.length === 10
										? `(${digits.substr(0, 3)}) ${digits.substr(3, 3)}-${digits.substr(6, 4)}`
										: e.target.value
								setFieldValue('phone', nextVal)
							}}
						/>
						{!existing && missingJobsites.length > 0 && (
							<div style={{ paddingTop: '1em', fontSize: '90%' }}>
								New job sites from AD:{' '}
								{missingJobsites.map((js, idx) => (
									<span key={js}>
										<span
											style={{ cursor: 'pointer', color: '#EA6C37' }}
											onClick={() => setFieldValue('title', js)}
										>
											{js}
										</span>
										{idx != missingJobsites.length - 1 ? ', ' : ''}
									</span>
								))}
							</div>
						)}

						<Separator />

						<FormField className="half" name="mainAddress" type="text" label="Job Site Address" />
						<FormField className="half" name="mainAddress2" type="text" label="Job Site Address 2" />
						<FormField className="half" name="mainAddressCity" type="text" label="City" />
						<FormField className="quarter" name="mainAddressState" type="text" label="State" />
						<FormField className="quarter" name="mainAddressZip" type="text" label="Zip" />

						<Separator />

						<FormField className="half" name="officeAddress" type="text" label="Job Office Address" />
						<FormField className="half" name="officeAddress2" type="text" label="Job Office Address 2" />
						<FormField className="half" name="officeAddressCity" type="text" label="City" />
						<FormField className="quarter" name="officeAddressState" type="text" label="State" />
						<FormField className="quarter" name="officeAddressZip" type="text" label="Zip" />

						<FormField name="alt_names" type="text" label="Matching office names" />
						{existing && missingJobsites.length > 0 && (
							<div style={{ paddingTop: '1em', fontSize: '90%' }}>
								New job sites from AD:{' '}
								{missingJobsites.map((js, idx) => (
									<span key={js}>
										<span
											style={{ cursor: 'pointer', color: '#EA6C37' }}
											onClick={() => {
												const newAltNames =
													_.chain((values.alt_names || '').split(';'))
														.map((ejs) => ejs.trim())
														.filter((ejs) => ejs.length > 0)
														.concat([js])
														.uniq()
														.value()
														.join('; ') + ';'
												setFieldValue('alt_names', newAltNames)
											}}
										>
											{js}
										</span>
										{idx != missingJobsites.length - 1 ? ', ' : ''}
									</span>
								))}
							</div>
						)}
						<FormField
							type="formButtons"
							submitLabel={`${existing ? 'Update' : 'Create'} Job Site`}
							onCancel="/admin/jobsites"
						/>
					</Form>
				)
			}}
		</Formik>
	)
}

function UsersPublish({ users }: { users: UsersGetAllQuery['users'] }) {
	const location = useLocation()
	const navigate = useNavigate()
	const [upload] = useUpload()

	const [addUserRole] = useAddUserRoleMutation(usersMutationParams)
	const [removeUserRole] = useRemoveUserRoleMutation(usersMutationParams)
	const [updateProfilePic] = useUpdateProfilePicMutation(usersMutationParams)

	const userId = +location.pathname.split('/').pop()
	const user = users.find((u) => u.id === userId)
	const roles = ['SuperAdmin', 'Admin', 'Gallery', 'HR'].map((role) => ({ label: role, value: role }))

	return (
		<Formik
			enableReinitialize
			initialValues={{
				name: `${user.firstName || ''} ${user.lastName || ''}`,
				roles: user.roles.map((ur) => roles.find((role) => role.value === ur.role)),
				profileImage: null
			}}
			onSubmit={async (values) => {
				if (values.profileImage) {
					const { uploadId } = await upload(values.profileImage.file)
					// todo: adjust hasura permissions
					await updateProfilePic({
						variables: {
							userId: user.id,
							uploadId
						}
					})
				}
				const currentRoles = user.roles.map((ur) => ur.role)
				const newRoles = values.roles.map((r) => r.value)
				const addedRoles = _.difference(newRoles, currentRoles)
				const removedRoles = _.difference(currentRoles, newRoles)
				for (const addedRole of addedRoles) {
					await addUserRole({
						context,
						variables: {
							input: {
								userId: user.id,
								role: addedRole as Roles_Enum
							}
						}
					})
				}
				for (const removedRole of removedRoles) {
					await removeUserRole({
						context,
						variables: {
							userId: user.id,
							role: removedRole as Roles_Enum
						}
					})
				}

				navigate('/admin/users')
			}}
		>
			{() => {
				return (
					<Form>
						<FormField name="name" type="text" label="Name" disabled />
						<Separator />
						<FormField
							name="profileImage"
							label="Profile Image"
							type="file"
							image={{ aspect: 1, existing: user.profilePhotoUrl }}
						/>
						<Separator />
						<FormField name="roles" type="select2" label="Roles" options={roles} isMulti />
						<FormField type="formButtons" submitLabel="Update User" onCancel="/admin/users" />
					</Form>
				)
			}}
		</Formik>
	)
}

function UsersList({ users }: { users: UsersGetAllQuery['users'] }) {
	const fullNameByUserId = _.chain(users)
		.keyBy('id')
		.mapValues((u) => `${u.firstName || ''} ${u.lastName || ''}`)
		.value()

	return (
		<div>
			<Table.Table>
				<Table.Head>
					<Table.Row>
						<Table.Header>Name</Table.Header>
						<Table.Header>Job Title</Table.Header>
						<Table.Header>Supervisor</Table.Header>
						<Table.SmallHeader>Roles</Table.SmallHeader>
						<Table.ActionsHeader />
					</Table.Row>
				</Table.Head>
				<Table.Body>
					{users.map((u) => (
						<Table.Row key={u.id}>
							<td>{fullNameByUserId[u.id] || ''}</td>
							<td>{u.jobTitle}</td>
							<td>{fullNameByUserId[u.supervisorId] || ''}</td>
							<td>{u.roles.map((ur) => ur.role).join(', ')}</td>
							<Table.Actions>
								<RouterLink to={`/admin/users/${u.id}`}>
									<Table.EditIcon />
								</RouterLink>
							</Table.Actions>
						</Table.Row>
					))}
				</Table.Body>
			</Table.Table>
		</div>
	)
}

function PageList() {
	const pages = _.orderBy(useSiteContext().siteMeta.pages, 'title')

	return (
		<Table.Table>
			<Table.Head>
				<Table.Row>
					<Table.Header>Name</Table.Header>
					<Table.ActionsHeader />
				</Table.Row>
			</Table.Head>
			<Table.Body>
				{pages.map((p) => (
					<Table.Row key={p.slug}>
						<td>{p.title}</td>
						<Table.Actions>
							<RouterLink to={`/admin/pages/${p.slug}`}>
								<Table.EditIcon />
							</RouterLink>
						</Table.Actions>
					</Table.Row>
				))}
			</Table.Body>
		</Table.Table>
	)
}
function PagePublish() {
	const location = useLocation()
	const pageSlug = location.pathname.split('/').pop()
	const page = useSiteContext().siteMeta.pages.find((p) => p.slug === pageSlug)
	const navigate = useNavigate()
	const [upload] = useUpload()
	const [updatePage] = useUpdatePageMastheadMutation({
		awaitRefetchQueries: true,
		refetchQueries: ['siteMeta']
	})

	return (
		<Formik
			enableReinitialize
			initialValues={{
				mastheadImage: null,
				name: page.title
			}}
			onSubmit={async (values) => {
				if (values.mastheadImage) {
					const { uploadId } = await upload(values.mastheadImage.file)
					await updatePage({
						variables: {
							slug: page.slug,
							mastheadUploadId: uploadId
						}
					})
				}

				navigate('/admin/pages')
			}}
		>
			{() => {
				return (
					<Form>
						<FormField name="name" type="text" label="Name" disabled />
						<Separator />
						<FormField
							name="mastheadImage"
							label="Masthead Image"
							type="file"
							image={{ aspect: 160 / 15, existing: page.masthead.publicUrl }}
						/>
						<FormField type="formButtons" submitLabel="Update Page" onCancel="/admin/pages" />
					</Form>
				)
			}}
		</Formik>
	)
}
function JobsiteList({ jobsites }: { jobsites: JobsitesQuery['jobsites'] }) {
	const [deleteJobsite] = useDeleteJobsiteMutation(jobsiteMutationParams)
	return (
		<div>
			<Table.Table>
				<Table.Head>
					<Table.Row>
						<Table.Header>Site Number</Table.Header>
						<Table.Header>Title</Table.Header>
						<Table.Header />
					</Table.Row>
				</Table.Head>
				<Table.Body>
					{jobsites.map((js) => (
						<Table.Row key={js.id}>
							<td>{js.siteNumber}</td>
							<td>{js.title}</td>
							<Table.Actions>
								<RouterLink to={`/admin/jobsites/${js.id}`}>
									<Table.EditIcon />
								</RouterLink>
								<Table.DeleteIcon
									onClick={async () => {
										if (confirm('Are you sure you want to delete this job site?')) {
											await deleteJobsite({ context, variables: { id: js.id } })
										}
									}}
								/>
							</Table.Actions>
						</Table.Row>
					))}
				</Table.Body>
			</Table.Table>
		</div>
	)
}

function DepartmentsAdminList({ users }: { users: UsersGetAllQuery['users'] }) {
	const { departments } = useSiteContext().siteMeta
	const usersById = _.keyBy(users, 'id')
	const getOwner = (userId: number) => {
		const user = usersById[userId]
		if (user) {
			return `${user.firstName} ${user.lastName}`
		}
		return '-'
	}
	return (
		<div>
			<Table.Table>
				<Table.Head>
					<Table.Row>
						<Table.Header>Name</Table.Header>
						<Table.Header>Owner</Table.Header>
						<Table.ActionsHeader />
					</Table.Row>
				</Table.Head>
				<Table.Body>
					{departments.map((d) => (
						<Table.Row key={d.id}>
							<td>{d.title}</td>
							<td>{getOwner(d.ownerId)}</td>
							<Table.Actions>
								<RouterLink to={`/departments/${d.id}/edit`}>
									<Table.EditIcon />
								</RouterLink>
							</Table.Actions>
						</Table.Row>
					))}
				</Table.Body>
			</Table.Table>
		</div>
	)
}

function DepartmentsAdminPublish({ users }: { users: UsersGetAllQuery['users'] }) {
	const navigate = useNavigate()
	const [createDepartment] = useCreateDepartmentMutation({
		awaitRefetchQueries: true,
		refetchQueries: ['siteMeta']
	})
	const userOptions = _.chain(users)
		.orderBy(['lastName', 'firstName'])
		.map((u) => ({
			label: `${u.lastName}, ${u.firstName}`,
			value: u.id.toString()
		}))
		.value()

	return (
		<div>
			<Formik
				enableReinitialize
				initialValues={{
					title: '',
					ownerId: { value: 0 }
				}}
				onSubmit={async (values) => {
					const { id } = (
						await createDepartment({
							context,
							variables: {
								title: values.title,
								ownerId: +values.ownerId.value,
								mastheadUploadId: 5365
							}
						})
					).data.insertDepartment
					navigate(`/departments/${id}/edit`)
				}}
			>
				{() => {
					return (
						<Form>
							<FormField name="title" type="text" label="Title" />
							<FormField name="ownerId" type="select2" label="Owner" options={userOptions} />

							<FormField
								type="formButtons"
								submitLabel="Create Department"
								onCancel="/admin/departments"
								autoWidth
							/>
						</Form>
					)
				}}
			</Formik>
		</div>
	)
}

function AdminRoute() {
	const jobsites = useJobsitesQuery().data?.jobsites
	const users = useUsersGetAllQuery().data?.users

	return (
		<Container>
			<LayoutWithSidebar>
				<Sidebar links={sidebarLinks}></Sidebar>
				<Routes>
					<Route path="/" element={<p>Select a section from the navigation. </p>} />
					<Route
						path="/users/*"
						element={
							<Routes>
								<Route path="/" element={users && <UsersList users={users}></UsersList>} />
								<Route path="/*" element={users && <UsersPublish users={users}></UsersPublish>} />
							</Routes>
						}
					/>{' '}
					<Route
						path="/jobsites/*"
						element={
							<Routes>
								<Route path="/" element={jobsites && <JobsiteList jobsites={jobsites}></JobsiteList>} />
								<Route
									path="/new"
									element={jobsites && <JobsitePublish jobsites={jobsites}></JobsitePublish>}
								/>
								<Route
									path="/*"
									element={jobsites && <JobsitePublish jobsites={jobsites}></JobsitePublish>}
								/>
							</Routes>
						}
					/>
					<Route
						path="/pages/*"
						element={
							<Routes>
								<Route path="/" element={<PageList></PageList>} />
								<Route path="/*" element={<PagePublish></PagePublish>} />
							</Routes>
						}
					/>
					<Route
						path="/departments/*"
						element={
							<Routes>
								<Route path="/" element={users && <DepartmentsAdminList users={users} />} />
								<Route path="/new" element={users && <DepartmentsAdminPublish users={users} />} />
							</Routes>
						}
					/>
				</Routes>
			</LayoutWithSidebar>
		</Container>
	)
}

export default AdminRoute
