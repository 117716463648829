import React, { useState } from 'react'
import RichText from 'src/components/RichText'
import { AccordianWrapper, Preview, Expanded, ArrowDown, ArrowUp } from './styled'

interface IAccordianProps {
	previewText: string
	expandedText: string
	className?: string
	adminElement?: any
	children?: React.ReactNode
}

function Accordian({ previewText, expandedText, className, children }: IAccordianProps) {
	const [open, setOpen] = useState(false)
	return (
		<AccordianWrapper className={className}>
			<Preview className={open && 'expanded'} onClick={() => setOpen(!open)}>
				{previewText}
				{open ? <ArrowUp /> : <ArrowDown />}
			</Preview>
			<Expanded className={open && 'expanded'}>
				<RichText html={expandedText} />
				{children}
			</Expanded>
		</AccordianWrapper>
	)
}

export default Accordian
