import _ from 'lodash'

export const commonRoutes = [
	{ title: 'Overview', path: 'overview' },
	{ title: 'Dept. Team', path: 'team' },
	{
		title: 'News & Announcements',
		path: 'news',
		subLinks: [{ title: 'Create a new announcement', path: 'publish', adminOnly: true }]
	},
	{
		title: 'Discussions',
		path: 'discussions',
		subLinks: [{ title: 'Create a new discussion', path: 'publish' }]
	}
]

const editDepartmentRoutes = [
	{ title: 'FAQ', path: 'faqs', subLinks: [{ title: 'Create a new question', path: 'publish', adminOnly: true }] },
	{ title: 'Edit Department', path: 'edit', adminOnly: true },
	{
		title: 'Manage Quick Links',
		path: 'quick-links',
		adminOnly: true,
		subLinks: [{ title: 'Create a new link', path: 'publish' }]
	},
	{ title: 'Add New Page', path: 'content/publish', adminOnly: true }
]

export const getDepartmentRoutes = (department, isAdmin: boolean) => {
	const adminFilter = isAdmin ? () => true : (r) => !r.adminOnly

	const commonRoutesContent = commonRoutes.filter(adminFilter).map((route) => {
		const { title } = route
		const to = `/departments/${department.id}/${route.path}`
		const subLinks = route.subLinks
			?.filter(adminFilter)
			.map((subLink) => ({ title: subLink.title, to: `${to}/${subLink.path}` }))
		return {
			title,
			to,
			subLinks
		}
	})

	const departmentSpecificRoutes = _.chain(department.contents)
		.map((content) => {
			const { title } = content
			const to = `/departments/${department.id}/contents/${content.id}`

			const subLinks =
				content.contents &&
				_.chain(content.contents)
					.map((subLink) => ({ title: subLink.title, to: `${to}/${subLink.id}` }))
					.orderBy('title')
					.value()

			return { title, to, subLinks }
		})
		.orderBy('title')
		.value()

	const editDepartmentLinks = editDepartmentRoutes.filter(adminFilter).map((link) => {
		const { title } = link
		const to = `/departments/${department.id}/${link.path}`
		const subLinks = link.subLinks
			?.filter(adminFilter)
			.map((subLink) => ({ title: subLink.title, to: `${to}/${subLink.path}` }))

		return { title, to, subLinks }
	})

	return [...commonRoutesContent, ...departmentSpecificRoutes, ...editDepartmentLinks]
}
