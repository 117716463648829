import React from 'react'

function AppleLogo() {
	return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 284.3 95"
			enableBackground="new 0 0 284.3 95"
			xmlSpace="preserve"
		>
			<g id="XMLID_206_">
				<g id="XMLID_267_">
					<path
						id="XMLID_271_"
						fill="#030202"
						d="M284.3,20.1c0-0.7,0-1.5,0-2.2c-0.1-1.6-0.1-3.2-0.4-4.8c-0.3-1.6-0.7-3.1-1.5-4.5
			c-0.7-1.4-1.7-2.7-2.8-3.9c-1.1-1.1-2.4-2.1-3.8-2.8c-1.5-0.7-2.9-1.2-4.5-1.5c-1.6-0.3-3.2-0.4-4.8-0.4c-0.7,0-1.5,0-2.2,0
			c-0.9,0-1.7,0-2.6,0h-239c-0.9,0-1.7,0-2.6,0c-0.7,0-1.4,0-2.2,0c-1.6,0-3.2,0.1-4.8,0.4C11.5,0.7,10,1.2,8.6,1.9
			C7.2,2.7,5.9,3.6,4.7,4.7C3.6,5.9,2.7,7.2,1.9,8.6c-0.7,1.4-1.2,2.9-1.5,4.5c-0.3,1.6-0.4,3.2-0.4,4.8c0,0.7,0,1.5,0,2.2
			c0,0.9,0,1.7,0,2.6v49.7c0,0.9,0,1.7,0,2.6c0,0.7,0,1.5,0,2.2c0,1.6,0.1,3.2,0.4,4.8c0.3,1.6,0.7,3.1,1.5,4.5
			c0.7,1.4,1.7,2.7,2.8,3.8c1.1,1.1,2.4,2.1,3.8,2.8c1.4,0.7,2.9,1.2,4.5,1.5c1.6,0.3,3.2,0.4,4.8,0.4c0.7,0,1.5,0,2.2,0
			c0.9,0,1.7,0,2.6,0h239c0.9,0,1.7,0,2.6,0c0.7,0,1.5,0,2.2,0c1.6,0,3.2-0.1,4.8-0.4c1.6-0.3,3.1-0.8,4.5-1.5
			c1.4-0.7,2.7-1.7,3.8-2.8c1.1-1.1,2.1-2.4,2.8-3.8c0.7-1.4,1.2-2.9,1.5-4.5c0.3-1.6,0.4-3.2,0.4-4.8c0-0.7,0-1.5,0-2.2
			c0-0.9,0-1.7,0-2.6V22.7C284.3,21.8,284.3,20.9,284.3,20.1z"
					/>
					<path
						id="XMLID_268_"
						fill="#909295"
						d="M261.6,2l2.6,0c0.6,0,1.2,0,1.8,0l0.4,0c1.2,0,2.9,0.1,4.5,0.4c1.4,0.3,2.7,0.7,4,1.3
			c1.2,0.6,2.4,1.5,3.4,2.4c1,1,1.8,2.1,2.4,3.3c0.6,1.2,1,2.5,1.3,4c0.3,1.5,0.4,3,0.4,4.4c0,0.7,0,1.4,0,2.2c0,0.8,0,1.7,0,2.6
			v49.7c0,0.9,0,1.7,0,2.6c0,0.7,0,1.4,0,2.1c-0.1,1.5-0.1,3-0.4,4.5c-0.2,1.4-0.7,2.7-1.3,4c-0.7,1.3-1.5,2.4-2.5,3.4
			c-1,1-2.1,1.8-3.3,2.4c-1.2,0.6-2.5,1-4,1.3c-1.5,0.3-3.1,0.4-4.4,0.4c-0.7,0-1.4,0-2.2,0c-0.9,0-1.7,0-2.6,0h-239
			c-0.9,0-1.7,0-2.6,0c-0.7,0-1.4,0-2.1,0c-1.3,0-2.9-0.1-4.5-0.4c-1.5-0.3-2.8-0.7-4-1.3c-1.2-0.6-2.4-1.4-3.4-2.5
			c-1-1-1.8-2.1-2.4-3.3c-0.6-1.2-1-2.5-1.3-4C2.1,80,2,78.4,2,77.1c0-0.5,0-1,0-1.5L2,75v-2.6V22.7l0-2.6l0-0.7c0-0.5,0-1,0-1.5
			c0-1.2,0.1-2.9,0.4-4.5c0.3-1.5,0.7-2.8,1.3-4c0.6-1.3,1.5-2.4,2.4-3.4c1-1,2.1-1.8,3.3-2.4c1.2-0.6,2.5-1.1,4-1.3
			C15.1,2.1,16.7,2,17.9,2l0.5,0c0.6,0,1.1,0,1.7,0l2.6,0H261.6 M261.6,0h-239c-0.9,0-1.7,0-2.6,0c-0.7,0-1.4,0-2.2,0
			c-1.6,0-3.2,0.1-4.8,0.4C11.5,0.7,10,1.2,8.6,1.9C7.2,2.7,5.9,3.6,4.7,4.7C3.6,5.9,2.7,7.2,1.9,8.6c-0.7,1.4-1.2,2.9-1.5,4.5
			c-0.3,1.6-0.4,3.2-0.4,4.8c0,0.7,0,1.5,0,2.2c0,0.9,0,1.7,0,2.6v49.7c0,0.9,0,1.7,0,2.6c0,0.7,0,1.5,0,2.2c0,1.6,0.1,3.2,0.4,4.8
			c0.3,1.6,0.7,3.1,1.5,4.5c0.7,1.4,1.7,2.7,2.8,3.8c1.1,1.1,2.4,2.1,3.8,2.8c1.4,0.7,2.9,1.2,4.5,1.5c1.6,0.3,3.2,0.4,4.8,0.4
			c0.7,0,1.5,0,2.2,0c0.9,0,1.7,0,2.6,0h239c0.9,0,1.7,0,2.6,0c0.7,0,1.5,0,2.2,0c1.6,0,3.2-0.1,4.8-0.4c1.6-0.3,3.1-0.8,4.5-1.5
			c1.4-0.7,2.7-1.7,3.8-2.8c1.1-1.1,2.1-2.4,2.8-3.8c0.7-1.4,1.2-2.9,1.5-4.5c0.3-1.6,0.4-3.2,0.4-4.8c0-0.7,0-1.5,0-2.2
			c0-0.9,0-1.7,0-2.6V22.7c0-0.9,0-1.7,0-2.6c0-0.7,0-1.5,0-2.2c-0.1-1.6-0.1-3.2-0.4-4.8c-0.3-1.6-0.7-3.1-1.5-4.5
			c-0.7-1.4-1.7-2.7-2.8-3.9c-1.1-1.1-2.4-2.1-3.8-2.8c-1.5-0.7-2.9-1.2-4.5-1.5c-1.6-0.3-3.2-0.4-4.8-0.4c-0.7,0-1.5,0-2.2,0
			C263.4,0,262.5,0,261.6,0L261.6,0z"
					/>
				</g>
				<g id="XMLID_233_">
					<path
						id="XMLID_264_"
						fill="#FFFFFF"
						d="M89.9,20.7c4.2,0,6.7,2.6,6.7,7c0,4.5-2.4,7.1-6.7,7.1h-5.1V20.7H89.9z M86.9,32.9h2.7
			c3,0,4.7-1.8,4.7-5.1c0-3.2-1.7-5.1-4.7-5.1h-2.7V32.9z"
					/>
					<path
						id="XMLID_261_"
						fill="#FFFFFF"
						d="M99,29.6c0-3.4,1.9-5.6,5-5.6c3.1,0,5,2.1,5,5.6c0,3.5-1.9,5.6-5,5.6
			C100.9,35.1,99,33,99,29.6z M106.9,29.6c0-2.3-1-3.7-2.9-3.7c-1.8,0-2.9,1.4-2.9,3.7c0,2.3,1,3.7,2.9,3.7
			C105.9,33.2,106.9,31.9,106.9,29.6z"
					/>
					<path
						id="XMLID_259_"
						fill="#FFFFFF"
						d="M122.5,34.9h-2.2l-2.2-7.9H118l-2.2,7.9h-2.2l-3-10.7h2.1l1.9,8.2h0.2l2.2-8.2h2l2.2,8.2
			h0.2l1.9-8.2h2.1L122.5,34.9z"
					/>
					<path
						id="XMLID_257_"
						fill="#FFFFFF"
						d="M127.9,24.2h2v1.7h0.2c0.5-1.2,1.6-1.9,3.2-1.9c2.4,0,3.7,1.4,3.7,4v6.9h-2.1v-6.4
			c0-1.7-0.7-2.6-2.3-2.6c-1.6,0-2.6,1-2.6,2.7v6.3h-2.1V24.2z"
					/>
					<path id="XMLID_255_" fill="#FFFFFF" d="M140.4,20h2.1v14.9h-2.1V20z" />
					<path
						id="XMLID_252_"
						fill="#FFFFFF"
						d="M145.4,29.6c0-3.4,1.9-5.6,5-5.6c3.1,0,5,2.1,5,5.6c0,3.5-1.9,5.6-5,5.6
			C147.3,35.1,145.4,33,145.4,29.6z M153.4,29.6c0-2.3-1-3.7-2.9-3.7c-1.8,0-2.9,1.4-2.9,3.7c0,2.3,1,3.7,2.9,3.7
			C152.3,33.2,153.4,31.9,153.4,29.6z"
					/>
					<path
						id="XMLID_249_"
						fill="#FFFFFF"
						d="M157.7,31.9c0-1.9,1.4-3,4-3.2l2.9-0.2v-0.9c0-1.1-0.7-1.8-2.2-1.8c-1.2,0-2,0.4-2.2,1.2
			h-2c0.2-1.8,1.9-3,4.4-3c2.7,0,4.2,1.3,4.2,3.6v7.3h-2v-1.5h-0.2c-0.6,1.1-1.8,1.7-3.2,1.7C159.2,35.1,157.7,33.8,157.7,31.9z
			 M164.6,31v-0.9l-2.6,0.2c-1.5,0.1-2.1,0.6-2.1,1.5c0,1,0.8,1.5,2,1.5C163.4,33.3,164.6,32.3,164.6,31z"
					/>
					<path
						id="XMLID_246_"
						fill="#FFFFFF"
						d="M169.5,29.6c0-3.4,1.7-5.5,4.4-5.5c1.5,0,2.7,0.7,3.3,1.9h0.2V20h2.1v14.9h-2v-1.7h-0.2
			c-0.6,1.2-1.9,1.9-3.4,1.9C171.2,35.1,169.5,33,169.5,29.6z M171.7,29.6c0,2.3,1.1,3.6,2.9,3.6c1.8,0,2.9-1.4,2.9-3.6
			c0-2.2-1.1-3.6-2.9-3.6C172.8,25.9,171.7,27.3,171.7,29.6z"
					/>
					<path
						id="XMLID_243_"
						fill="#FFFFFF"
						d="M188.2,29.6c0-3.4,1.9-5.6,5-5.6c3.1,0,5,2.1,5,5.6c0,3.5-1.9,5.6-5,5.6
			C190.1,35.1,188.2,33,188.2,29.6z M196.1,29.6c0-2.3-1-3.7-2.9-3.7c-1.8,0-2.9,1.4-2.9,3.7c0,2.3,1,3.7,2.9,3.7
			C195.1,33.2,196.1,31.9,196.1,29.6z"
					/>
					<path
						id="XMLID_241_"
						fill="#FFFFFF"
						d="M201.1,24.2h2v1.7h0.2c0.5-1.2,1.6-1.9,3.2-1.9c2.4,0,3.7,1.4,3.7,4v6.9h-2.1v-6.4
			c0-1.7-0.7-2.6-2.3-2.6c-1.6,0-2.6,1-2.6,2.7v6.3h-2.1V24.2z"
					/>
					<path
						id="XMLID_239_"
						fill="#FFFFFF"
						d="M222.2,21.6v2.7h2.3V26h-2.3v5.5c0,1.1,0.5,1.6,1.5,1.6c0.3,0,0.5,0,0.8,0v1.8
			c-0.3,0.1-0.7,0.1-1.2,0.1c-2.3,0-3.3-0.8-3.3-2.9v-6h-1.7v-1.8h1.7v-2.7H222.2z"
					/>
					<path
						id="XMLID_237_"
						fill="#FFFFFF"
						d="M227.4,20h2.1v5.9h0.2c0.5-1.2,1.7-1.9,3.3-1.9c2.3,0,3.7,1.4,3.7,4v6.9h-2.1v-6.4
			c0-1.7-0.8-2.6-2.3-2.6c-1.7,0-2.7,1.1-2.7,2.7v6.2h-2.1V20z"
					/>
					<path
						id="XMLID_234_"
						fill="#FFFFFF"
						d="M248.9,32c-0.5,1.9-2.2,3.1-4.6,3.1c-3.1,0-4.9-2.1-4.9-5.5c0-3.4,1.9-5.6,4.9-5.6
			c3,0,4.8,2,4.8,5.4v0.7h-7.6v0.1c0.1,1.9,1.2,3.1,2.8,3.1c1.3,0,2.2-0.5,2.5-1.3H248.9z M241.5,28.6h5.4c0-1.7-1.1-2.8-2.6-2.8
			S241.6,26.9,241.5,28.6z"
					/>
				</g>
				<g id="XMLID_229_">
					<g id="XMLID_230_">
						<path
							id="XMLID_232_"
							fill="#FFFFFF"
							d="M58.8,48.2c-0.1-6.5,5.4-9.7,5.6-9.9c-3.1-4.5-7.8-5.1-9.5-5.1c-4-0.4-7.9,2.4-9.9,2.4
				c-2.1,0-5.2-2.3-8.6-2.3c-4.3,0.1-8.4,2.6-10.6,6.5c-4.6,8-1.2,19.6,3.2,26.1c2.2,3.1,4.8,6.7,8.1,6.5c3.3-0.1,4.5-2.1,8.5-2.1
				c3.9,0,5.1,2.1,8.5,2c3.5-0.1,5.8-3.2,7.9-6.3c2.5-3.6,3.6-7.2,3.6-7.3C65.7,58.7,58.9,56.1,58.8,48.2z"
						/>
						<path
							id="XMLID_231_"
							fill="#FFFFFF"
							d="M52.4,29c1.8-2.2,3-5.2,2.6-8.3c-2.6,0.1-5.8,1.8-7.6,3.9c-1.6,1.9-3.1,5-2.7,8
				C47.5,32.9,50.5,31.2,52.4,29z"
						/>
					</g>
				</g>
				<g id="XMLID_207_">
					<path
						id="XMLID_226_"
						fill="#FFFFFF"
						d="M100.5,64.5H89.2l-2.7,8h-4.8l10.7-29.5h4.9L108,72.4h-4.8L100.5,64.5z M90.4,60.8h8.9
			l-4.4-12.9h-0.1L90.4,60.8z"
					/>
					<path
						id="XMLID_223_"
						fill="#FFFFFF"
						d="M131,61.7c0,6.7-3.6,11-9,11c-3.1,0-5.5-1.4-6.8-3.8h-0.1v10.7h-4.4V50.9h4.3v3.6h0.1
			c1.2-2.3,3.8-3.8,6.8-3.8C127.4,50.7,131,55,131,61.7z M126.5,61.7c0-4.4-2.3-7.2-5.7-7.2c-3.4,0-5.6,2.9-5.6,7.2
			c0,4.3,2.3,7.2,5.6,7.2C124.3,68.9,126.5,66.1,126.5,61.7z"
					/>
					<path
						id="XMLID_220_"
						fill="#FFFFFF"
						d="M154.7,61.7c0,6.7-3.6,11-9,11c-3.1,0-5.5-1.4-6.8-3.8h-0.1v10.7h-4.4V50.9h4.3v3.6h0.1
			c1.2-2.3,3.8-3.8,6.8-3.8C151.1,50.7,154.7,55,154.7,61.7z M150.2,61.7c0-4.4-2.3-7.2-5.7-7.2c-3.4,0-5.6,2.9-5.6,7.2
			c0,4.3,2.3,7.2,5.6,7.2C147.9,68.9,150.2,66.1,150.2,61.7z"
					/>
					<path
						id="XMLID_218_"
						fill="#FFFFFF"
						d="M170.4,64.2c0.3,2.9,3.2,4.8,7.1,4.8c3.7,0,6.4-1.9,6.4-4.6c0-2.3-1.6-3.7-5.4-4.6
			l-3.8-0.9c-5.4-1.3-7.9-3.8-7.9-8c0-5.1,4.4-8.6,10.7-8.6c6.2,0,10.5,3.5,10.7,8.6h-4.5c-0.3-2.9-2.7-4.7-6.3-4.7s-6,1.8-6,4.4
			c0,2.1,1.6,3.3,5.4,4.3l3.3,0.8c6.1,1.4,8.6,3.9,8.6,8.2c0,5.5-4.4,9-11.4,9c-6.5,0-11-3.4-11.2-8.7H170.4z"
					/>
					<path
						id="XMLID_216_"
						fill="#FFFFFF"
						d="M198,45.8v5.1h4.1v3.5H198v11.9c0,1.8,0.8,2.7,2.6,2.7c0.5,0,1.2-0.1,1.5-0.1v3.5
			c-0.5,0.1-1.5,0.2-2.5,0.2c-4.4,0-6.1-1.6-6.1-5.8V54.4h-3.1v-3.5h3.1v-5.1H198z"
					/>
					<path
						id="XMLID_213_"
						fill="#FFFFFF"
						d="M204.5,61.7c0-6.8,4-11,10.2-11c6.2,0,10.2,4.3,10.2,11c0,6.8-3.9,11-10.2,11
			C208.4,72.7,204.5,68.5,204.5,61.7z M220.4,61.7c0-4.6-2.1-7.4-5.7-7.4c-3.6,0-5.7,2.8-5.7,7.4c0,4.7,2.1,7.4,5.7,7.4
			C218.2,69.1,220.4,66.4,220.4,61.7z"
					/>
					<path
						id="XMLID_211_"
						fill="#FFFFFF"
						d="M228.5,50.9h4.2v3.7h0.1c0.7-2.4,2.6-3.9,5.2-3.9c0.6,0,1.2,0.1,1.5,0.2V55
			c-0.3-0.1-1.1-0.3-2-0.3c-2.8,0-4.6,1.9-4.6,4.9v12.8h-4.4V50.9z"
					/>
					<path
						id="XMLID_208_"
						fill="#FFFFFF"
						d="M259.9,66.1c-0.6,3.9-4.4,6.6-9.3,6.6c-6.3,0-10.1-4.2-10.1-10.9c0-6.7,3.9-11.1,10-11.1
			c6,0,9.7,4.1,9.7,10.6v1.5h-15.2v0.3c0,3.7,2.3,6.1,5.8,6.1c2.5,0,4.4-1.2,5-3H259.9z M244.9,59.7h10.8c-0.1-3.3-2.2-5.5-5.3-5.5
			C247.4,54.3,245.2,56.5,244.9,59.7z"
					/>
				</g>
			</g>
		</svg>
	)
}

export default AppleLogo
