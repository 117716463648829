import React from 'react'
import { SpinnerWrapper } from './styled'

export type Spinner = { className?: string; size?: 'xsm' | 'sm' | 'lg'; type?: 'primary' | 'secondary' }

const Spinner = ({ className, size = 'lg', type = 'primary' }: Spinner) => (
	<SpinnerWrapper className={className} id="spinner" size={size} type={type}>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</SpinnerWrapper>
)

export default Spinner

export function StateSpinner() {
	return (
		<div style={{ width: '100%', textAlign: 'center' }}>
			<Spinner />
		</div>
	)
}
