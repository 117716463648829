import React from 'react'
import axios from 'axios'
import useAsync, { AsyncState } from 'react-use/lib/useAsync'

const { get: getWeatherBitAxios } = axios.create({
	baseURL: 'https://api.weatherbit.io/v2.0/current',
	params: {
		key: '17302b753289429c897b46c197893c04',
		city: 'SanFrancisco,CA',
		units: 'I'
	}
})

const Context = React.createContext<
	AsyncState<{
		temp: string
		description: string
		iconUrl: string
	}>
>(null)

export function WeatherProvider({ children }: { children: React.ReactNode }) {
	const state = useAsync(async () => {
		const response = await getWeatherBitAxios<{
			data: {
				temp: string
				weather: {
					description: string
					icon: string
				}
			}[]
		}>('/')
		const data = response.data.data[0]
		return {
			temp: `${data.temp} °F`,
			description: data.weather.description,
			iconUrl: `https://www.weatherbit.io/static/img/icons/${data.weather.icon}.png`
		}
	})
	return <Context.Provider value={state}>{children}</Context.Provider>
}

export function useWeather() {
	return React.useContext(Context)
}
