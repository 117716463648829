import React from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { BulletinDetailsFragment, useDeleteBulletinMutation } from 'shared/schema'
import RichText from 'src/components/RichText'
import Comments from 'src/components/Comments'
import {
	NewsDetailContainer,
	NewsContentContainer,
	NewsByline,
	NewsEditActions,
	NewsAction,
	NewsActionLink,
	ResourcesHeader
} from './styled'
import UserProfilePhoto from '../UserProfilePhoto'
import { Route, Routes, useNavigate } from 'react-router-dom'
import NewsPublish from 'src/routes/news/publish'
import { RoleGate, useUserRoles } from '../Roles'
import LinkCard from '../LinkCard'
dayjs.extend(relativeTime)

// todo: commentsRefetch -> mutationRefetch
function NewsDetail(props: { bulletin: BulletinDetailsFragment; commentsRefetch: string }) {
	const { bulletin, commentsRefetch } = props
	const navigate = useNavigate()

	const [deleteBulletin] = useDeleteBulletinMutation({
		refetchQueries: [commentsRefetch],
		awaitRefetchQueries: true
	})
	const { useRole } = useUserRoles()

	return (
		<Routes>
			<Route
				path="/"
				element={
					<>
						<NewsDetailContainer>
							<NewsByline>
								<UserProfilePhoto src={bulletin.author?.profilePhotoUrl} />
								<span>
									{bulletin.author?.firstName} {bulletin.author?.lastName}
								</span>
								<span>•</span>
								<span>{dayjs(bulletin.createdAt).format('ll')}</span>
							</NewsByline>
							<RoleGate role={['SuperAdmin', 'Admin']}>
								<NewsEditActions>
									<NewsAction to="publish">Edit</NewsAction>
									<NewsActionLink
										onClick={async () => {
											if (confirm('Are you sure you want to delete this news announcement?')) {
												await deleteBulletin({
													context: {
														useRole
													},
													variables: {
														id: bulletin.id
													}
												})
												navigate('..')
											}
										}}
									>
										Delete
									</NewsActionLink>
								</NewsEditActions>
							</RoleGate>
							<img src={bulletin.leadWebUrl} className="article-image" />
							<NewsContentContainer>
								<RichText html={bulletin.post} />
							</NewsContentContainer>
						</NewsDetailContainer>
						{bulletin.bulletinResources.length > 0 && (
							<div style={{ clear: 'both', paddingTop: '3rem' }}>
								<ResourcesHeader>Related Resources</ResourcesHeader>
								{bulletin.bulletinResources.map(({ resource }, index) => {
									return <LinkCard key={`${resource.title}-${index}`} data={resource} />
								})}
							</div>
						)}
						<Comments comments={bulletin.comments} bulletinId={+bulletin.id} refetch={commentsRefetch} />
					</>
				}
			/>
			<Route path="/publish" element={<NewsPublish existing={bulletin} />} />
		</Routes>
	)
}

export default NewsDetail
