import { useEffect } from 'react'
import { useLocation } from 'react-router'

interface GA {
	(command: string, ...fields: any[]): void
}

function GAPageView() {
	const { pathname } = useLocation()
	useEffect(() => {
		const w = window as { ga?: GA }
		if (w.ga) {
			w.ga('set', 'page', pathname)
			w.ga('send', 'pageview')
		}
	}, [pathname])
	return null
}

export default GAPageView
