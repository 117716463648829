import React from 'react'
import { Route, Routes, useParams } from 'react-router-dom'

import { DiscussionList, DiscussionPublish, DiscussionsDetail } from 'src/components/Discussions'
import ContentHeader from '../ContentHeader'
import { useDepartment } from 'src/routes/department'

function DeptDiscussionsDetail() {
	const department = useDepartment()
	const { discussionId } = useParams()
	const discussion = department.discussions.find((d) => d.id === +discussionId)

	if (!discussion) {
		return null
	}

	return (
		<>
			<ContentHeader primaryColor={false} parent={{ __typename: 'static', to: '..', label: 'Discussions' }}>
				{discussion.title}
			</ContentHeader>
			<DiscussionsDetail discussion={discussion} />
		</>
	)
}

export function DiscussionsList() {
	const department = useDepartment()

	return (
		<div>
			<Routes>
				<Route
					path="/"
					element={
						<>
							<ContentHeader>Discussions</ContentHeader>
							{department.discussions.length === 0 && (
								<p>This department does not have any discussions yet!</p>
							)}
							<DiscussionList
								departmentId={department.id}
								discussions={department.discussions}
							></DiscussionList>
						</>
					}
				/>
				<Route
					path="/publish"
					element={
						<>
							<ContentHeader parent={{ __typename: 'static', to: '..', label: 'Discussions' }}>
								Publish Discussion
							</ContentHeader>
							<DiscussionPublish departmentId={department.id} />
						</>
					}
				/>
				<Route path="/:discussionId" element={<DeptDiscussionsDetail />} />
			</Routes>
		</div>
	)
}
