import React from 'react'
import styled from 'styled-components'
import { useDepartment } from 'src/routes/department'
import ContentHeader from '../ContentHeader'
import PersonCard from 'src/components/PersonCard'

import { useDepartmentEmployeesQuery } from 'shared/schema'
import _ from 'lodash'

const TeamContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`

function Team() {
	const department = useDepartment()
	const { data } = useDepartmentEmployeesQuery({ variables: { departmentId: department.id } })

	return (
		<div>
			<ContentHeader>Dept Team.</ContentHeader>
			<TeamContainer>
				{data?.departmentById?.employees &&
					_.orderBy(data.departmentById.employees, ['lastName', 'firstName']).map((person) => (
						<PersonCard key={person.id} person={person} />
					))}
			</TeamContainer>
		</div>
	)
}

export default Team
