import React from 'react'
import { Route, Routes } from 'react-router-dom'

import QuickLinksList from './list'
import QuickLinksForm from './form'

function QuickLinksRoutes() {
	return (
		<Routes>
			<Route path="/" element={<QuickLinksList />} />
			<Route path="/publish" element={<QuickLinksForm />} />
			<Route path="/:id/publish" element={<QuickLinksForm />} />
		</Routes>
	)
}

export default QuickLinksRoutes
