import styled from 'styled-components'
import { CalendarEvent } from 'src/icons'
import { NavLink } from 'react-router-dom'

export const EditActions = styled.div`
	display: flex;
	padding-top: 1rem;
`

export const Action = styled(NavLink)`
	text-decoration: none;
	color: ${({ theme }) => theme.colors.primary};
	line-height: 40px;
	padding-right: 20px;
`

export const ActionLink = styled.a`
	text-decoration: none;
	cursor: pointer;
	color: ${({ theme }) => theme.colors.primary};
	line-height: 40px;
	padding-right: 20px;
`

export const Filters = styled.form`
	border: 1px solid ${({ theme }) => theme.colors.borderGray};
	padding: 20px;
	margin-top: 20px;
	width: 260px;
	h3 {
		margin-bottom: 15px;
		font-weight: 500;
		font-size: 16px;
	}
`

export const FilterContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 5px 0px;
	cursor: pointer;
`

export const FilterLabel = styled.label`
	font-size: 13px;
	padding-left: 5px;
	cursor: pointer;
`

export const CalendarIcon = styled(CalendarEvent)`
	height: 1rem;
	width: 1rem;
`
