import React from 'react'
import Container from 'src/components/Container'
import { HeaderHero, HeaderContainer, HomeHeroWrapper, Logo } from './styled'

interface IHeaderProps {
	imageUrl: string
	title: string
	hideTitle?: boolean
}

function Header({ imageUrl, title, hideTitle }: IHeaderProps) {
	return (
		<HeaderContainer>
			<HeaderHero imageUrl={imageUrl}>
				{title === 'Home' && (
					<HomeHeroWrapper>
						<Container>
							<h2>Welcome to</h2>
							{/* TO DO: White version of logo */}
							<Logo type="light" />
						</Container>
					</HomeHeroWrapper>
				)}
			</HeaderHero>
			<Container>{!hideTitle && <h1>{title}</h1>}</Container>
		</HeaderContainer>
	)
}

export default Header
