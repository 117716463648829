import { useAddUploadMutation, useGetPresignedUploadMutation } from 'shared/schema'

export const useUpload = (isPublic = true) => {
	const [getPresignedUpload] = useGetPresignedUploadMutation()
	const [addUpload] = useAddUploadMutation()

	const upload = async (file) => {
		const { size, name, type } = file
		const prefix = Math.floor(Math.random() * 100000000).toString()
		const fileName = `${prefix}-${name}`.replace(/[^a-zA-Z0-9_.]+/g, '_')

		const result = await getPresignedUpload({
			variables: {
				type,
				fileName,
				isPublic: isPublic
			}
		})
		const { fields, url, presignedGetUrl } = result.data.getPresignedUpload
		const formData = new FormData()
		for (const [key, value] of Object.entries(fields)) {
			formData.append(key, value)
		}
		formData.append('file', file)
		await fetch(url, {
			method: 'post',
			body: formData
		})
		const newUpload = await addUpload({
			variables: {
				key: `staging/uploads/${fileName}`,
				size,
				type,
				url: presignedGetUrl
			}
		})
		return {
			uploadId: newUpload.data.insertUpload.id,
			publicUrl: newUpload.data.insertUpload.publicUrl
		}
	}

	return [upload]
}
