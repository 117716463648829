import styled from 'styled-components'
import { Form } from 'formik'

export const Subtitle = styled.div`
	font-size: 16px;
	color: ${({ theme }) => theme.colors.lightestGray};
	display: flex;
	align-items: center;
	img {
		height: 30px;
		width: 30px;
		float: left;
		padding-left: 0;
		padding-right: 10px;
	}
`
export const TinyCommentsContainer = styled.div`
	clear: both;
	margin-top: 8px;
`

export const CommentsContainer = styled.div`
	clear: both;
	margin-top: 20px;
	padding: 40px 30px 60px 30px;
	background-color: ${({ theme }) => theme.colors.lightGray};
	border-radius: 3px;
	h2 {
		font-size: 18px;
		margin-bottom: 25px;
		font-weight: 400;
	}
`

export const CommentContainer = styled.div`
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
	p {
		font-size: 12px;
		font-weight: lighter;
	}
`

export const TinyCommentContainer = styled.div`
	padding-top: 8px;
	padding-bottom: 8px;
	border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
	font-size: 80%;
	line-height: 24px;
	p {
		font-size: 12px;
		font-weight: lighter;
	}
	img {
		float: left;
		width: 24px;
		padding-left: 0px;
		padding-right: 12px;
	}
	.commenter {
		font-weight: 500;
		padding-right: 0.5rem;
	}
	.comment {
		font-weight: 400;
	}
`

export const Comment = styled.span`
	font-weight: 300;
	font-size: 95%;
	line-height: 1.4;
`

export const CommenterInformation = styled.div`
	padding-bottom: 10px;
	img {
		float: left;
		width: 50px;
		padding-left: 0px;
		padding-right: 20px;
	}
	h3 {
		display: inline-block;
		font-size: 16px;
		font-weight: normal;
	}
	h4 {
		padding-left: 15px;
		display: inline-block;
		font-size: 12px;
		font-weight: lighter;
	}
`

export const CommentAction = styled.button`
	padding-top: 10px;
	display: inline-block;
	color: ${({ theme }) => theme.colors.primary};
	font-size: 14px;
	border: none;
	background: none;
	&:first-child {
		padding-right: 10px;
	}
	&:hover {
		cursor: pointer;
	}
`

export const EditComment = styled(Form)`
	input {
		width: 80%;
	}
`
export const TinyAddComment = styled(Form)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 8px;
	border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
	> div {
		width: 80%;
		> div {
			padding-top: 0;
		}
	}
	> button {
		width: 20%;
		padding-top: 0;
	}
	img {
		float: left;
		width: 50px;
		height: 50px;
		padding-left: 0px;
		padding-right: 20px;
	}
`

export const AddComment = styled(Form)`
	div {
		display: flex;
		padding-bottom: 10px;
	}
	img {
		float: left;
		width: 50px;
		height: 50px;
		padding-left: 0px;
		padding-right: 20px;
	}

	button {
		float: right;
	}
`
