import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'

const GlobalStyles = createGlobalStyle`

	${normalize()}

	:root {
		--spinner-color: ${(props) => props.theme.colors.primary};
		--spinner-size: 64px;
		--spinner-stroke: 3px;
		--header-height: 54px;
		--container-min-width: 860px;
		--container-max-width: 1180px;
		--grid-basis: 16px;
		--color-black: #071633;
		--color-body: var(--color-black);
		--form-control-trim: #B4B9C1;
	}

	body, input, textarea {
		color:var(--color-body);
		font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
		-webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale; font-smoothing:antialiased; text-rendering:optimizeLegibility;
	}

	h1, h2, h3, h4, h5, h6 {
		margin:0;
	}

	img {
		display:block; max-width:100%; height:auto;
	}

	a {
		color:var(--color-blue); text-decoration:underline;
	}

	p {
		&:first-child {
			margin-top:0;
		}
		&:last-child {
			margin-bottom:0;
		}
	}
	.user-rich-text {
		line-height: 1.8;
		font-weight: 300;
		strong {
			font-weight: 600;
		}
	}

`

export default GlobalStyles
