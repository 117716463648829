import React from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { useDepartment } from 'src/routes/department'
import { useParams } from 'react-router-dom'

import NewsDetail from 'src/components/NewsDetail'
import ContentHeader from '../ContentHeader'

dayjs.extend(relativeTime)

function NewsDetailRoute() {
	const { bulletinId } = useParams()
	const department = useDepartment()

	const bulletin = department.bulletins.find((b) => b.id == +bulletinId)

	return (
		<div>
			<ContentHeader
				primaryColor={false}
				parent={{
					to: `/departments/${department.id}/news`,
					label: 'News & Announcements',
					__typename: 'static'
				}}
			>
				{bulletin.title}
			</ContentHeader>
			<NewsDetail bulletin={bulletin} commentsRefetch="departmentDetails" />
		</div>
	)
}

export default NewsDetailRoute
