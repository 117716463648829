import React from 'react'
import { Formik, Form } from 'formik'
import FormField from 'src/components/FormField'

import {
	useCreateEventMutation,
	GetAllEventsDocument,
	EventTypes_Enum,
	useGetAllEventsQuery,
	useUpdateEventMutation
} from 'shared/schema'
import { useNavigate, useParams } from 'react-router-dom'
import { useUserRoles } from 'src/components/Roles'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import dayjs from 'dayjs'
dayjs.extend(utc)
dayjs.extend(timezone)

const eventOptions = [
	{
		label: 'Nibbi Event',
		value: 'NIBBI_EVENT'
	},
	{
		label: 'Birthday',
		value: 'BIRTHDAY'
	},
	{
		label: 'Industry Event',
		value: 'INDUSTRY_EVENT'
	},
	{
		label: 'Training',
		value: 'TRAINING'
	},
	{
		label: 'Holiday',
		value: 'HOLIDAY'
	},
	{
		label: 'Oakland Office',
		value: 'OAKLAND_OFFICE'
	}
]

function CreateNewEvent() {
	const navigate = useNavigate()
	const params = useParams()
	const { data: eventsData } = useGetAllEventsQuery({
		skip: !params.eventId
	})

	const [createEvent] = useCreateEventMutation({
		refetchQueries: [{ query: GetAllEventsDocument }],
		awaitRefetchQueries: true
	})
	const [updateEvent] = useUpdateEventMutation({
		refetchQueries: [{ query: GetAllEventsDocument }],
		awaitRefetchQueries: true
	})
	const { useRole } = useUserRoles()
	if (params.eventId && !eventsData) {
		return null
	}
	const existing = params.eventId && eventsData.events.find((ev) => ev.id === +params.eventId)
	let initialValues = {
		eventTitle: '',
		from: {
			date: '',
			time: ''
		},
		to: {
			date: '',
			time: ''
		},
		allDay: false,
		eventType: eventOptions[0],
		eventDescription: ''
	}
	if (existing) {
		const parseDate = (input) => {
			const [date, time] = dayjs(input).tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm').split(' ')
			return {
				date,
				time
			}
		}

		initialValues = {
			eventTitle: existing.title,
			from: parseDate(existing.startsAt),
			to: parseDate(existing.endsAt),
			allDay: existing.allDay,
			eventType: eventOptions.find((eo) => eo.value === existing.type),
			eventDescription: existing.description || ''
		}
	}
	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={async (values) => {
				const startDate = dayjs
					.tz(
						`${values.from.date} ${values.allDay || !values.from.time ? '08:00' : values.from.time}`,
						'America/Los_Angeles'
					)
					.toISOString()
				const endDate = dayjs
					.tz(
						`${values.to.date} ${values.allDay || !values.to.time ? '08:00' : values.to.time}`,
						'America/Los_Angeles'
					)
					.toISOString()

				const variables = {
					allDay: values.allDay,
					startsAt: startDate,
					endsAt: endDate,
					description: values.eventDescription,
					title: values.eventTitle,
					type: values.eventType.value
				}

				if (existing) {
					await updateEvent({
						context: { useRole },
						variables: { ...variables, id: existing.id }
					})
					navigate('../..')
				} else {
					await createEvent({
						context: {
							useRole
						},
						variables
					})
					navigate('..')
				}
			}}
		>
			{({ setFieldValue, values }) => (
				<Form>
					<FormField name="eventTitle" type="text" label="Event Title" placeholder="Event title" />
					<FormField type="timeDateGroup" label="Date and Time" />
					<FormField name="eventType" type="select2" label="Event Type" options={eventOptions} />
					<FormField
						name="eventDescription"
						type="richText"
						label="Event Description"
						value={values.eventDescription}
						placeholder="Write an optional description here..."
						onChange={(val) => setFieldValue('eventDescription', val)}
					/>
					<FormField type="formButtons" onCancel={existing ? '../..' : undefined} />
				</Form>
			)}
		</Formik>
	)
}

export default CreateNewEvent
