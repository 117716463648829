import styled from 'styled-components'

import LogoImg from 'src/components/Logo'

export const HeaderContainer = styled.div`
	margin-bottom: 80px;
	h1 {
		position: absolute;
		color: #fff;
		background: ${(props) => props.theme.colors.primary};
		display: inline-block;
		padding: 0px 15px 5px 15px;
		font-size: 4rem;
		transform: translateY(-50%);
		font-weight: lighter;
	}
`

export const Logo = styled(LogoImg)`
	margin-top: 25px;
	height: 230px;
	width: 230px;
`

export const HomeHeroWrapper = styled.div`
	height: 230px;
	background: linear-gradient(to right, rgba(0, 0, 0, 0.6) 15%, rgba(0, 0, 0, 0));
	h2 {
		font-size: 30px;
		display: block;
		line-height: 28px;
		color: #fff;
		font-weight: 300;
		position: absolute;
		margin-top: 30px;
	}
`

export const HeaderHero = styled.div`
	width: 100%;
	height: 230px;
	background: url(${(props: { imageUrl: string }) => props.imageUrl});
	background-size: cover;
	background-position: center;
	position: relative;
`
