import React from 'react'
import { Route, Routes } from 'react-router'
import Container from 'src/components/Container'
import LayoutWithSidebar from 'src/components/LayoutWithSidebar'
import Sidebar, { Link } from 'src/components/Sidebar'
import { Form, Formik } from 'formik'
import FormField from 'src/components/FormField'
import styled from 'styled-components'
import _ from 'lodash'
import { useDoceboMetaQuery, useUpdateCourseMutation } from 'shared/schema'

const context = {
	useRole: 'HR'
}

const SideBySide = styled.div`
	display: flex;
	> :nth-child(1) {
		width: 50%;
		padding-right: 1rem;
	}
	> :nth-child(2) {
		width: 25%;
		padding-right: 1rem;
	}
	> :nth-child(3) {
		width: 25%;
	}
	padding-bottom: 1rem;
`

function DoceboRoute() {
	const { data } = useDoceboMetaQuery({
		context
	})

	const [updateCourse] = useUpdateCourseMutation({
		refetchQueries: ['doceboMeta'],
		awaitRefetchQueries: true,
		context
	})

	if (!data) {
		return <p></p>
	}
	const certificationOptions = data.v2_adp_certifications.map((c) => ({
		label: `${c.code} - ${c.description}`,
		value: c.code
	}))

	const categoryOptions = data.v2_adp_certification_categories.map((c) => ({
		label: c.name,
		value: c.code
	}))

	const lengthOptions = [
		{
			label: '1 year',
			value: '365'
		},
		{
			label: '2 years',
			value: '730'
		},
		{
			label: '3 years',
			value: '1095'
		},
		{
			label: '4 years',
			value: '1460'
		},
		{
			label: '5 years',
			value: '1825'
		},
		{
			label: 'Never',
			value: '3650'
		}
	]

	const initialValues = _.chain(data.v2_docebo_courses)
		.keyBy('uid')
		.pickBy((course) => !!course.adp_certification_code)
		.mapValues((course) => ({
			certificate: certificationOptions.find((c) => c.value === course.adp_certification_code),
			category: categoryOptions.find((cat) => cat.value === course.adp_certification_category_code),
			days: lengthOptions.find((length) => +length.value === course.adp_certification_days)
		}))
		.value()

	return (
		<div>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={async (values, actions) => {
					const changes = _.chain(values)
						.map((val, key) => {
							if (!val.certificate?.value) {
								return {
									days: null,
									category_code: null,
									code: null,
									uid: key
								}
							}
							return {
								days: +val.days.value || null,
								category_code: val.category?.value || null,
								code: val.certificate.value || null,
								uid: key
							}
						})
						.filter(({ uid, days, category_code, code }) => {
							const existing = data.v2_docebo_courses.find((c) => c.uid === uid)
							if (!existing) {
								return false
							}
							return (
								existing.adp_certification_days !== days ||
								existing.adp_certification_category_code !== category_code ||
								existing.adp_certification_code !== code
							)
						})
						.value()
					await Promise.all(
						changes.map((change) =>
							updateCourse({
								variables: change
							})
						)
					)
					window.scrollTo(0, 0)
					actions.setSubmitting(false)
				}}
			>
				{({ values, setFieldValue }) => {
					return (
						<Form>
							{data.v2_docebo_courses.map((course) => (
								<div
									key={course.uid}
									style={{
										marginBottom: '1.5rem',
										paddingBottom: '1.5rem',
										borderBottom: '1px solid #eee'
									}}
								>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<img src={course.img} style={{ width: '96px' }} />
										<span style={{ paddingLeft: '2rem', fontSize: '125%', fontWeight: 600 }}>
											{course.name}
											<br />
											<span style={{ fontSize: '75%', fontWeight: 400 }}>
												Code: {course.code}
											</span>
										</span>
									</div>
									<SideBySide>
										<FormField
											name={`${course.uid}.certificate`}
											type="select2"
											options={certificationOptions}
											onChange={(val) => {
												const daysField = `${course.uid}.days`
												const categoryField = `${course.uid}.category`

												if (val === null) {
													setFieldValue(daysField, null)
													setFieldValue(categoryField, null)
												} else if (!values[course.uid]?.days) {
													setFieldValue(daysField, lengthOptions[0])
												}
											}}
											label="Certification"
											$isClearable
										/>
										<FormField
											name={`${course.uid}.days`}
											type="select2"
											options={lengthOptions}
											label="Expiration"
										/>
										<FormField
											name={`${course.uid}.category`}
											type="select2"
											options={categoryOptions}
											label="Category"
											$isClearable
										/>
									</SideBySide>
								</div>
							))}
							<FormField type="formButtons" submitLabel="Save" hideBorderTop={true} $hideCancel />
						</Form>
					)
				}}
			</Formik>
		</div>
	)
}

const sidebarLinks: Link[] = [{ title: 'Link Docebo Courses to ADP', to: '/hr/docebo' }]

function HRRoute() {
	return (
		<Container>
			<LayoutWithSidebar>
				<Sidebar links={sidebarLinks}></Sidebar>
				<Routes>
					<Route path="/" element={<p>Select a section from the navigation to the left. </p>} />
					<Route path="/docebo/" element={<DoceboRoute />} />
				</Routes>
			</LayoutWithSidebar>
		</Container>
	)
}

export default HRRoute
