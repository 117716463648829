import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useModal } from 'src/components/Modal'
import { Form, Formik } from 'formik'
import FormField from 'src/components/FormField'
import Container from 'src/components/Container'
import dayjs from 'dayjs'
import {
	useUsersGetAllQuery,
	useRecentBulletinsQuery,
	useRecentDiscussionsQuery,
	useRecentResourcesQuery,
	UsersGetAllQuery,
	useAddNominationMutation,
	useGetNominationsQuery,
	useRemoveNominationMutation,
	useSendNominationEmailMutation
} from 'shared/schema'

import LinkCardList from 'src/components/LinkCardList'
import ImageCard from 'src/components/ImageCard'
import {
	HeroQuoteBackground,
	HeroQuotePrimary,
	HeroQuoteSecondary,
	QuoteContainer,
	CoreValuesContainer,
	CoreValues,
	CoreValue
} from './styled'

import Button from 'src/components/Button'
import { NomCard } from 'src/components/PersonCard'
import { useAuthGate } from 'src/components/AuthGate'
import _ from 'lodash'
import { Tabs } from 'react-tabs'
import { Tab as ReactTab, TabList as ReactTabList, TabPanel as ReactTabsPanel } from 'react-tabs'
export const TabList = styled(ReactTabList)`
	display: flex;
	list-style: none;
	padding-left: 0;
`

export const Tab = styled(ReactTab)`
	padding: 15px 10px;
	margin-right: 10px;
	&:hover {
		cursor: pointer;
		color: ${({ theme }) => theme.colors.primary};
	}
	&.active {
		font-weight: 500;
		color: ${({ theme }) => theme.colors.primary};
		border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
	}
`

export const TabContent = styled(ReactTabsPanel)``

// TODO: create a component for this
const SideBySide = styled.div`
	border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
	font-size: 16px;
	display: flex;
	margin-top: 20px;
	> * {
		width: 50%;
	}
`

const SideBySideNoBorder = styled.div`
	font-size: 16px;
	display: flex;
	> .left {
		width: 60%;
		padding-right: 2rem;
	}
	> .right {
		border-left: 1px solid #f3f3f3;
		padding-top: 1rem;
		margin-left: 2rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;

		background-color: #f8f8f8;
		width: 33%;
		border-radius: 16px;
		p {
			line-height: 1.6;
			font-size: 15px;
		}
	}
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 15px 0px;
	h2 {
		font-weight: 100;
		font-size: 24px;
	}
	h3 {
		font-weight: 100;
		font-size: 18px;
	}
`

function HomeRoute() {
	const { user } = useAuthGate()

	const { data: newsData } = useRecentBulletinsQuery()
	const { data: discussionsData } = useRecentDiscussionsQuery()
	const { data: resourcesData } = useRecentResourcesQuery()

	const favoriteDiscussions = user.favoriteDiscussions.map((fav) => fav.discussion)
	const favoriteResources = user.favoriteResources.map((fav) => fav.resource)

	const bulletins = newsData?.bulletins ?? []

	const { loading: usersLoading, data: usersData } = useUsersGetAllQuery()

	const userOptions = (usersData?.users ?? []).map((u: UsersGetAllQuery['users'][number]) => ({
		label: `${u.firstName} ${u.lastName}`,
		value: `${u.id}`
	}))
	const categoryOptions = [
		{ value: 'Culture', label: 'Culture' },
		{ value: 'Safety', label: 'Safety' },
		{ value: 'Sustainability', label: 'Sustainability' },
		{ value: 'Initiative', label: 'Initiative', explainer: 'Went the extra mile' },
		{
			value: 'Mentoring',
			label: 'Mentoring',
			explainer: 'Provided guidance and support to someone outside the scope of their job'
		},
		{ value: 'Innovation', label: 'Innovation', explainer: 'Improved a process' },
		{
			value: 'Community',
			label: 'Community',
			explainer:
				'Initiated or participated in a community project on behalf of Nibbi, or went the extra mile to build relationships in the communities where we work'
		},
		{
			value: 'Excellence',
			label: 'Excellence',
			explainer: 'Outstanding result such as financial, schedule, quality, or resolving a sticky issue'
		},
		{
			value: 'Client Service',
			label: 'Client Service',
			explainer:
				'Exceeded client expectations, or Nibbi awarded a new job as a direct result of a person or team effort'
		}
	]

	const [currentTab, setCurrentTab] = useState(0)
	const [singleNom, setSingleNom] = useState(null)

	const { data: nominationsRawData } = useGetNominationsQuery({
		skip: !(usersData?.users.length > 0)
	})
	const nominationsLoaded = Boolean(nominationsRawData?.v2_nominations)

	const usersById = _.keyBy(usersData?.users || [], 'id')

	let nominations = []
	let tabNominations = []

	if (nominationsLoaded) {
		const thisMonthStart = dayjs().startOf('month')
		const thisMonthEnd = dayjs().endOf('month')
		const lastMonthStart = dayjs().subtract(1, 'month').startOf('month')
		const lastMonthEnd = dayjs().subtract(1, 'month').endOf('month')

		nominations = nominationsRawData.v2_nominations.map((n) => {
			return {
				id: n.id,
				user: usersById[n.to_user_id] || { firstName: n.to_name },
				values: n
			}
		})
		if (singleNom) {
			tabNominations = nominations.filter((n) => n.id === singleNom)
		} else if (currentTab === 0) {
			tabNominations = nominations.filter(
				(nom) =>
					dayjs(nom.values.created_at).isAfter(thisMonthStart) &&
					dayjs(nom.values.created_at).isBefore(thisMonthEnd)
			)
		} else if (currentTab === 1) {
			tabNominations = nominations.filter(
				(nom) =>
					dayjs(nom.values.created_at).isAfter(lastMonthStart) &&
					dayjs(nom.values.created_at).isBefore(lastMonthEnd)
			)
		} else {
			tabNominations = nominations
		}
	}

	const [addNominationMutation] = useAddNominationMutation({
		refetchQueries: ['getNominations'],
		awaitRefetchQueries: true
	})
	const [sendNominationEmail] = useSendNominationEmailMutation()

	const [removeNominationMutation] = useRemoveNominationMutation({
		refetchQueries: ['getNominations'],
		awaitRefetchQueries: true
	})

	const nomModalHeader = singleNom ? 'Cheers Nomination' : 'Cheers Nominations'

	const [allNomsModal, setShowAllNominations] = useModal({
		tall: true,
		header: nomModalHeader,
		onClose: () => {
			setSingleNom(null)
		},
		children: (
			<div>
				{!singleNom && (
					<Tabs
						style={{ paddingBottom: '1rem' }}
						selectedIndex={currentTab}
						selectedTabClassName="active"
						onSelect={(index) => {
							setCurrentTab(index)
						}}
					>
						<TabList>
							<Tab>This Month</Tab>
							<Tab>Last Month</Tab>
							<Tab>All Time</Tab>
						</TabList>
						<TabContent />
						<TabContent />
						<TabContent />
					</Tabs>
				)}

				{tabNominations.length === 0 && (
					<div>
						{currentTab === 0 && <em>There have been no nominations this month.</em>}
						{currentTab === 1 && <em>There were no nominations last month.</em>}
						{currentTab === 2 && <em>There have been no nominations.</em>}
					</div>
				)}
				{tabNominations.map((nom) => (
					<NomCard
						key={nom.id}
						person={nom.user}
						values={nom.values}
						usersById={usersById}
						full
						onRemove={async () => {
							if (confirm('Are you sure you want to remove this nomination?')) {
								await removeNominationMutation({
									variables: {
										_eq: nom.id
									}
								})
							}
						}}
					/>
				))}
			</div>
		)
	})

	const defaultNominatingName = '...'
	const [nominatingName, setNominatingName] = useState(defaultNominatingName)

	const [nominationModal, setNominationModal, nominationModalOpen] = useModal({
		tall: true,
		header: `Cheers to ${nominatingName}`,
		children: (
			<Formik
				initialValues={{
					userId: { value: -1 },
					project: '',
					category: { value: '' },
					description: ''
				}}
				validate={(values) => {
					const errors: any = {}

					if (!values.userId || values.userId?.value === -1) {
						errors.userId = 'Choose or type in the name of a person'
					}
					if (values.category.value === '') {
						errors.category = 'Choose a category'
					}

					return errors
				}}
				onSubmit={async (values) => {
					const toUserId = +values.userId.value > 0 ? +values.userId.value : null
					let toUserName = ''
					if (!toUserId) {
						toUserName = values.userId.value.toString() || ''
					}
					await addNominationMutation({
						variables: {
							category: values.category.value,
							description: values.description,
							from_user_id: user.id,
							project: values.project,
							to_name: toUserName,
							to_user_id: toUserId
						}
					})
					const userId = +values.userId?.value
					const { email, supervisorId, firstName, lastName } = usersById[userId] ?? {}
					let mgrEmail: string | null = null

					if (supervisorId) {
						const supervisorEmail = usersById[supervisorId]?.email
						if (supervisorEmail) {
							mgrEmail = supervisorEmail
						}
					}
					if (userId > 0 && email) {
						const emailVariables = {
							to: email,
							mgr_to: mgrEmail,
							category: values.category.value,
							html: values.description,
							name: `${user.firstName || ''} ${user.lastName || ''}`,
							to_name: `${firstName || ''} ${lastName || ''}`
						}
						if (location.host.endsWith('1234')) {
							emailVariables.to = `test+${emailVariables.to.split('@')[0]}@mweber.xyz`

							if (emailVariables.mgr_to) {
								emailVariables.mgr_to = `test+${emailVariables.mgr_to.split('@')[0]}@mweber.xyz`
							}
						}
						await sendNominationEmail({
							variables: emailVariables
						})
					}
					setNominationModal(false)
				}}
			>
				{({ values, setFieldValue, errors, touched }) => {
					return (
						<Form>
							<FormField
								name="userId"
								type="select2-create"
								label="Who are you nominating?"
								options={userOptions}
								onChange={(value) => {
									const name = value?.label ? `${value?.label}!` : defaultNominatingName
									if (name !== nominatingName) {
										setNominatingName(name)
									}
								}}
								formatCreateLabel={(name) => (
									<>
										<em>Add</em> {name}
									</>
								)}
								placeholder="If not listed, type a full name and click Add"
							/>
							{touched.userId && errors.userId && (
								<em style={{ fontSize: '80%', color: 'red' }}>{errors.userId}</em>
							)}
							<FormField
								name="category"
								type="select2"
								label="Which category are you nominating for?"
								options={categoryOptions}
								formatOptionLabel={(data, { context }) => {
									return (
										<div>
											<div>{data.label}</div>
											{context === 'menu' && data.explainer && (
												<div
													style={{
														fontSize: '75%',
														color: values.category.value === data.value ? 'white' : '#777'
													}}
												>
													{data.explainer}
												</div>
											)}
										</div>
									)
								}}
							/>
							{touched.category && errors.category && (
								<em style={{ fontSize: '80%', color: 'red' }}>{errors.category}</em>
							)}

							<FormField
								name="description"
								type="richText"
								label="Describe what inspired your nomination"
								value={values.description}
								placeholder=""
								onChange={(val) => setFieldValue('description', val)}
							/>

							<FormField type="formButtons" onCancel={() => setNominationModal(false)} hideBorderTop />
						</Form>
					)
				}}
			</Formik>
		)
	})

	useEffect(() => {
		if (!nominationModalOpen && nominatingName !== defaultNominatingName) {
			setNominatingName(defaultNominatingName)
		}
	}, [nominatingName, nominationModalOpen])

	return (
		<>
			{nominationModal}
			{allNomsModal}
			<Container>
				<SideBySideNoBorder>
					<div className="left">
						{(bulletins || []).map((b) => (
							<ImageCard key={`home-news-card-${b.id}`} data={b} $noBorder $wide $botPadding />
						))}
					</div>
					{nominationsLoaded && (
						<div className="right">
							<Header>
								<h2>Cheers!</h2>
							</Header>
							<p>
								Give a shout out to those who go the extra mile and celebrate what is great about Nibbi!
							</p>
							<p style={{ paddingTop: '1rem', textAlign: 'center' }}>
								<Button $autoWidth mode="secondary" onClick={() => setNominationModal(true)}>
									Nominate Someone!
								</Button>
							</p>

							{nominations.length > 0 && (
								<>
									<Header style={{ paddingBottom: '1rem', paddingTop: '1rem' }}>
										<h3>Recent Nominations</h3>
									</Header>
									{nominations.slice(0, 3).map((nom) => (
										<NomCard
											key={nom.id}
											person={nom.user}
											values={nom.values}
											usersById={usersById}
											clicked={() => {
												setSingleNom(nom.id)
												setShowAllNominations(true)
											}}
										/>
									))}
									<Button
										style={{ marginBottom: '2rem' }}
										$autoWidth
										mode="outline"
										onClick={() => setShowAllNominations(true)}
									>
										View All Nominations
									</Button>
								</>
							)}
						</div>
					)}
				</SideBySideNoBorder>

				<SideBySide>
					<LinkCardList title="Latest Discussions" data={discussionsData?.discussions || []} />
					<LinkCardList title="Latest Resources" data={resourcesData?.resources || []} />
				</SideBySide>

				<SideBySide>
					<LinkCardList
						title="Favorite Discussions"
						data={favoriteDiscussions}
						emptyText="You do not have any favorite discussions yet."
					/>
					<LinkCardList
						title="Favorite Resources"
						data={favoriteResources}
						emptyText="You do not have any favorite resources yet."
					/>
				</SideBySide>
			</Container>
			<HeroQuoteBackground>
				<QuoteContainer>
					<HeroQuotePrimary>
						<h4>The Mission Statement</h4>
						<p>
							Serving our clients and community by building safely with integrity, innovation and quality.
						</p>
					</HeroQuotePrimary>
					<HeroQuoteSecondary>
						<h4>The Vision Statement</h4>
						<p>
							Be the well-respected builder and employer of choice, focused on safety, quality and
							profitability.
						</p>
					</HeroQuoteSecondary>
				</QuoteContainer>
			</HeroQuoteBackground>
			<Container>
				<CoreValuesContainer>
					<h3>Our Core Values</h3>
					<CoreValues>
						<CoreValue>
							<h4>Safety</h4>
							<p>
								We are firmly committed to maintaining the safest possible jobsite for our employees,
								subcontractors, clients and the community. We constantly work to improve awareness and
								accident prevention through continual staff training and highly detailed pre-task
								planning.
							</p>
						</CoreValue>
						<CoreValue>
							<h4>Honesty & Integrity</h4>
							<p>
								Nibbi operates with the highest professional and ethical standards. We stand behind our
								work and do what is best for each project, allowing our corporate culture and values to
								guide our conduct.
							</p>
						</CoreValue>
						<CoreValue>
							<h4>Client Focus</h4>
							<p>
								Our highest priority is helping our clients find solutions to achieve their objectives.
								We continuously strive to better serve our clients through collaboration, innovation and
								efficiency.
							</p>
						</CoreValue>
						<CoreValue>
							<h4>Social Responsibility</h4>
							<p>
								We genuinely care for the communities in which we live and work. We give our time,
								talent and resources to local communities and organizations in need. We practice
								sustainable and green construction processes and are environmentally conscious in our
								everyday work lives.
							</p>
						</CoreValue>
						<CoreValue>
							<h4>Financial Stability</h4>
							<p>
								Nibbi believes great companies are built on sound financial practices and a history of
								solid financial results. We are committed to effectively managing our financial
								resources and earning a consistent profit to support our clients’ needs and achieve our
								long-term goals.
							</p>
						</CoreValue>
						<CoreValue>
							<h4>Opportunity</h4>
							<p>
								We provide dynamic career opportunities and mentoring for our employees. We encourage
								leadership training and continuing education at all levels. We continuously strive to
								recruit and develop the best talent.
							</p>
						</CoreValue>
						<CoreValue>
							<h4>Family Culture</h4>
							<p>
								We are proud of our emphasis on fostering a strong sense of family through caring,
								mutual respect and promoting a strong work / life balance.
							</p>
						</CoreValue>
					</CoreValues>
				</CoreValuesContainer>
			</Container>
		</>
	)
}

export default HomeRoute
