import styled from 'styled-components'

import RichText from 'src/components/RichText'
import { NavLink } from 'react-router-dom'

export const ImageCardContainer = styled.div<{ $noBorder?: boolean; $stacked?: boolean }>`
	width: ${(props) => (props.$stacked ? '31%' : '100%')};
	padding-left: 3%;
	display: ${(props) => (props.$stacked ? 'inline-grid' : 'flex')};
	border-bottom: ${(props) => !props.$stacked && !props.$noBorder && `1px solid ${props.theme.colors.lightGray}`};
	&:first-child {
		padding-left: ${(props) => (props.$stacked ? 0 : undefined)};
	}
`

export const Image = styled.img<{ $stacked?: boolean; $wide: boolean }>`
	width: ${(props) => props.$stacked && '100%'};
	height: ${(props) => !props.$stacked && `${props.$wide ? '150' : '100'}px`};
	margin-top: ${(props) => !props.$stacked && '20px'};
	margin-right: ${(props) => !props.$stacked && '15px'};
	border-radius: 3px;
	&:hover {
		cursor: pointer;
	}
`

export const ItemSubtitle = styled.div<{ $stacked?: boolean }>`
	font-size: 14px;
	color: ${({ theme }) => theme.colors.lightestGray};
	margin-bottom: ${(props) => (props.$stacked ? '15px' : '5px')};
`

export const Link = styled(NavLink)<{ $stacked?: boolean }>`
	display: block;
	margin-top: ${(props) => (props.$stacked ? '10px' : '5px')};
	margin-bottom: 20px;
	color: ${({ theme }) => theme.colors.primary};
	text-decoration: none;
	&:hover {
		cursor: pointer;
	}
`

export const TitleLink = styled(NavLink)<{ $stacked?: boolean }>`
	font-size: 24px;
	line-height: 30px;
	transition: all 0.1s ease-in-out;
	text-decoration: none;
	color: ${({ theme }) => theme.colors.primaryTextColor};
	margin: 15px 0px 10px 0px;
	font-weight: 300;
	display: block;
	&:hover {
		color: ${({ theme }) => theme.colors.primary};
		cursor: pointer;
	}
`

export const ContentPreview = styled(RichText)`
	p {
		line-height: 25px;
		font-size: 15px;
		color: ${({ theme }) => theme.colors.primaryTextColor};
	}
`
