import { ResourceDetailsFragment, useLatestOrgChartQuery } from 'shared/schema'
import Button from 'src/components/Button'
import { FileEarmarkPdf } from 'src/icons'
import { Box, Stack } from '@mui/material'
import theme from 'shared/theme'

export const OrgChartPDFButton = () => {
	const { loading, data } = useLatestOrgChartQuery()
	let resource: ResourceDetailsFragment
	if (!loading && data?.resources?.length) {
		resource = data.resources[0]
	}
	return (
		<Button
			loading={loading}
			disabled={loading}
			mode="outline"
			onClick={() => {
				// this should be an anchor tag, not a button
				// but i don't think we built that
				// ghetto yolo
				if (resource) {
					window.open(resource.file.url, '_blank')
				}
			}}
		>
			<Stack direction="row" alignItems="center" spacing={1}>
				<FileEarmarkPdf height={24} color={theme.colors.primary} />
				<Box>View as PDF</Box>
			</Stack>
		</Button>
	)
}
