import React from 'react'
import { useDepartment } from 'src/routes/department'
import { compact } from 'lodash'
import { DepartmentDetailsQuery } from 'shared/schema'
import PageHeader from 'src/components/PageHeader'

type ParentTypes =
	| DepartmentDetailsQuery['department']['contents'][number]
	| { __typename: 'static'; to: string; label: string }

function ContentHeader({
	children,
	parent,
	showBreadcrumbs = true,
	primaryColor
}: {
	children: string
	parent?: ParentTypes
	showBreadcrumbs?: boolean
	primaryColor?: boolean
}) {
	primaryColor ??= true
	const department = useDepartment()
	const parents = compact([
		showBreadcrumbs && { to: `/departments/${department.id}/overview`, title: department.title },
		parent?.__typename === 'contents' && {
			to: `/departments/${department.id}/contents/${parent.id}`,
			title: parent.title
		},
		parent?.__typename === 'static' && { to: parent.to, title: parent.label }
	])

	return (
		<PageHeader primaryColor={primaryColor} parents={parents}>
			{children}
		</PageHeader>
	)
}

export default ContentHeader
