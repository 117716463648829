import styled, { css } from 'styled-components'
import { X, Envelope, Pencil, Trash, CloudDownload } from 'src/icons'
import { buildFileIcon } from 'src/components/Resource/icon'

export const CloseIcon = styled(X)<{ color?: string; size?: string }>`
	width: ${(props) => (props.size === 'small' ? '30px' : '45px')};
	height: ${(props) => (props.size === 'small' ? '30px' : '45px')};
	color: ${(props) => props.theme.colors[props.color]};
	top: 0;
	right: 0;
	position: absolute;
	float: right;
	padding-top: 15px;
	padding-right: 15px;
	&:hover {
		cursor: pointer;
	}
`

export const ResourceTitle = styled.h1`
	color: white;
	font-size: 36px;
	font-weight: 300;
	margin-bottom: 5px;
`

export const ResourceSubtitle = styled.h2`
	color: white;
	font-size: 20px;
	font-weight: 300;
	opacity: 85%;
`

export const ContentContainer = styled.div`
	display: flex;
	justify-content: space-around;
	padding-top: 50px;
`

export const PDFContainer = styled.div`
	background-color: white;
	width: 60%;
	height: calc(100vh - 100px);
	iframe {
		width: 100%;
		height: 100%;
	}
`

export const ResourceInfoContainer = styled.div`
	width: 30%;
`

const makeIcon = (sourceIcon) => {
	return styled(sourceIcon)`
		color: ${({ theme }) => theme.colors.neonGreen};
		width: 25px;
		height: 25px;
		background-color: white;
		border: 1px solid ${({ theme }) => theme.colors.neonGreen};
		padding: 8px;
		border-radius: 5px;
		margin-bottom: 10px;
	`
}

export const FileIcon = buildFileIcon(makeIcon)

export const ActionsContainer = styled.div`
	padding-top: 20px;
	color: ${({ theme }) => theme.colors.primary};
`

const IconStyles = css`
	width: 15px;
	height: 15px;
	padding-right: 5px;
`

export const EmailIcon = styled(Envelope)`
	${IconStyles}
`

export const EditIcon = styled(Pencil)`
	${IconStyles}
`

export const TrashIcon = styled(Trash)`
	${IconStyles}
`

export const DownloadIcon = styled(CloudDownload)`
	${IconStyles}
`

export const PDFAction = styled.div`
	padding-bottom: 5px;
	a {
		text-decoration: none;
	}
	&:hover {
		cursor: pointer;
	}
`

export const EmailResourceContainer = styled.div`
	position: fixed;
	overflow: auto;
	top: 20vh;
	right: 0;
	bottom: 0;
	left: 50vh;
	background-color: ${({ theme }) => theme.colors.white};
	width: 400px;
	height: 200px;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
	border-radius: 3px;
	border: 1px solid ${({ theme }) => theme.colors.borderGray};
	padding: 40px;
	color: ${({ theme }) => theme.colors.primaryTextColor};

	input {
		margin-top: 20px;
		width: calc(100% - 40px);
	}
`

export const Divider = styled.div`
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid #535353;
`

export const Button = styled.button<{ fullWidth?: boolean }>`
	color: ${({ theme }) => theme.colors.white};
	background-color: ${({ theme }) => theme.colors.primary};
	padding: 10px 15px;
	border-radius: 3px;
	border: none;
	margin: 10px auto;
	display: block;
	width: ${(props) => props?.fullWidth && '100%'};
	transition: all 0.1s ease-in-out;
	&:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.colors.primaryDark};
	}
`
