import styled from 'styled-components'
import Button from 'src/components/Button'

export const Background = styled.div`
	background-size: cover;
	background-position: center;
	height: 100vh;
`

export const SignInContainer = styled.div`
	background: white;
	max-width: 375px;
	margin: 0 auto;
	padding: 30px;
	box-shadow: 0px 5px 5px rgb(0 0 0 / 10%);
	border-radius: 3px;
`

export const CenterVertically = styled.div`
	width: 50%;
	height: 50%;
	overflow: auto;
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
`

export const FormFieldWrapper = styled.div`
	width: 90%;
	padding-top: 20px;
	&:last-of-type {
		padding-bottom: 30px;
	}
`

export const SignInLogo = styled.img`
	display: block;
	margin: 0 auto;
	padding-top: 15px;
	padding-bottom: 25px;
`

export const SigninButton = styled(Button)`
	width: 100%;
`

export const Errors = styled.div`
	color: ${(props) => props.theme.colors.red};
`
