import React from 'react'
import {
	TopHeader,
	HeaderContainer,
	UtilitiesContainer,
	UtilityItemLink,
	UserContainer,
	UtilityDropdown,
	UtilityItemExternalLink,
	NavIcon
} from '../styled'
import WeatherDropdownContent from './WeatherDropdownContent'
import UserDropdownContent from './UserDropdownContent'
import { Clock, CalendarAlt, User } from 'src/icons'
import { Sun } from 'src/icons'

import useCurrentDate from 'src/components/useCurrentDate'
import { useWeather } from 'src/components/Weather'
import { useAuthGate } from 'src/components/AuthGate'

function UtilityHeader() {
	const weatherState = useWeather()
	const { dateStamp, timeStamp } = useCurrentDate()
	const { user } = useAuthGate()

	return (
		<TopHeader>
			<HeaderContainer>
				<UtilitiesContainer>
					<UtilityItemLink to="#">
						<NavIcon as={Clock} />
						{timeStamp}
					</UtilityItemLink>
					<UtilityItemLink to="/calendar">
						<NavIcon as={CalendarAlt} />
						{dateStamp}
					</UtilityItemLink>
					{weatherState.value && (
						<UtilityDropdown
							href="https://weather.com/weather/today/l/USCA0987:1:US"
							as={UtilityItemExternalLink}
						>
							<NavIcon as={Sun} />
							{weatherState.value.temp} - {weatherState.value.description}
							<WeatherDropdownContent />
						</UtilityDropdown>
					)}
				</UtilitiesContainer>
				<UserContainer>
					<UtilityDropdown>
						<NavIcon as={User} />
						Logged in as {user && user.firstName} {user && user.lastName}
						<UserDropdownContent />
					</UtilityDropdown>
				</UserContainer>
			</HeaderContainer>
		</TopHeader>
	)
}

export default UtilityHeader
