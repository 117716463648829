import React from 'react'
import styled from 'styled-components'
import Spinner from 'src/components/Spinner'

export interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	loading?: boolean
	mode?: 'primary' | 'secondary' | 'tertiary' | 'outline'
	$autoWidth?: boolean
}

const BaseButton = styled.button`
	font-size: 20px;
	width: 100%;
	margin: 0;
	padding: 8px 0;
	border: none;
	color: #fff;
	cursor: pointer;
	&[disabled] {
		cursor: not-allowed;
		opacity: 0.5;
	}
`

const PrimaryButton = styled(BaseButton)<{ $autoWidth?: boolean }>`
	background-color: ${(props) => props.theme.colors.primary};
	width: ${(props) => (props.$autoWidth ? 'auto' : '200px')};
	&:hover {
		background-color: ${(props) => props.theme.colors.primaryDark};
	}
`

const SecondaryButton = styled(BaseButton)<{ $autoWidth?: boolean }>`
	background-color: ${(props) => props.theme.colors.primary};
	font-size: 1rem;
	border-radius: 3px;
	padding: 10px 30px;
	width: ${(props) => (props.$autoWidth ? 'auto' : '200px')};
	&:hover {
		background-color: ${(props) => props.theme.colors.primaryDark};
	}
`

const TertiaryButton = styled(BaseButton)<{ $autoWidth?: boolean }>`
	font-size: 1rem;
	border-radius: 3px;
	padding: 10px 30px;
	width: ${(props) => (props.$autoWidth ? 'auto' : '100px')};
	color: ${(props) => props.theme.colors.primary};
	background: none;
`

const OutlineButton = styled(BaseButton)<{ $autoWidth?: boolean }>`
	background-color: white;
	color: #8e8e8e;
	font-size: 1rem;
	border-radius: 3px;
	padding: 10px 30px;
	width: ${(props) => (props.$autoWidth ? 'auto' : '200px')};
	border: 1px solid #e7e7e7;
	&:hover {
		background-color: ${(props) => props.theme.colors.lightGray};
	}
`

const ButtonModeMap = {
	primary: PrimaryButton,
	secondary: SecondaryButton,
	tertiary: TertiaryButton,
	outline: OutlineButton
}

function Button({ loading, children, mode = 'primary', ...props }: IButton) {
	const DynamicButton = ButtonModeMap[mode]
	return <DynamicButton {...props}>{loading ? <Spinner size="xsm" type="secondary" /> : children}</DynamicButton>
}

export default Button
