import React from 'react'
import { Label } from './styled'

interface IFormLabel extends React.LabelHTMLAttributes<HTMLLabelElement> {
	children: string | React.ReactNode
	className?: string
}

function FormLabel({ children, className, ...props }: IFormLabel) {
	return (
		<Label className={className} {...props}>
			{children}
		</Label>
	)
}

export default FormLabel
