import React from 'react'
import LinkCard from '../LinkCard'
import { ILinkCardProps } from '../LinkCard/types'
import { Section, Header, SeeAllLink } from './styled'

export default function LinkCardList(props: {
	title?: string
	headerLink?: {
		title: string
		to: string
	}
	data: ILinkCardProps['data'][]
	emptyText?: string
	departmentId?: number
	showSubtitleActions?: boolean
}): any {
	if (props.data.length == 0 && !props.emptyText) {
		return null
	}
	return (
		<Section>
			<Header>
				{props.title && <h2>{props.title}</h2>}
				{props.headerLink && <SeeAllLink to={props.headerLink.to}>{props.headerLink.title}</SeeAllLink>}
			</Header>
			{props.data.map((linkCardData) => {
				return (
					<LinkCard
						key={`${linkCardData.__typename}:${linkCardData.id}`}
						data={linkCardData}
						departmentId={props.departmentId}
						showSubtitleActions={props.showSubtitleActions}
					/>
				)
			})}
			{props.data.length === 0 && <p>{props.emptyText}</p>}
		</Section>
	)
}
