import React, { useState, useMemo } from 'react'
import { DndContext, closestCenter, MouseSensor, DragOverlay, useSensor, useSensors } from '@dnd-kit/core'
import { useSortable, arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import _ from 'lodash'

import { useUserRoles } from 'src/components/Roles'

const SortableGridItem = ({ GridItemComponent, ...props }: { data: any; GridItemComponent: any; onClick?: any }) => {
	const { isGalleryAdmin } = useUserRoles()
	const sortable = useSortable({ id: props.data.id, disabled: !isGalleryAdmin })
	const { attributes, listeners, setNodeRef, transform, transition } = sortable

	const style = {
		transform: CSS.Transform.toString(transform),
		transition
	}

	return <GridItemComponent ref={setNodeRef} style={style} {...props} {...attributes} {...listeners} />
}

export const SortableGrid = ({
	GridItemComponent,
	reorderHook,
	reorderHookConfig,
	reorderMutationVariables,
	initialItems,
	gridColumns,
	onGridItemClick
}: {
	GridItemComponent: any
	reorderHook: any
	reorderHookConfig: any
	reorderMutationVariables?: any
	initialItems: any
	gridColumns: number
	onGridItemClick?: any
}) => {
	const { isGalleryAdmin: canEdit } = useUserRoles()

	const [items, setItems] = useState([])
	const [activeId, setActiveId] = useState(null)
	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				distance: 10
			}
		})
	)
	const [reorderMutation] = reorderHook(reorderHookConfig)
	useMemo(() => {
		if (!canEdit) {
			return
		}
		if (!(initialItems?.length > 0) || !(items?.length > 0)) {
			return
		}
		if (!_.isEqual(_.map(initialItems, 'id'), _.map(items, 'id'))) {
			reorderMutation({
				variables: {
					children: _.map(items, 'id'),
					...reorderMutationVariables
				}
			})
		}
	}, [items])

	if (!initialItems) {
		return null
	}
	if ((initialItems?.length && !items.length) || items.length > initialItems.length) {
		setItems(initialItems)
		return null
	}
	if (!items.length) {
		return null
	}

	function handleDragStart(event) {
		setActiveId(event.active.id)
	}

	function handleDragEnd(event) {
		const { active, over } = event

		if (active.id !== over.id) {
			setItems((items) => {
				const itemIds = items.map((g) => g.id)
				const oldIndex = itemIds.indexOf(active.id)
				const newIndex = itemIds.indexOf(over.id)

				return arrayMove(items, oldIndex, newIndex)
			})
		}

		setActiveId(null)
	}

	function handleDragCancel() {
		setActiveId(null)
	}

	let activeFullGalleryCard = null
	if (activeId) {
		const activeIndex = items.findIndex((g) => g.id == activeId)
		const activeItem = items[activeIndex]
		activeFullGalleryCard = <GridItemComponent data={activeItem} />
	}

	return (
		items.length > 0 && (
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragStart={handleDragStart}
				onDragEnd={handleDragEnd}
				onDragCancel={handleDragCancel}
			>
				<SortableContext items={items} strategy={rectSortingStrategy}>
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
							gridGap: 10,
							padding: 10
						}}
					>
						{items.map((i, index) => (
							<SortableGridItem
								GridItemComponent={GridItemComponent}
								key={i.id}
								data={i}
								onClick={() => {
									if (onGridItemClick) {
										onGridItemClick(index)
									}
								}}
							/>
						))}
					</div>
				</SortableContext>

				<DragOverlay adjustScale={true}>{activeFullGalleryCard}</DragOverlay>
			</DndContext>
		)
	)
}
