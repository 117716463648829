import React, { useState } from 'react'
import {
	DepartmentDetailsFragment,
	useAdminAddContentMutation,
	useAdminUpdateContentMutation,
	useDeleteContentResourceMutation,
	useAddResourceMutation,
	useAddContentsResourceMutation
} from 'shared/schema'
import { Formik, Form } from 'formik'
import _ from 'lodash'

import { useDepartment } from 'src/routes/department'
import ContentHeader from '../ContentHeader'
import FormField from 'src/components/FormField'
import { useNavigate } from 'react-router'
import { useUserRoles } from 'src/components/Roles'
import { useAddResources } from '../../AddResources'

function ContentsPublish({
	existing,
	cancelEditing
}: {
	cancelEditing?: any
	existing?: {
		id: number
		body: string
		title: string
		parentId: number
		contents?: { id: number }[]
		contentResources?: DepartmentDetailsFragment['contents'][number]['contentResources']
	}
}) {
	const navigate = useNavigate()
	const department = useDepartment()
	const [addContent] = useAdminAddContentMutation({
		awaitRefetchQueries: true
	})
	const [updateContent] = useAdminUpdateContentMutation({
		refetchQueries: ['departmentDetails'],
		awaitRefetchQueries: true
	})
	const [convertedText, setConvertedText] = useState(existing?.body || '')
	const [deleteContentResource] = useDeleteContentResourceMutation()
	const [addResource] = useAddResourceMutation()
	const [addContentResource] = useAddContentsResourceMutation()

	const [addResourcesFormFields, addingResourceModal, resourceActions] = useAddResources(
		_.map(existing?.contentResources ?? [], 'resource')
	)

	const { useRole } = useUserRoles()

	const onSubmit = async (values) => {
		const context = {
			useRole
		}
		const contentVariables = {
			body: convertedText,
			departmentId: department.id,
			parentId: +values.parentId || null,
			title: values.title,
			active: true
		}
		let contentId = existing?.id
		if (!contentId) {
			const newContent = await addContent({
				context,
				variables: contentVariables
			})
			contentId = newContent.data.insertContent.id
		}
		const resourceWork = _.map(resourceActions, async (resourceAction) => {
			if (resourceAction.action === 'remove') {
				return deleteContentResource({
					context,
					variables: {
						contentId: contentId,
						resourceId: resourceAction.data.resourceId
					}
				})
			} else if (resourceAction.action === 'new') {
				const newResource = await addResource({
					context,
					variables: {
						title: resourceAction.data.title,
						fileUploadId: resourceAction.data.uploadId,
						description: resourceAction.data.description
					}
				})
				return addContentResource({
					context,
					variables: {
						departmentContentId: contentId,
						resourceId: newResource.data.insertResource.id
					}
				})
			} else if (resourceAction.action === 'add') {
				return addContentResource({
					context,
					variables: {
						departmentContentId: contentId,
						resourceId: resourceAction.resource.id
					}
				})
			}
		})
		await Promise.all(resourceWork)

		const variables = {
			body: convertedText,
			departmentId: department.id,
			parentId: +values.parentId || null,
			title: values.title,
			active: true
		}
		const contentUpdate = await updateContent({
			context,
			variables: { ...variables, id: contentId }
		})

		const result = contentUpdate.data.updateContentById

		if (existing && existing.parentId == result.parentId && existing.id == result.id) {
			cancelEditing()
		} else {
			const to = result.parentId
				? `/departments/${department.id}/contents/${result.parentId}/${result.id}`
				: `/departments/${department.id}/contents/${result.id}`
			navigate(to)
		}
	}

	const baseParentOptions = [{ value: '-', label: 'Top Level (none)' }]
	const departmentParentOptions = department.contents
		// Prevent content with children from being a child
		.filter(() => !existing?.contents?.length)
		// Prevent content from being a child of itself
		.filter((c) => !existing?.id || existing.id != c.id)
		.map((c) => ({ value: c.id.toString(), label: c.title }))

	return (
		<>
			<Formik
				initialValues={{
					title: existing?.title || '',
					parentId: existing?.parentId || '-'
				}}
				onSubmit={onSubmit}
			>
				<Form>
					<FormField type="text" name="title" placeholder="Title" label="Page Title" />
					{/* todo: broken */}
					<FormField
						type="select"
						name="parentId"
						label="Parent"
						options={[...baseParentOptions, ...departmentParentOptions]}
					/>
					<FormField
						name="body"
						type="richText"
						label="Body"
						value={convertedText}
						onChange={setConvertedText}
					/>
					{addResourcesFormFields}
					<FormField
						type="formButtons"
						submitLabel={existing ? 'Update' : 'Create'}
						onCancel={() => cancelEditing()}
					/>
				</Form>
			</Formik>
			{addingResourceModal}
		</>
	)
}

export function AddContents() {
	return (
		<div>
			<ContentHeader>Publish Department Content</ContentHeader>
			<ContentsPublish />
		</div>
	)
}

export default ContentsPublish
