import React from 'react'
import { Routes, Route, useParams } from 'react-router-dom'
import Container from 'src/components/Container'
import LayoutWithSidebar from 'src/components/LayoutWithSidebar'
import Sidebar from 'src/components/Sidebar'
import NewsDetail from 'src/components/NewsDetail'
import { useSiteContext } from 'src/components/AppWrapper'
import ImageCard from 'src/components/ImageCard'
import PageHeader from 'src/components/PageHeader'
import NewsPublish from 'src/routes/news/publish'
import { useBulletinsQuery, BulletinDetailsFragment, useSingleBulletinQuery } from 'shared/schema'
import { DepartmentLinks, DepartmentLink } from './styled'
import { StateSpinner } from 'src/components/Spinner'

const sidebarLinks = [{ title: 'Create New Post', to: '/news/publish', adminOnly: true }]

function NewsListRoute(props: { bulletins?: BulletinDetailsFragment[] }) {
	return (
		<div>
			{props?.bulletins?.map((b) => (
				<ImageCard key={b.id} data={b} />
			))}
		</div>
	)
}

function NewsDetailRoute(props: { bulletins?: BulletinDetailsFragment[] }) {
	const { bulletinId } = useParams()

	const fromProps = props.bulletins?.find((b) => b.id === +bulletinId)

	const { data: lookupData } = useSingleBulletinQuery({
		variables: { id: +bulletinId },
		skip: !props.bulletins || !!fromProps
	})

	const bulletin = fromProps || (lookupData?.bulletins ?? []).find((b) => b.id === +bulletinId)

	if (!bulletin) {
		return null
	}

	return (
		<div>
			<PageHeader parents={[{ to: '/news', title: 'News & Announcements' }]} primaryColor={false}>
				{bulletin.title}
			</PageHeader>
			<NewsDetail bulletin={bulletin} commentsRefetch="bulletins" />
		</div>
	)
}

function NewsAndAnnouncements() {
	const { siteMeta } = useSiteContext()
	const departments = siteMeta.departments
	const { data, loading } = useBulletinsQuery()

	return (
		<Container>
			<LayoutWithSidebar>
				<Sidebar links={sidebarLinks}>
					<DepartmentLinks>
						<h3>Jump to Department Board</h3>
						{departments.map((d) => (
							<DepartmentLink key={d.id} to={`/departments/${d.id}/news`}>
								{d.title}
							</DepartmentLink>
						))}
					</DepartmentLinks>
				</Sidebar>
				<Routes>
					<Route
						path="/"
						element={loading ? <StateSpinner /> : <NewsListRoute bulletins={data?.bulletins} />}
					/>
					<Route path="/publish" element={<NewsPublish />} />
					<Route path="/:bulletinId/*" element={<NewsDetailRoute bulletins={data?.bulletins} />} />
				</Routes>
			</LayoutWithSidebar>
		</Container>
	)
}

export default NewsAndAnnouncements
