import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const Breadcrumbs = styled.h1`
	font-weight: lighter;
	line-height: 40px;
	font-size: 20px;
	padding-bottom: 20px;
	a {
		text-decoration: none;
		color: ${({ theme }) => theme.colors.primary};
	}
`

const Heading = styled.h1<{ primaryColor?: boolean }>`
	font-weight: lighter;
	line-height: 40px;
	color: ${(props) => (props.primaryColor ? props.theme.colors.primary : props.theme.colors.primaryTextColor)};
	font-size: 40px;
	padding-bottom: 20px;
`

function PageHeader({
	children,
	parents,
	primaryColor = true
}: {
	children: string
	parents?: { to: string; title: string }[]
	primaryColor?: boolean
}) {
	return (
		<>
			{parents?.length > 0 && (
				<Breadcrumbs>
					{parents.map((p, index) => (
						<React.Fragment key={index}>
							<NavLink to={p.to}>{p.title}</NavLink>
							{' / '}
						</React.Fragment>
					))}
				</Breadcrumbs>
			)}
			<Heading primaryColor={primaryColor}>{children}</Heading>
		</>
	)
}

export default PageHeader
