import React from 'react'
import styled, { css } from 'styled-components'
import { Star, StarFill, CircleFill } from 'src/icons'
import {
	useAddFavoriteDiscussionMutation,
	useRemoveFavoriteDiscussionMutation,
	useAddFavoriteResourceMutation,
	useRemoveFavoriteResourceMutation
} from 'shared/schema'
import { useAuthGate } from '../AuthGate'

const IconStyles = css`
	width: 15px;
	height: 15px;
	padding-right: 5px;
	margin-bottom: 3px;
`
const FavoriteContainer = styled.span<{ $favorited?: boolean }>`
	color: ${(props) => (props.$favorited ? props.theme.colors.primary : '')};
	&:hover {
		cursor: pointer;
	}
`
const FavoritedIcon = styled(StarFill)`
	${IconStyles}
	color: ${({ theme }) => theme.colors.primary};
`
const FavoriteIcon = styled(Star)`
	${IconStyles}
`
const LoadingCircle = styled(CircleFill)`
	height: 0.5rem;
	color: ${(props) => props.theme.colors.primary};
	animation: fader 1s infinite;
	@keyframes fader {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`

export default function (props: { data: { __typename?: 'discussions' | 'resources'; id: number } }) {
	const { user } = useAuthGate()

	const [addFavoriteDiscussion, { loading: addFavoriteDiscussionLoading }] = useAddFavoriteDiscussionMutation({
		refetchQueries: ['currentUser'],
		awaitRefetchQueries: true
	})
	const [
		removeFavoriteDiscussion,
		{ loading: removeFavoriteDiscussionLoading }
	] = useRemoveFavoriteDiscussionMutation({
		refetchQueries: ['currentUser'],
		awaitRefetchQueries: true
	})

	const [addFavoriteResource, { loading: addFavoriteResourceLoading }] = useAddFavoriteResourceMutation({
		refetchQueries: ['currentUser'],
		awaitRefetchQueries: true
	})
	const [removeFavoriteResource, { loading: removeFavoriteResourceLoading }] = useRemoveFavoriteResourceMutation({
		refetchQueries: ['currentUser'],
		awaitRefetchQueries: true
	})
	const loading =
		addFavoriteDiscussionLoading ||
		removeFavoriteDiscussionLoading ||
		addFavoriteResourceLoading ||
		removeFavoriteResourceLoading

	const typeLookup = {
		discussions: {
			isFavoritedLookup: () => user.favoriteDiscussions.find((fav) => fav.discussionId === props.data.id),
			addFavorite: () =>
				addFavoriteDiscussion({
					variables: {
						discussionId: props.data.id
					}
				}),
			removeFavorite: () =>
				removeFavoriteDiscussion({
					variables: {
						discussionId: props.data.id
					}
				})
		},
		resources: {
			isFavoritedLookup: () => user.favoriteResources.find((fav) => fav.resourceId === props.data.id),
			addFavorite: () =>
				addFavoriteResource({
					variables: {
						resourceId: props.data.id
					}
				}),
			removeFavorite: () =>
				removeFavoriteResource({
					variables: {
						resourceId: props.data.id
					}
				})
		}
	}
	if (!props.data.__typename) {
		return null
	}

	const isFavorited = typeLookup[props.data.__typename].isFavoritedLookup()

	const toggle = () => {
		if (loading) {
			return
		}
		if (isFavorited) {
			typeLookup[props.data.__typename].removeFavorite()
		} else {
			typeLookup[props.data.__typename].addFavorite()
		}
	}

	return (
		<span onClick={toggle}>
			{isFavorited ? (
				<FavoriteContainer $favorited>
					<FavoritedIcon />
					Favorited
				</FavoriteContainer>
			) : (
				<FavoriteContainer>
					<FavoriteIcon />
					Favorite
				</FavoriteContainer>
			)}{' '}
			{loading && <LoadingCircle />}
		</span>
	)
}
