import React from 'react'
import Spinner from 'src/components/Spinner'
import styles from './styles.module.scss'

const AppLoading = () => (
	<div className={styles.appLoading}>
		<Spinner />
	</div>
)

export default AppLoading
