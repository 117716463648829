import React, { useState } from 'react'
import { Tabs } from 'react-tabs'

import Spinner from 'src/components/Spinner'
import LinkCard from 'src/components/LinkCard'
import { useSearch } from '../index'
import { SearchContainer, Tab, TabList, TabContent } from './styled'
import Container from 'src/components/Container'
import UserModal from './UserModal'
import { SearchQuery } from 'shared/schema'
import Select from 'src/components/Select'
import { useNavigate } from 'react-router'

const TABS = {
	ALL_RESULTS: 0,
	RESOURCES: 1,
	ANNOUNCEMENTS: 2,
	PEOPLE: 3,
	DISCUSSIONS: 4
}

const SEARCH_RESULT_TYPE_MAP = {
	resources: 'resource',
	bulletin: 'news',
	users: 'user'
}

const sortOptions = [
	{ label: 'Newest', value: 'dateDesc' },
	{ label: 'Oldest', value: 'dateAsc' },
	{ label: 'Name, A - Z', value: 'nameAsc' },
	{ label: 'Name, Z - A', value: 'nameDesc' }
]

const SearchResults = () => {
	const { results, loading, searchKeywords, setSearchOpen, searchSort, setSearchSort } = useSearch()
	const [currentTab, setCurrentTab] = useState(TABS.ALL_RESULTS)
	const [userModalOpen, setUserModalOpen] = useState(false)
	const [userModalData, setUserModalData] = useState<null | SearchQuery['search'][number]['user']>()
	const navigate = useNavigate()

	const resources = results?.filter((result) => result.modelType === 'resources') ?? []
	const announcements = results?.filter((result) => result.modelType === 'bulletin') ?? []
	const users = results?.filter((result) => result.modelType === 'users') ?? []

	const getOnClick = (resultType: 'resource' | 'news' | 'user', user: SearchQuery['search'][number]['user']) => {
		if (resultType === SEARCH_RESULT_TYPE_MAP.users) {
			return () => {
				setUserModalOpen(true)
				setUserModalData(user)
			}
		} else if (resultType === SEARCH_RESULT_TYPE_MAP.bulletin) {
			return () => {
				setSearchOpen(false)
			}
		}
	}

	const onResourceContextClick = (context) => {
		setSearchOpen(false)
		if (context.parentId) {
			navigate(`/departments/${context.departmentId}/contents/${context.parentId}/${context.id}`)
		} else {
			navigate(`/departments/${context.departmentId}/contents/${context.id}`)
		}
	}

	return (
		<SearchContainer>
			{loading ? (
				<Spinner />
			) : (
				<Container>
					{results?.length === 0 && (
						<h1>
							No results found for <b>{searchKeywords}</b>
						</h1>
					)}
					{results?.length > 0 && (
						<>
							<Tabs
								selectedIndex={currentTab}
								selectedTabClassName="active"
								onSelect={(index) => {
									setCurrentTab(index)
								}}
							>
								<TabList>
									<Tab>All Results</Tab>
									{resources.length > 0 && <Tab>Resources</Tab>}
									{announcements.length > 0 && <Tab>Announcements</Tab>}
									{users.length > 0 && <Tab>Users</Tab>}
									<span style={{ marginLeft: 'auto' }}>
										Sort results by:
										<Select onChange={(e) => setSearchSort(e.target.value)}>
											{sortOptions.map((sortOption) => (
												<option key={`${sortOption.value}`} value={sortOption.value}>
													{sortOption.label}
												</option>
											))}
										</Select>
									</span>
								</TabList>
								<TabContent>
									{results.map((result, index) => (
										<LinkCard
											key={`${SEARCH_RESULT_TYPE_MAP[result.modelType]}-${index}`}
											data={result.resource || result.user || result.bulletin}
											$displaySize="lg"
											modelId={result.modelId}
											showTypeBadge
											departmentId={
												(result.bulletin?.global === false &&
													result.bulletin.bulletinDepartments?.[0]?.departmentId) ||
												undefined
											}
											onClick={getOnClick(SEARCH_RESULT_TYPE_MAP[result.modelType], result.user)}
											onResourceContextClick={onResourceContextClick}
										/>
									))}
								</TabContent>
								{resources.length > 0 && (
									<TabContent>
										{resources.map((result, index) => (
											<LinkCard
												key={`resource-${index}`}
												data={result.resource}
												$displaySize="lg"
												modelId={result.modelId}
												showTypeBadge
												onResourceContextClick={onResourceContextClick}
											/>
										))}
									</TabContent>
								)}
								{announcements.length > 0 && (
									<TabContent>
										{announcements.map((result, index) => (
											<LinkCard
												key={`news-${index}`}
												data={result.bulletin}
												$displaySize="lg"
												modelId={result.modelId}
												showTypeBadge
											/>
										))}
									</TabContent>
								)}
								{users.length > 0 && (
									<TabContent>
										{users.map((result, index) => (
											<LinkCard
												key={`user-${index}`}
												data={result.user}
												$displaySize="lg"
												modelId={result.modelId}
												showTypeBadge
												onClick={getOnClick(
													SEARCH_RESULT_TYPE_MAP[result.modelType],
													result.user
												)}
											/>
										))}
									</TabContent>
								)}
							</Tabs>
						</>
					)}
				</Container>
			)}
			{userModalOpen && userModalData && (
				<UserModal
					person={userModalData}
					onClose={() => {
						setUserModalOpen(false)
						setUserModalData(null)
					}}
				/>
			)}
		</SearchContainer>
	)
}

export default SearchResults
