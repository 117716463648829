import React from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import Container from 'src/components/Container'
import Sidebar from 'src/components/Sidebar'
import LayoutwithSidebar from 'src/components/LayoutWithSidebar'
import {
	Overview,
	Contents,
	AddContents,
	FAQ,
	FAQPublish,
	Team,
	News,
	QuickLinksRoutes,
	EditDepartment
} from 'src/components/Department'
import { getDepartmentRoutes } from 'src/models/departments'
import { useDepartmentDetailsQuery, DepartmentDetailsFragment } from 'shared/extended'
import { QuickLinks, QuickLink } from './styled'
import { DiscussionsList } from 'src/components/Department/Discussions'
import { useUserRoles } from 'src/components/Roles'

const DepartmentContext = React.createContext<DepartmentDetailsFragment>(null)

function getCorrectedHref(href) {
	return href.startsWith('http') ? href : `https://${href}`
}

function DepartmentRoute() {
	const { isAdmin } = useUserRoles()

	const { departmentId } = useParams()
	const { data, loading } = useDepartmentDetailsQuery({ variables: { departmentId: +departmentId } })
	if (loading) {
		return null
	}
	const department = data.department

	if (!department) {
		return <Navigate to="/" />
	}

	return (
		<DepartmentContext.Provider value={department}>
			<Container>
				<LayoutwithSidebar>
					<Sidebar logoUrl={department.logo?.publicUrl} links={getDepartmentRoutes(department, isAdmin)}>
						{department.quicklinks.length > 0 && (
							<QuickLinks>
								<h3>Quick Links</h3>
								{department.quicklinks.map((quickLink) => (
									<QuickLink
										key={`quicklink-${quickLink.title}`}
										href={getCorrectedHref(quickLink.url)}
										target="_blank"
									>
										{quickLink.title}
									</QuickLink>
								))}
							</QuickLinks>
						)}
					</Sidebar>
					<Routes>
						<Route path="/overview" element={<Overview />} />
						<Route path="/team" element={<Team />} />
						<Route path="/news/*" element={<News />} />
						<Route path="/discussions/*" element={<DiscussionsList />} />
						<Route path="/contents/:contentsId" element={<Contents />} />
						<Route path="/contents/:contentsId/:subContentsId" element={<Contents />} />
						<Route
							path="/faqs/*"
							element={
								<Routes>
									<Route path="/" element={<FAQ />} />
									<Route path="/publish" element={<FAQPublish />} />
									<Route path="/:faqId" element={<FAQPublish />} />
								</Routes>
							}
						/>
						<Route path="/edit" element={<EditDepartment />} />
						<Route path="/quick-links/*" element={<QuickLinksRoutes />} />
						<Route path="/content/publish" element={<AddContents />} />
					</Routes>
				</LayoutwithSidebar>
			</Container>
		</DepartmentContext.Provider>
	)
}

export function useDepartment() {
	return React.useContext(DepartmentContext)
}

export default DepartmentRoute
