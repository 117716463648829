import React from 'react'
import { useWeather } from 'src/components/Weather'

import { UtilityDropdownContent, WeatherTopContainer } from '../../styled'

function WeatherDropdownContent() {
	const weatherState = useWeather()
	if (weatherState.value) {
		return (
			<UtilityDropdownContent>
				<WeatherTopContainer>
					<img src={weatherState.value.iconUrl}></img>
					{weatherState.value.temp}
				</WeatherTopContainer>
				{weatherState.value.description}
			</UtilityDropdownContent>
		)
	}
	return null
}

export default WeatherDropdownContent
