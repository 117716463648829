import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDepartment } from 'src/routes/department'
import RichText from 'src/components/RichText'
import LinkCard from 'src/components/LinkCard'
import Select from 'src/components/Select'

import { ContentsWrapper, AdminActions } from './styled'
import ContentHeader from '../ContentHeader'
import { ContentsPublish } from '..'
import { useDisablePageMutation } from 'shared/schema'
import { useUserRoles } from 'src/components/Roles'
import _ from 'lodash'

function Contents() {
	const navigate = useNavigate()
	const department = useDepartment()
	const { contentsId, subContentsId } = useParams()
	const [isEditing, setIsEditing] = useState(false)
	const [disablePage] = useDisablePageMutation({
		refetchQueries: ['departmentDetails'],
		awaitRefetchQueries: true
	})
	const { isAdmin, useRole } = useUserRoles()

	const [sortIdx, setSortIdx] = useState(0)
	const sortOptions: { value: [string, 'asc' | 'desc']; label: string }[] = [
		{ value: ['resource.createdAt', 'desc'], label: 'Newest' },
		{ value: ['resource.createdAt', 'asc'], label: 'Oldest' },
		{ value: ['resource.title', 'asc'], label: 'Name' }
	]
	const [sortField, sortDir] = sortOptions[sortIdx].value

	const primaryContents = department.contents.find((content) => content.id === +contentsId)

	const content = subContentsId
		? primaryContents.contents.find((subContent) => subContent.id === +subContentsId)
		: primaryContents

	const contentResources = content?.contentResources
		? _.orderBy(content.contentResources, [sortField], [sortDir])
		: []

	useEffect(() => {
		setIsEditing(false)
	}, [content?.id])

	if (!content) {
		return null
	}

	return (
		<ContentsWrapper>
			<ContentHeader parent={subContentsId ? primaryContents : null} primaryColor={false}>
				{content.title}
			</ContentHeader>
			{isAdmin && (
				<AdminActions>
					<span onClick={() => setIsEditing(!isEditing)}>Edit</span>
					{/* TODO: implement delete */}
					<span
						onClick={async () => {
							if (confirm('Are you sure you want to delete this page?')) {
								await disablePage({
									context: {
										useRole
									},
									variables: {
										contentId: content.id
									}
								})
								navigate(`/departments/${department.id}/overview`)
							}
						}}
					>
						Delete
					</span>
				</AdminActions>
			)}
			{isEditing && (
				<div>
					<ContentsPublish existing={content} cancelEditing={() => setIsEditing(false)} />
				</div>
			)}
			{!isEditing && (
				<>
					<RichText html={content.body} />
					{contentResources.length > 0 && (
						<>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<h3>Related Resources</h3>
								<span style={{ margin: '15px 0px' }}>
									Sort results by:
									<Select onChange={(e) => setSortIdx(+e.target.value)}>
										{sortOptions.map((sortOption, index) => (
											<option key={`${sortOption.value}-${index}`} value={index}>
												{sortOption.label}
											</option>
										))}
									</Select>
								</span>
							</div>

							{contentResources.map(({ resource }, index) => {
								return <LinkCard key={`${resource.title}-${index}`} data={resource} />
							})}
						</>
					)}
				</>
			)}
		</ContentsWrapper>
	)
}

export default Contents
