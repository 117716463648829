import React from 'react'
import styled from 'styled-components'
import { CircleFill } from 'src/icons'

const LoadingCircle = styled(CircleFill)`
	height: 0.5rem;
	color: ${(props) => props.theme.colors.primary};
	animation: fader 1s infinite;
	@keyframes fader {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`

export default function (props: { loading: boolean }) {
	if (props.loading) {
		return (
			<>
				{' '}
				<LoadingCircle />
			</>
		)
	}
	return null
}
