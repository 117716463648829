import styled from 'styled-components'

export const ContentsWrapper = styled.div`
	h2 {
		margin-bottom: 15px;
		font-size: 35px;
		font-weight: 300;
	}
	p,
	li {
		color: ${({ theme }) => theme.colors.primaryTextColor};
		line-height: 1.8;
		font-weight: 300;
	}
	h3 {
		margin: 15px 0px;
		font-size: 25px;
		font-weight: 300;
	}
`

export const AdminActions = styled.div`
	padding-bottom: 10px;
	span {
		color: ${({ theme }) => theme.colors.primary};
		text-decoration: none;
		&:not(:last-child) {
			padding-right: 15px;
		}
		&:hover {
			cursor: pointer;
		}
	}
`
