import React, { forwardRef } from 'react'
import dayjs from 'dayjs'
import { Link, Route, Routes } from 'react-router-dom'
import 'react-image-lightbox/style.css'
import { useOrderedGalleriesGetAllQuery } from 'shared/extended'
import Container from 'src/components/Container'
import PageHeader from 'src/components/PageHeader'
import Button from 'src/components/Button'
import GalleryView from './view'
import GalleryForm from './form'
import {
	GalleryCard,
	GalleryCardContent,
	GalleryCardTitle,
	GalleryCardSubTitle,
	GalleryCardImage,
	GalleryButtonsContainer,
	GalleryHeaderContainer,
	Edit,
	Delete
} from './styled'
import { useDisableGalleryMutation, GalleriesGetAllQuery, useUpdateGalleryOrderMutation } from 'shared/schema'
import { useUserRoles } from 'src/components/Roles'

import { SortableGrid } from './sortable-grid'

const mutationConfig = {
	refetchQueries: ['galleriesGetAll'],
	awaitRefetchQueries: true
}
type FullGalleryCardProps = { style?: any; data: GalleriesGetAllQuery['galleries'][number] }
const FullGalleryCard = forwardRef<HTMLDivElement, FullGalleryCardProps>(
	({ style, ...props }: FullGalleryCardProps, ref) => {
		const inlineStyles = {
			height: 200,
			position: 'relative',
			...style
		}

		const [disableGallery] = useDisableGalleryMutation(mutationConfig)
		const g = props.data
		const { isGalleryAdmin: canEdit } = useUserRoles()

		return (
			<div ref={ref} style={inlineStyles} {...props}>
				<GalleryCard key={g.id}>
					<Link to={'/gallery/' + g.id} key={g.id}>
						<GalleryCardImage
							style={{
								backgroundImage: `url(${g.thumbUrl})`
							}}
						/>
						<GalleryCardContent>
							<GalleryCardTitle>{g.title}</GalleryCardTitle>
							<GalleryCardSubTitle>
								{(g.children as number[]).length} photos
								{g.date && ' on ' + dayjs(g.date).format('LL')}
							</GalleryCardSubTitle>
						</GalleryCardContent>
					</Link>
					{canEdit && (
						<GalleryButtonsContainer>
							<Link to={`${g.id}/publish`}>
								<Edit />
							</Link>
							<Delete
								onClick={() => {
									if (confirm('Are you sure you want to delete this album?')) {
										disableGallery({
											variables: {
												id: g.id
											}
										})
									}
								}}
							/>
						</GalleryButtonsContainer>
					)}
				</GalleryCard>
			</div>
		)
	}
)

function GalleryList() {
	const { data } = useOrderedGalleriesGetAllQuery()
	const { isGalleryAdmin: canEdit } = useUserRoles()

	return (
		<>
			<GalleryHeaderContainer>
				<PageHeader primaryColor={false}>Albums</PageHeader>
				{canEdit && (
					<Link to="/gallery/publish">
						<Button $autoWidth mode="secondary">
							Create New Gallery
						</Button>
					</Link>
				)}
			</GalleryHeaderContainer>
			<SortableGrid
				gridColumns={3}
				GridItemComponent={FullGalleryCard}
				reorderHook={useUpdateGalleryOrderMutation}
				reorderHookConfig={mutationConfig}
				initialItems={data?.galleries}
			/>
		</>
	)
}

function GalleryRoute() {
	return (
		<Container>
			<Routes>
				<Route path="/" element={<GalleryList />} />
				<Route path="/publish" element={<GalleryForm />} />
				<Route path="/:galleryId" element={<GalleryView />} />
				<Route path="/:galleryId/publish" element={<GalleryForm />} />
			</Routes>
		</Container>
	)
}

export default GalleryRoute
