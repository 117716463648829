import React, { useState } from 'react'
import { Formik } from 'formik'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import FormField from 'src/components/FormField'
import { useAuthGate } from 'src/components/AuthGate'
import UserProfilePhoto from 'src/components/UserProfilePhoto'
import { useAddCommentMutation } from 'shared/extended'
import { useDeleteCommentMutation, useUpdateCommentMutation } from 'shared/schema'
import { CommentDetailsFragment } from 'shared/schema'
import {
	TinyCommentsContainer,
	CommentsContainer,
	CommentContainer,
	TinyCommentContainer,
	CommenterInformation,
	CommentAction,
	AddComment,
	TinyAddComment,
	EditComment,
	Comment
} from './styled'
dayjs.extend(relativeTime)

function Comments(props: {
	comments: CommentDetailsFragment[]
	discussionId?: number
	bulletinId?: number
	nominationId?: number
	refetch: string | string[]
	tiny?: boolean
}) {
	const { user } = useAuthGate()
	const { comments, bulletinId, discussionId, nominationId } = props
	const [editingCommentId, setEditingCommentId] = useState(-1)

	const refetchArray = Array.isArray(props.refetch) ? props.refetch : [props.refetch]

	const [addCommentMutation, { loading: addCommentLoading }] = useAddCommentMutation({
		refetchQueries: refetchArray,
		awaitRefetchQueries: true
	})
	const [deleteCommentMutation] = useDeleteCommentMutation({
		refetchQueries: refetchArray,
		awaitRefetchQueries: true
	})
	const [updateCommentMutation] = useUpdateCommentMutation({
		refetchQueries: refetchArray,
		awaitRefetchQueries: true
	})

	const addComment = async (values, actions) => {
		const { comment } = values
		if (!comment.length) {
			return
		}
		await addCommentMutation({
			variables: {
				comment,
				bulletinId: +bulletinId > 0 ? +bulletinId : null,
				discussionId: +discussionId > 0 ? +discussionId : null,
				nominationId: +nominationId > 0 ? +nominationId : null
			}
		})
		actions.resetForm()
	}

	const updateComment = (values) => {
		updateCommentMutation({
			variables: {
				id: values.commentId,
				comment: values.updatedComment
			}
		})
	}

	const deleteComment = (commentId) => {
		deleteCommentMutation({ variables: { id: commentId } })
	}

	const update = (values) => {
		setEditingCommentId(-1)
		updateComment(values)
	}

	const OuterContainer = props.tiny ? TinyCommentsContainer : CommentsContainer
	const AddCommentElement = props.tiny ? TinyAddComment : AddComment

	return (
		<OuterContainer>
			{!props.tiny && <h2>Comments</h2>}

			{comments?.map((comment) => {
				const canEditComment = comment.user?.id === user?.id
				if (props.tiny) {
					return (
						<TinyCommentContainer key={comment.id}>
							<UserProfilePhoto src={comment.user?.profilePhotoUrl} />
							<span className="commenter">
								{comment.user?.firstName} {comment.user?.lastName}
							</span>
							<span className="comment">{comment.comment}</span>
						</TinyCommentContainer>
					)
				}
				return (
					<CommentContainer key={comment.id}>
						<CommenterInformation>
							<UserProfilePhoto src={comment.user?.profilePhotoUrl} />
							<h3>
								{comment.user?.firstName} {comment.user?.lastName}
							</h3>
							<h4>{comment.user?.jobTitle}</h4>
						</CommenterInformation>
						{comment.id === editingCommentId ? (
							<Formik
								initialValues={{ commentId: comment.id, updatedComment: comment.comment }}
								onSubmit={update}
							>
								<EditComment>
									<FormField name="updatedComment" type="textarea" />
									<CommentAction type="submit">Update Comment</CommentAction>
									<CommentAction onClick={() => setEditingCommentId(-1)}>Cancel</CommentAction>
								</EditComment>
							</Formik>
						) : (
							<Comment>{comment.comment}</Comment>
						)}
						{canEditComment && editingCommentId !== comment.id && (
							<div>
								<CommentAction onClick={() => setEditingCommentId(comment.id)}>Update</CommentAction>
								<CommentAction onClick={() => deleteComment(comment.id)}>Delete</CommentAction>
							</div>
						)}
						<p>
							{comment.updatedAt !== comment.createdAt && 'Edited '}
							{dayjs(comment?.updatedAt || comment?.createdAt).fromNow()}
						</p>
					</CommentContainer>
				)
			})}
			<Formik initialValues={{ comment: '' }} onSubmit={addComment}>
				<AddCommentElement>
					<div>
						{!props.tiny && <UserProfilePhoto src={user.profilePhotoUrl} />}
						<FormField
							name="comment"
							type="textarea"
							placeholder="Write a reply..."
							disabled={addCommentLoading}
						/>
					</div>
					<CommentAction type="submit">Add Comment</CommentAction>
				</AddCommentElement>
			</Formik>
		</OuterContainer>
	)
}

export default Comments
