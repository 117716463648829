import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

export const NavItemStyles = css`
	transition: all 0.1s ease-in-out;
	padding: 0px 15px;
	font-size: 13px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		color: ${({ theme }) => theme.colors.primary};
		svg {
			color: ${({ theme }) => theme.colors.primary};
		}
	}
	&.current {
		display: flex;
		color: ${({ theme }) => theme.colors.primary};
		svg {
			color: ${({ theme }) => theme.colors.primary};
		}
	}
`

export const NavItemLink = styled(NavLink)`
	${NavItemStyles}
`

export const NavItem = styled.div`
	${NavItemStyles}
	&.primary {
		line-height: 70px;
	}
`
