import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import styled from 'styled-components'
import FormField from 'src/components/FormField'
import LinkCardList from '../LinkCardList'
import { DepartmentDetailsQuery, DiscussionDetailsFragment, useUpdateDiscussionMutation } from 'shared/schema'
import { useCreateDiscussionMutation, useDeleteDiscussionMutation } from 'shared/schema'
import { useNavigate } from 'react-router'
import UserProfilePhoto from '../UserProfilePhoto'
import dayjs from 'dayjs'
import Favoriteable from '../Favoriteable'
import RichText from '../RichText'
import Comments from '../Comments'
import BodyText from '../BodyText'
import { useAuthGate } from '../AuthGate'
import { useUserRoles } from '../Roles'

type Discussion = DepartmentDetailsQuery['department']['discussions'][number]

const AuthorInformation = styled.div`
	color: #777;
	display: flex;
	align-items: center;
	img {
		width: 3rem;
		padding-left: 0px;
		padding-right: 20px;
	}
	span {
		padding-right: 10px;
	}
`

const DiscussionAction = styled.span`
	cursor: pointer;
	color: ${({ theme }) => theme.colors.primary};
`

export function DiscussionsDetail(props: { discussion: DiscussionDetailsFragment }) {
	const { discussion } = props
	const [editingDiscussion, setEditingDiscussion] = useState(false)
	const { user } = useAuthGate()
	const navigate = useNavigate()
	const [deleteDiscussion] = useDeleteDiscussionMutation({
		refetchQueries: ['discussions', 'departmentDetails'],
		awaitRefetchQueries: true
	})
	const { isAdmin, useRoleUnless } = useUserRoles()

	if (editingDiscussion) {
		return <DiscussionPublish existing={discussion} onCancel={() => setEditingDiscussion(false)} />
	}
	if (!discussion) {
		return null
	}

	return (
		<div>
			<AuthorInformation>
				<UserProfilePhoto src={discussion.author?.profilePhotoUrl} />
				<span>
					{discussion.author?.firstName} {discussion.author?.lastName}
				</span>
				<span> • </span>
				<span>{dayjs(discussion?.createdAt).format('ll')}</span>
			</AuthorInformation>
			<div style={{ paddingBottom: '2rem', paddingTop: '2rem' }}>
				<Favoriteable data={discussion} />
				{(isAdmin || discussion.author?.id === user.id) && (
					<>
						{' • '}
						{/* todo */}
						<DiscussionAction onClick={() => setEditingDiscussion(true)}>Edit</DiscussionAction>
						{' • '}
						{/* todo */}
						<DiscussionAction
							onClick={async () => {
								if (confirm('Are you sure you want to delete this discussion?')) {
									await deleteDiscussion({
										variables: { id: discussion.id },
										context: {
											useRole: useRoleUnless(discussion.author?.id === user.id)
										}
									})
									navigate('..')
								}
							}}
						>
							Delete
						</DiscussionAction>
					</>
				)}
			</div>
			<BodyText>
				<RichText html={discussion.post} />
			</BodyText>
			<Comments
				discussionId={discussion.id}
				comments={discussion.comments}
				refetch={['discussions', 'departmentDetails']}
			></Comments>
		</div>
	)
}

export function DiscussionList({
	departmentId,
	discussions = []
}: {
	departmentId?: number
	discussions: Discussion[]
}) {
	return <LinkCardList departmentId={departmentId} data={discussions} showSubtitleActions />
}

export function DiscussionPublish(
	props:
		| { departmentId?: number; existing?: never; onCancel?: never }
		| { departmentId?: never; existing: DiscussionDetailsFragment; onCancel: any }
) {
	const navigate = useNavigate()
	const isUpdate = Boolean(props.existing)

	const { user } = useAuthGate()
	const { useRoleUnless } = useUserRoles()

	const refetchConfig = {
		refetchQueries: ['discussions', 'departmentDetails'],
		awaitRefetchQueries: true
	}
	const [createDiscussion] = useCreateDiscussionMutation(refetchConfig)
	const [updateDiscussion] = useUpdateDiscussionMutation(refetchConfig)
	return (
		<Formik
			initialValues={{
				title: props.existing?.title ?? '',
				post: props.existing?.post ?? ''
			}}
			onSubmit={async (values) => {
				if (isUpdate) {
					await updateDiscussion({
						variables: {
							id: props.existing.id,
							post: values.post,
							title: values.title
						},
						context: {
							useRole: useRoleUnless(props.existing?.author?.id === user.id)
						}
					})
					props.onCancel()
				} else {
					const { data } = await createDiscussion({
						variables: {
							departmentId: props.departmentId || null,
							post: values.post,
							title: values.title
						}
					})
					navigate(`../${data.insertDiscussion.id}`)
				}
			}}
		>
			{({ values, setFieldValue }) => {
				return (
					<Form>
						<FormField name="title" type="text" placeholder="Discussion Title" label="Title" />
						<FormField
							name="body"
							type="richText"
							label="Discussion Content"
							value={values.post}
							placeholder="Write your post here..."
							onChange={(val) => setFieldValue('post', val)}
						/>
						<FormField
							type="formButtons"
							submitLabel={`${isUpdate ? 'Update' : 'Create'} discussion`}
							onCancel={props.onCancel}
						/>
					</Form>
				)
			}}
		</Formik>
	)
}
