import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Formik, Form } from 'formik'
import fileSize from 'filesize'
import FormField from 'src/components/FormField'
import Modal from 'src/components/Modal'
import LoadingDot from 'src/components/LoadingDot'

import {
	CloseIcon,
	ResourceTitle,
	ContentContainer,
	ResourceSubtitle,
	FileIcon,
	ResourceInfoContainer,
	PDFContainer,
	ActionsContainer,
	EmailIcon,
	EditIcon,
	TrashIcon,
	DownloadIcon,
	EmailResourceContainer,
	PDFAction,
	Button,
	Divider
} from './styled'
import Favoriteable from '../Favoriteable'
import { useUserRoles } from '../Roles'
import {
	useSendResourceEmailMutation,
	useUpdateResourceDataMutation,
	useUpdateResourceFileMutation,
	useDeleteResourceMutation
} from 'shared/schema'
import RichText from '../RichText'
import { useUpload } from '../Upload'

const Context = React.createContext<{
	resourceOpen: any
	setResourceOpen: any
	resource: any
	setResource: any
}>(null)

export function useResource() {
	return React.useContext(Context)
}

export function ResourceProvider({ children }: { children: React.ReactNode }) {
	const [resourceOpen, setResourceOpen] = useState(false)
	const [resource, setResource] = useState()

	return (
		<Context.Provider
			value={{
				resourceOpen,
				setResourceOpen,
				resource,
				setResource
			}}
		>
			{children}
		</Context.Provider>
	)
}

const ResourceDialog = () => {
	const [emailDialogOpen, setEmailDialogOpen] = useState(false)
	const [editingResource, setEditingResource] = useState(false)
	const { resourceOpen, setResourceOpen, resource } = useResource()
	const [sendResourceEmail] = useSendResourceEmailMutation()
	const [upload] = useUpload(false)
	const [updateResourceFile] = useUpdateResourceFileMutation()
	const [updateResourceData] = useUpdateResourceDataMutation()
	const [deleteResource, { loading: deleteLoading }] = useDeleteResourceMutation({
		refetchQueries: ['search', 'departmentDetails'],
		awaitRefetchQueries: true
	})

	const { isAdmin, useRole } = useUserRoles()

	const closeResource = () => {
		setResourceOpen(!resourceOpen)
	}

	const closeEmailDialog = () => {
		setEmailDialogOpen(!emailDialogOpen)
	}

	const createdDateString = dayjs(resource.createdAt).format('lll')

	return (
		<Modal backgroundColor="dark">
			{resourceOpen && <CloseIcon onClick={closeResource} color="white" />}
			<ContentContainer>
				<PDFContainer>
					{resource.file?.type === 'application/pdf' ? <iframe src={resource.file.url} /> : 'No Preview'}
				</PDFContainer>
				<ResourceInfoContainer>
					<FileIcon ext={resource.file?.key.split('.').pop()} />
					<ResourceTitle>{resource.title}</ResourceTitle>
					<ResourceSubtitle>{createdDateString}</ResourceSubtitle>
					<ActionsContainer>
						<a href={resource.file.url} target="_blank">
							<Button fullWidth>
								<DownloadIcon />
								Download {resource.file.size ? `(${fileSize(resource.file.size)})` : ''}
							</Button>
						</a>
						<Divider />
						{resource.description?.length > 0 && (
							<div style={{ color: '#ddd' }}>
								<RichText html={resource.description} />
								<Divider />
							</div>
						)}
						<Favoriteable data={resource} />
						<PDFAction onClick={() => setEmailDialogOpen(true)}>
							<EmailIcon />
							Email this resource
						</PDFAction>
						{isAdmin && (
							<>
								<PDFAction>
									<EditIcon />
									<span onClick={() => setEditingResource(!editingResource)}>Edit this resource</span>
								</PDFAction>
								<PDFAction
									onClick={async () => {
										if (confirm('Are you sure you want to delete this resource?')) {
											await deleteResource({
												context: {
													useRole
												},
												variables: {
													id: resource.id
												}
											})
											closeResource()
										}
									}}
								>
									<TrashIcon />
									Delete this resource
									<LoadingDot loading={deleteLoading} />
								</PDFAction>
							</>
						)}
						{emailDialogOpen && (
							<Modal showBackground={false}>
								<EmailResourceContainer>
									<CloseIcon onClick={closeEmailDialog} color="borderGray" size="small" />
									{/* TODO: implement this */}
									<Formik
										initialValues={{ email: '' }}
										onSubmit={async (values) => {
											await sendResourceEmail({
												variables: {
													to: values.email,
													resourceId: resource.id
												}
											})

											closeEmailDialog()
										}}
									>
										<Form>
											<FormField
												name="email"
												type="input"
												placeholder="Email address to send document"
												label="Email this document"
											/>
											<FormField
												type="formButtons"
												onCancel={() => closeEmailDialog()}
												hideBorderTop
											/>
										</Form>
									</Formik>
								</EmailResourceContainer>
							</Modal>
						)}
					</ActionsContainer>
					{editingResource && (
						<div
							style={{
								marginTop: '1rem',
								backgroundColor: 'white',
								padding: '1rem',
								borderRadius: '4px'
							}}
						>
							<Formik
								initialValues={{
									title: resource.title,
									file: null,
									description: resource.description ?? ''
								}}
								onSubmit={async (values) => {
									if (values.file) {
										const { uploadId } = await upload(values.file.file)
										await updateResourceFile({
											context: {
												useRole
											},
											variables: {
												id: resource.id,
												fileUploadId: uploadId
											}
										})
									}
									await updateResourceData({
										context: {
											useRole
										},
										variables: {
											id: resource.id,
											title: values.title,
											description: values.description
										}
									})

									setEditingResource(false)
								}}
							>
								{({ values, setFieldValue }) => {
									return (
										<Form>
											<FormField name="title" type="text" label="Resource Title" />
											{/* need to deal with cache invalidation to re-enable */}
											{/* <FormField name="file" type="file" label="Upload document" /> */}
											<FormField
												name="description"
												type="richText"
												label="Description"
												value={values.description}
												placeholder="Tell us about the document..."
												onChange={(val) => setFieldValue('description', val)}
											/>
											<FormField
												hideBorderTop
												type="formButtons"
												submitLabel="Save Resource"
												onCancel={() => setEditingResource(false)}
											/>
										</Form>
									)
								}}
							</Formik>
						</div>
					)}
				</ResourceInfoContainer>
			</ContentContainer>
		</Modal>
	)
}

export default ResourceDialog
