import React from 'react'
import { File, FilePowerpoint } from 'src/icons'
import { FileEarmarkPdf, FileEarmarkWord } from 'src/icons'
import { mapValues } from 'lodash'

// todo: add additional icons
export const fileExtMap = {
	pdf: FileEarmarkPdf,
	doc: FileEarmarkWord,
	docx: FileEarmarkWord,
	ppt: FilePowerpoint
}

// todo: typing on iconBuilder
export function buildFileIcon(iconBuilder: any) {
	const defaultIcon = iconBuilder(File)
	const extIcons = mapValues(fileExtMap, (sourceIcon) => iconBuilder(sourceIcon))

	return function ({ ext, ...props }: any) {
		const Icon = extIcons[ext] ?? defaultIcon
		return <Icon {...props}></Icon>
	}
}
