import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'

import { useOrderedGetGalleryQuery } from 'shared/extended'
import FormField from 'src/components/FormField'
import {
	GalleriesGetAllDocument,
	GetGalleryDocument,
	useAddGalleryPhotosMutation,
	useCreateGalleryMutation,
	useUpdateGalleryMutation,
	useGetGalleryLazyQuery,
	useUpdateGalleryPhotoOrderMutation
} from 'shared/schema'
import _ from 'lodash'
import FormLabel from 'src/components/FormLabel'
import { Delete, GalleryButtonsContainer, GalleryPhotoCard, GalleryPhotoImage } from '../styled'
import { useUserRoles } from 'src/components/Roles'
import { useUpload } from 'src/components/Upload'

const processImageBase =
	location.host === 'localhost:1234'
		? 'http://localhost:3000/api/processImage?uploadId='
		: 'https://onenibbi-v2-backend.vercel.app/api/processImage?uploadId='

function GalleryForm() {
	const { galleryId } = useParams()
	const navigate = useNavigate()
	const mutationConfig = {
		refetchQueries: [
			{ query: GalleriesGetAllDocument },
			{ query: GetGalleryDocument, variables: { id: +galleryId > 0 ? +galleryId : -1 } }
		],
		awaitRefetchQueries: true
	}

	const [updateGallery] = useUpdateGalleryMutation(mutationConfig)
	const [updateGalleryOrder] = useUpdateGalleryPhotoOrderMutation(mutationConfig)
	const [createGallery] = useCreateGalleryMutation(mutationConfig)
	const [getGallery, { data: postUpdateGalleryData }] = useGetGalleryLazyQuery()

	const [uploading, setUploading] = useState(false)
	const [newPhotos, setNewPhotos] = useState<{ [fileKey: string]: { uploadId: number; publicUrl: string } }>({})
	const { data, loading } = useOrderedGetGalleryQuery(+galleryId, !galleryId)
	const [upload] = useUpload(true)
	const [addGalleryPhotos] = useAddGalleryPhotosMutation()

	const { isGalleryAdmin } = useUserRoles()

	useEffect(() => {
		if (!isGalleryAdmin) {
			navigate('/gallery')
		}
	}, [isGalleryAdmin])

	useEffect(() => {
		const check = async () => {
			if (postUpdateGalleryData) {
				if (
					(postUpdateGalleryData.gallery.children as any).length !=
					postUpdateGalleryData.gallery.photos.length
				) {
					await updateGalleryOrder({
						variables: {
							id: postUpdateGalleryData.gallery.id,
							children: postUpdateGalleryData.gallery.photos.map((p) => p.id)
						}
					})
				}
				navigate('/gallery')
			}
		}
		check()
	}, [postUpdateGalleryData])

	if (loading) {
		return null
	}

	const prefillFormData = {
		title: data?.gallery?.title || '',
		date: data?.gallery?.date?.split('T')[0] || '',
		photos: data?.gallery?.photos || []
	}

	return (
		<Formik
			initialValues={prefillFormData}
			onSubmit={async (values) => {
				const getDate = () => {
					if (values.date?.length === 0) {
						return new Date().toISOString().substring(0, 10)
					} else {
						return values.date
					}
				}
				let newGalleryId = +galleryId
				if (galleryId) {
					if (Object.keys(newPhotos).length > 0) {
						await addGalleryPhotos({
							variables: {
								// todo, type
								objects: Object.values(newPhotos).map((newPhoto) => ({
									caption: '',
									galleryId,
									imageUploadId: newPhoto.uploadId
								}))
							}
						})
					}
					await updateGallery({
						variables: {
							id: +galleryId,
							title: values.title,
							date: getDate()
						}
					})
				} else {
					const result = await createGallery({
						variables: {
							title: values.title,
							date: getDate(),
							photos: {
								// type??
								data: Object.values(newPhotos).map((newPhoto) => ({
									caption: '',
									galleryId,
									imageUploadId: newPhoto.uploadId
								}))
							}
						}
					})
					newGalleryId = result.data.insertGallery.id
				}
				await getGallery({ variables: { id: newGalleryId } })
			}}
		>
			<Form>
				<FormField name="title" type="text" label="Title" placeholder="Gallery Title" />
				<FormField name="date" type="date" label="Date" />
				<FormField
					name="photo"
					type="file"
					label="Upload photo(s)"
					multiple
					disabled={uploading}
					onChange={async (ev) => {
						ev.persist()
						setUploading(true)

						const newUploads = _.chain(ev.target.files)
							.map((file) => ({ file, key: `${file.name}:${file.size}`, upload: null }))
							.filter((fileEntry) => !newPhotos[fileEntry.key])
							.value()

						for (const newUpload of newUploads) {
							newUpload.upload = await upload(newUpload.file)
							const processImageRequest = await fetch(processImageBase + newUpload.upload.uploadId)
							const processImageResult = await processImageRequest.json()
							newUpload.upload.publicUrl = processImageResult.thumbUrl
						}
						await setNewPhotos({
							...newPhotos,
							..._.chain(newUploads)
								.keyBy('key')
								.mapValues((newUpload) => ({
									uploadId: newUpload.upload.uploadId,
									publicUrl: newUpload.upload.publicUrl
								}))
								.value()
						})
						setUploading(false)
						ev.target.value = []
					}}
				/>
				{Object.keys(newPhotos).length > 0 && (
					<>
						<FormLabel>New photo(s)</FormLabel>
						<div
							style={{
								display: 'grid',
								gridTemplateColumns: `repeat(5, 1fr)`,
								gridGap: 8,
								padding: 8
							}}
						>
							{Object.entries(newPhotos).map(([pKey, p]) => {
								return (
									<GalleryPhotoCard key={p.uploadId}>
										<GalleryPhotoImage
											// todo: add to backend
											style={{
												backgroundImage: `url(${p.publicUrl})`
											}}
										/>

										<GalleryButtonsContainer>
											{/* <Edit onClick={() => console.log('edit')} /> */}
											<Delete
												onClick={() => {
													if (confirm('Are you sure you want to delete this photo?')) {
														setNewPhotos(_.omit(newPhotos, pKey))
													}
												}}
											/>
										</GalleryButtonsContainer>
									</GalleryPhotoCard>
								)
							})}
						</div>
					</>
				)}

				<FormField type="formButtons" isLoading={uploading} onCancel={galleryId ? '../..' : '..'} />

				{/* TODO: use upload feature when implemented */}
			</Form>
		</Formik>
	)
}

export default GalleryForm
