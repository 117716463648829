import React from 'react'
import styled from 'styled-components'

const StyledInput = styled.input`
	background-color: white;
	border: 1px solid ${({ theme }) => theme.colors.lightGray};
	padding: 10px;
	border-radius: 3px;
`

function TextInput(props) {
	return <StyledInput {...props} />
}

export default TextInput
