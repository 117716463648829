import styled, { css } from 'styled-components'
import UserProfilePhoto from '../UserProfilePhoto'
import { PinMap, Phone, TelephoneFill, Envelope } from 'src/icons'
export const Card = styled.div<{ $fullWidth: boolean; $flat?: boolean }>`
	margin-bottom: 20px;
	padding: ${(props) => (props.$flat ? undefined : `20px`)};
	position: relative;
	border: 1px solid ${({ theme, $flat }) => ($flat ? 'transparent' : theme.colors.lightGray)};
	border-radius: 3px;
	width: ${(props) => (props.$fullWidth ? '90%' : `calc(50% - 50px)`)};
	padding-bottom: ${(props) => (props.$flat ? `20px` : undefined)};
	border-bottom: ${(props) => (props.$flat ? `1px solid ${props.theme.colors.lightGray}` : undefined)};
`

export const CommentsAndReactions = styled.div`
	background-color: ${({ theme }) => theme.colors.lightGray};
	border-radius: 3px;
	padding: 16px;
	margin-top: 1rem;
`

export const SideBySide = styled.div<{ $clickable?: boolean }>`
	display: flex;
	cursor: ${(props) => (props.$clickable ? 'pointer' : undefined)};
`

export const PersonTitles = styled.div`
	margin-left: 15px;
	h1 {
		font-size: 18px;
		font-weight: 400;
		line-height: 25px;
	}
	h2 {
		font-size: 16px;
		font-weight: 300;
		line-height: 25px;
	}
	h3 {
		font-size: 16px;
		color: ${({ theme }) => theme.colors.lightestGray};
		font-weight: 300;
		line-height: 25px;
	}
	h4 {
		font-size: 14px;
		font-weight: 500;
		line-height: 25px;
	}
`

export const ProfilePhoto = styled(UserProfilePhoto)`
	height: 64px;
	width: 64px;
`

export const Divider = styled.div`
	margin: 10px 0px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`

const IconStyles = css`
	width: 15px;
	height: 15px;
	color: ${({ theme }) => theme.colors.lightestGray};
	padding-right: 10px;
`

export const LocationIcon = styled(PinMap)`
	${IconStyles}
`

export const CellIcon = styled(Phone)`
	${IconStyles}
`

export const HomePhoneIcon = styled(TelephoneFill)`
	${IconStyles}
`

export const EmailIcon = styled(Envelope)`
	${IconStyles}
`

export const ContactInfoContainer = styled.section`
	display: flex;
	flex-wrap: wrap;
`

export const ContactInfo = styled.a`
	width: 50%;
	display: flex;
	align-items: center;
	padding-top: 15px;
	color: ${({ theme }) => theme.colors.primary};
	text-decoration: none;
	font-size: 15px;
	&:hover {
		cursor: pointer;
	}
`
