import styled from 'styled-components'
import { Tab as ReactTab, TabList as ReactTabList, TabPanel as ReactTabsPanel } from 'react-tabs'

export const SearchContainer = styled.div`
	position: absolute;
	top: 110px;
	left: 0;
	width: 100%;
	height: calc(100% - 110px);
	padding-top: 10px;
	z-index: 10;
	box-shadow: inset 0px 8px 10px -10px rgb(0 0 0 / 50%);
	background-color: rgba(255, 255, 255, 0.95);
	overflow: auto;
	h1 {
		margin-top: 20px;
		font-weight: 400;
		text-align: center;
		font-size: 25px;
	}
	& > #spinner {
		display: block;
		margin: 20px auto;
	}
`
export const TabList = styled(ReactTabList)`
	display: flex;
	list-style: none;
	padding-left: 0;
`

export const Tab = styled(ReactTab)`
	padding: 15px 10px;
	margin-right: 10px;
	&:hover {
		cursor: pointer;
		color: ${({ theme }) => theme.colors.primary};
	}
	&.active {
		font-weight: 500;
		color: ${({ theme }) => theme.colors.primary};
		border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
	}
`

export const TabContent = styled(ReactTabsPanel)``
