import { Box, Stack, Typography, Grid, Link } from '@mui/material'
import { Room as LocationIcon, MuiPhone as PhoneIcon } from 'src/icons'
import { JobsiteFragment } from 'shared/schema'

interface AddressProps {
	line1: string
	line2?: string
	city: string
	stateAbbrev: string
	zipCode: string
}

function AddressView(address: AddressProps) {
	const streetAddress = `${address.line1}${address.line2 ? `, ${address.line2}` : ''}`
	const mapsLink = `https://maps.google.com/?q=${streetAddress},${address.city}+${address.stateAbbrev}+${address.zipCode}`
	return (
		<Typography component={Link} href={mapsLink} target="_blank" sx={{ lineHeight: '1.3' }}>
			{streetAddress}
			<br />
			{address.city}, {address.stateAbbrev} {address.zipCode}
		</Typography>
	)
}

function JobsitePanel({ jobsite }: { jobsite: JobsiteFragment }) {
	return (
		<Box sx={{ background: '#f3f3f3', p: 3, mb: 4 }}>
			<Stack spacing={2}>
				<Typography>
					{jobsite.title}
					{jobsite.siteNumber && (
						<Box component="span" sx={{ ml: 1, opacity: 0.5 }}>
							#{jobsite.siteNumber}
						</Box>
					)}
				</Typography>
				<Box>
					<Grid container spacing={2}>
						{jobsite.mainAddress && (
							<Grid item xs={6}>
								<Stack spacing={2}>
									<Stack direction="row" spacing={0.5}>
										<Box sx={{ pt: '1px' }}>
											<LocationIcon color="primary" />
										</Box>
										<Box>
											<Typography>Jobsite Address</Typography>
											<AddressView
												line1={jobsite.mainAddress}
												line2={jobsite.mainAddress2}
												city={jobsite.mainAddressCity}
												stateAbbrev={jobsite.mainAddressState}
												zipCode={jobsite.mainAddressZip}
											/>
										</Box>
									</Stack>
								</Stack>
							</Grid>
						)}
						{jobsite.officeAddress && (
							<Grid item xs={6}>
								<Stack spacing={2}>
									<Stack direction="row" spacing={0.5}>
										<Box sx={{ pt: '1px' }}>
											<LocationIcon color="primary" />
										</Box>
										<Box>
											<Typography>Jobsite Address</Typography>
											<AddressView
												line1={jobsite.officeAddress}
												line2={jobsite.officeAddress2}
												city={jobsite.officeAddressCity}
												stateAbbrev={jobsite.officeAddressState}
												zipCode={jobsite.officeAddressZip}
											/>
										</Box>
									</Stack>
								</Stack>
							</Grid>
						)}
					</Grid>
				</Box>
				{jobsite.phone && (
					<Stack direction="row" spacing={0.5}>
						<Box sx={{ pt: '1px' }}>
							<PhoneIcon />
						</Box>
						<Box>
							<Typography>Phone Number</Typography>
							<Typography>{jobsite.phone}</Typography>
						</Box>
					</Stack>
				)}
			</Stack>
		</Box>
	)
}

export default JobsitePanel
