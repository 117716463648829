import React from 'react'
import LinkCardList from 'src/components/LinkCardList'
import { useDepartment } from 'src/routes/department'

import styled from 'styled-components'

const SideBySide = styled.div`
	border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
	font-size: 16px;
	display: flex;
	margin-top: 20px;
	> * {
		width: 100%;
	}
`

function LatestNewsAndDiscussions() {
	const department = useDepartment()
	const { bulletins, discussions } = department
	const showSection = bulletins.length > 0 || discussions.length > 0

	return showSection ? (
		<SideBySide>
			<LinkCardList
				title="Latest News"
				departmentId={department.id}
				data={bulletins.slice(0, 3)}
				headerLink={{ title: 'See All News & Announcements', to: `/departments/${department.id}/news/` }}
			/>
			<LinkCardList
				title="Recent Discussions"
				departmentId={department.id}
				data={discussions.slice(0, 3)}
				headerLink={{ title: 'See All Discussions', to: `/departments/${department.id}/discussions/` }}
			/>
		</SideBySide>
	) : null
}

export default LatestNewsAndDiscussions
