import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { X } from 'src/icons'

export const CloseIcon = styled(X)<{ color?: string; size?: string }>`
	width: ${(props) => (props.size === 'small' ? '30px' : '45px')};
	height: ${(props) => (props.size === 'small' ? '30px' : '45px')};
	color: ${(props) => props.theme.colors[props.color]};
	top: 0;
	right: 0;
	position: absolute;
	float: right;
	padding-top: 15px;
	padding-right: 15px;
	&:hover {
		cursor: pointer;
	}
`
const getBackground = (showBackground, backgroundColor) => {
	if (!showBackground) {
		return null
	}
	return backgroundColor === 'light' ? `rgba(255, 255, 255, 0.8)` : `rgba(34, 34, 34, 0.97)`
}

export const DialogContainer = styled.div<{ $showBackground; $backgroundColor }>`
	@keyframes fadein {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	box-shadow: inset 0px 8px 10px -10px rgb(0 0 0 / 50%);
	overflow: auto;
	background-color: ${(props) => getBackground(props.$showBackground, props.$backgroundColor)};
	animation: fadein 0.3s ease;
	cursor: default;
`

const ModalContents = styled.div<{ $tall?: boolean }>`
	background-color: white;
	padding: 2rem;
	position: relative;
	width: calc(100% - 40px);
	max-width: 700px;
	transform: ${(props) => {
		const height = props.$tall ? '10vh' : '30vh'
		return `translate(calc(50vw - 390px), ${height});`
	}};
`

const ModalHeading = styled.h1`
	font-weight: lighter;
	line-height: 40px;
	color: ${(props) => props.theme.colors.primaryTextColor};
	font-size: 28px;
	padding-bottom: 20px;
`

interface IModalProps {
	showBackground?: boolean
	backgroundColor?: 'dark' | 'light'
	children?: React.ReactNode
}

const Modal = ({ showBackground = true, backgroundColor = 'light', children }: IModalProps) => {
	return (
		<DialogContainer $showBackground={showBackground} $backgroundColor={backgroundColor}>
			{children}
		</DialogContainer>
	)
}

export default Modal

export function useModal({
	children,
	backgroundColor = 'dark',
	header,
	tall,
	onClose
}: {
	children: any
	backgroundColor?: 'light' | 'dark'
	header?: string
	tall?: boolean
	onClose?: any
}): [any, React.Dispatch<React.SetStateAction<boolean>>, boolean] {
	const [isOpen, setIsOpen] = useState(false)

	const closeModal = () => {
		if (onClose) {
			onClose()
		}

		setIsOpen(false)
	}

	const upHandler = ({ key }): void => {
		if (key === 'Escape' && isOpen) {
			closeModal()
		}
	}
	useEffect(() => {
		window.addEventListener('keyup', upHandler)
		return () => {
			window.removeEventListener('keyup', upHandler)
		}
	}, [isOpen])

	const modal = isOpen ? (
		<Modal backgroundColor={backgroundColor}>
			<ModalContents $tall={tall}>
				<CloseIcon onClick={() => closeModal()} />
				{header && <ModalHeading>{header}</ModalHeading>}
				{children}
			</ModalContents>
		</Modal>
	) : null

	return [modal, setIsOpen, isOpen]
}
