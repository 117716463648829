import React from 'react'
import { useDepartment } from 'src/routes/department'
import RichText from 'src/components/RichText'
import LatestNewsAndDiscussions from './LatestNewsAndDiscussions'
import ContentHeader from '../ContentHeader'
import BodyText from 'src/components/BodyText'

function Overview() {
	const department = useDepartment()

	return (
		<BodyText>
			<ContentHeader showBreadcrumbs={false}>Mission Statement</ContentHeader>
			<RichText html={department.missionStatement} />
			<LatestNewsAndDiscussions />
		</BodyText>
	)
}

export default Overview
