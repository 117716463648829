import React from 'react'
import classNames from 'classnames'
import Search, { useSearch } from 'src/components/Search'
import { getDepartmentRoutes } from 'src/models/departments'
import NavDropdown from '../NavDropdown'
import NavLink from 'src/components/NavLink'
import { useSiteContext } from 'src/components/AppWrapper'
import { HeaderContainer, MainNav, Logo, NavLinks, PeopleSubMenu, DepartmentsSubMenu } from '../styled'
import { useUserRoles } from 'src/components/Roles'

function NavHeader() {
	const { siteMeta } = useSiteContext()
	const departments = siteMeta.departments
	const { searchOpen } = useSearch()
	const { isAdmin } = useUserRoles()

	return (
		<MainNav>
			<HeaderContainer className={classNames({ ['searchOpen']: !!searchOpen })}>
				<NavLinks>
					<NavLink to="/home" id="logoLink">
						<Logo type="dark" />
					</NavLink>
					{!searchOpen && (
						<>
							<NavDropdown
								title="Departments"
								subMenuStyle={DepartmentsSubMenu}
								content={departments.map((department) => ({
									title: department.title,
									content: getDepartmentRoutes(department, isAdmin),
									to: `/departments/${department.id}/overview`
								}))}
							/>

							<NavLink title="News & Announcements" to="/news" primary />

							<NavDropdown
								title="People"
								subMenuStyle={PeopleSubMenu}
								content={[
									{ title: 'Company Directory', to: '/company-directory' },
									{ title: 'Org Chart', to: '/org-chart' }
								]}
							/>

							<NavLink title="Discussions" to="/discussions" primary />
							<NavLink title="Gallery" to="/gallery" primary />
							<NavLink title="Calendar" to="/calendar" primary />
						</>
					)}
				</NavLinks>
				<Search />
			</HeaderContainer>
		</MainNav>
	)
}

export default NavHeader
