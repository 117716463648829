import React from 'react'
import { useLocation } from 'react-router-dom'
import { NavItemLink, NavItem } from './styled'
import classNames from 'classnames'

interface NavLinkProps {
	title?: string
	children?: React.ReactNode
	to?: string
	id?: string
	sublinkIsActive?: boolean
	primary?: boolean
}

function NavLink({ title, children, to, id, sublinkIsActive, primary }: NavLinkProps) {
	// logic to highlight the Departments and People nav items when one of their sub-routes is the active route
	// there's probably a better way to do this
	const location = useLocation()
	const currentLocationRoute = location.pathname.split('/')[1]
	const currentTitle = title && title.toLowerCase()
	const peopleDropdownLinks = ['org-chart', 'company-directory']

	const activeNavDropdown =
		(primary && currentLocationRoute === currentTitle) ||
		(currentTitle === 'people' && peopleDropdownLinks.includes(currentLocationRoute))

	const isDetailState = location.pathname.split('/').pop().match(/\d+/)?.length > 0
	const isParentActive =
		location.pathname.startsWith(to + '/') &&
		isDetailState &&
		location.pathname.split('/').length != to.split('/').length

	if (!to) {
		return (
			<NavItem
				className={classNames({ ['current']: activeNavDropdown || sublinkIsActive, ['primary']: primary })}
			>
				{title}
				{children}
			</NavItem>
		)
	}

	return (
		<NavItemLink
			to={to}
			id={id}
			activeClassName="current"
			className={classNames({
				['current']: activeNavDropdown || sublinkIsActive || isParentActive,
				['primary']: primary
			})}
			end
		>
			{title}
			{children}
		</NavItemLink>
	)
}

export default NavLink
