import styled, { css } from 'styled-components'
import { CaretDownFill, CaretUpFill } from 'src/icons'

export const AccordianWrapper = styled.div`
	font-size: 20px;
	padding: 20px 0px;
	border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
	&:last-child {
		border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
	}
`

export const Preview = styled.div`
	color: ${({ theme }) => theme.colors.primary};
	&:hover {
		cursor: pointer;
	}
	&.expanded {
		color: ${({ theme }) => theme.colors.primaryTextColor};
	}
`

export const Expanded = styled.div`
	transition: all 350ms ease;
	overflow: hidden;
	max-height: 0px;
	color: ${({ theme }) => theme.colors.lightestGray};
	font-size: 18px;
	line-height: 1.5;
	&.expanded {
		padding-top: 15px;
		overflow: auto;
		max-height: 400px;
	}
`

const IconStyles = css`
	width: 10px;
	height: 10px;
	padding-left: 15px;
	&.expanded {
	}
`

export const ArrowDown = styled(CaretDownFill)`
	color: ${({ theme }) => theme.colors.lightestGray};
	${IconStyles}
`
export const ArrowUp = styled(CaretUpFill)`
	color: ${({ theme }) => theme.colors.primaryTextColor};

	${IconStyles}
`
