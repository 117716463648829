import { useState } from 'react'
import { useInterval } from 'react-use'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(LocalizedFormat)

function useCurrentDate() {
	const [currentDate, setCurrentDate] = useState(dayjs())
	useInterval(() => {
		setCurrentDate(dayjs())
	}, 1000)
	return {
		dateStamp: currentDate.format('ll'),
		timeStamp: currentDate.format('LTS'),
		moment: currentDate
	}
}

export default useCurrentDate
