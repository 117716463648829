import React from 'react'
import { Formik, Form } from 'formik'
import styled from 'styled-components'

import FormField from 'src/components/FormField'
import dayjs from 'dayjs'
import Select from 'react-select'
import { useSiteContext } from 'src/components/AppWrapper'
import { FormFieldWrapper } from 'src/components/FormField/styled'
import FormLabel from 'src/components/FormLabel'
import { useUpload } from 'src/components/Upload'
import {
	BulletinDetailsFragment,
	useAddBulletinDepartmentsMutation,
	useAddBulletinMutation,
	useRemoveBulletinDepartmentsMutation,
	useUpdateBulletinMutation,
	useRefetchBulletinMutation,
	useAddResourceMutation,
	useAddBulletinsResourceMutation,
	useDeleteBulletinsResourceMutation
} from 'shared/schema'
import { useNavigate } from 'react-router'
import { difference, map } from 'lodash'
import { useUserRoles } from 'src/components/Roles'
import { useAddResources } from 'src/components/AddResources'

const RichTextField = styled(FormField)`
	width: 94%;
`

function NewsPublish({ departmentId, existing }: { departmentId?: number; existing?: BulletinDetailsFragment }) {
	const { siteMeta } = useSiteContext()
	const [upload] = useUpload()
	const refetchConfig = {
		refetchQueries: ['departmentDetails', 'bulletins'],
		awaitRefetchQueries: true
	}
	const [addBulletin] = useAddBulletinMutation(refetchConfig)
	const [addBulletinDepartments] = useAddBulletinDepartmentsMutation(refetchConfig)
	const [updateBulletin] = useUpdateBulletinMutation(refetchConfig)
	const [refetchBulletin] = useRefetchBulletinMutation(refetchConfig)
	const [removeBulletinDepartments] = useRemoveBulletinDepartmentsMutation(refetchConfig)
	const navigate = useNavigate()
	const { useRole } = useUserRoles()
	const [addResourcesFormFields, addingResourceModal, resourceActions] = useAddResources(
		map(existing?.bulletinResources ?? [], 'resource')
	)

	const [addResource] = useAddResourceMutation()
	const [addBulletinResource] = useAddBulletinsResourceMutation()
	const [deleteBulletinResource] = useDeleteBulletinsResourceMutation()

	const departments = siteMeta.departments
	const options = departments.map((d) => {
		return {
			value: d.id,
			label: d.title
		}
	})
	const initialDepartments = existing?.id
		? options.filter((o) => existing.bulletinDepartments.map((bd) => bd.departmentId).includes(o.value))
		: departmentId > 0
		? options.filter((o) => o.value === departmentId)
		: []
	return (
		<>
			<Formik
				initialValues={{
					title: existing?.title ?? '',
					date: dayjs(existing?.createdAt).format('YYYY-MM-DD'),
					departments: initialDepartments,
					global: existing?.global ?? !(departmentId > 0),
					articleImage: null,
					postContent: existing?.post ?? ''
				}}
				onSubmit={async (values) => {
					let articleUploadId = null
					if (values.articleImage) {
						articleUploadId = (await upload(values.articleImage.file)).uploadId
					}

					let bulletinId = 0

					if (existing) {
						bulletinId = (
							await updateBulletin({
								context: {
									useRole
								},
								variables: {
									id: existing.id,
									global: values.global,
									leadImageUploadId: articleUploadId ?? existing.leadImageUploadId,
									post: values.postContent,
									title: values.title
								}
							})
						).data.updateBulletinById.id
					} else {
						bulletinId = (
							await addBulletin({
								context: {
									useRole
								},
								variables: {
									global: values.global,
									leadImageUploadId: articleUploadId,
									post: values.postContent,
									title: values.title
								}
							})
						).data.insertBulletin.id
					}
					const originalDepartmentIds = map(initialDepartments, 'value')
					const departmentIds = map(values.departments, 'value')
					const departmentsToAdd = difference(departmentIds, existing ? originalDepartmentIds : [])
					const departmentsToRemove = difference(originalDepartmentIds, departmentIds)

					if (departmentsToAdd.length) {
						await addBulletinDepartments({
							context: {
								useRole
							},
							// this type error is due to the forced Maybe -> T mapping and cannot be avoided
							variables: {
								objects: departmentsToAdd.map((deptId) => ({
									bulletinId: bulletinId,
									departmentId: deptId
								}))
							}
						})
					}
					if (departmentsToRemove.length) {
						await removeBulletinDepartments({
							context: {
								useRole
							},
							variables: {
								bulletinId: bulletinId,
								departmentIds: departmentsToRemove
							}
						})
					}

					const resourceWork = map(resourceActions, async (resourceAction) => {
						if (resourceAction.action === 'remove') {
							return deleteBulletinResource({
								context: {
									useRole
								},
								variables: {
									bulletinId: bulletinId,
									resourceId: resourceAction.data.resourceId
								}
							})
						} else if (resourceAction.action === 'new') {
							const newResource = await addResource({
								context: {
									useRole
								},
								variables: {
									title: resourceAction.data.title,
									fileUploadId: resourceAction.data.uploadId,
									description: resourceAction.data.description
								}
							})
							return addBulletinResource({
								context: {
									useRole
								},
								variables: {
									bulletinId: bulletinId,
									resourceId: newResource.data.insertResource.id
								}
							})
						} else if (resourceAction.action === 'add') {
							return addBulletinResource({
								context: {
									useRole
								},
								variables: {
									bulletinId: bulletinId,
									resourceId: resourceAction.resource.id
								}
							})
						}
					})
					if (resourceWork.length) {
						await Promise.all(resourceWork)
						await refetchBulletin({
							context: {
								useRole
							},
							variables: {
								id: bulletinId,
								global: values.global
							}
						})
					}

					navigate('..')
				}}
			>
				{({ values, setFieldValue }) => {
					return (
						<Form>
							<FormField name="title" type="text" placeholder="News Announcement Title" label="Title" />
							<FormField name="date" type="date" placeholder="Select Date" label="Date" />
							<FormField name="global" type="checkbox" label="Make this a global bulletin" />
							<FormFieldWrapper>
								<FormLabel>Departments</FormLabel>
								<Select
									options={options}
									isMulti
									onChange={(value) => setFieldValue('departments', value)}
									defaultValue={values.departments}
								/>
							</FormFieldWrapper>
							<FormField
								name="articleImage"
								type="file"
								label="Article Image"
								image={{ aspect: 150 / 100, existing: existing?.leadWebUrl }}
							/>
							<RichTextField
								name="postContent"
								type="richText"
								label="Post Content"
								value={values.postContent}
								onChange={(val) => setFieldValue('postContent', val)}
							/>
							{addResourcesFormFields}
							<FormField
								type="formButtons"
								submitLabel={`${existing?.id ? 'Save' : 'Create'} News Announcement`}
								autoWidth
							/>
						</Form>
					)
				}}
			</Formik>
			{addingResourceModal}
		</>
	)
}

export default NewsPublish
