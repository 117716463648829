import React, { useEffect } from 'react'
import Container from 'src/components/Container'
import { useLocation, useParams } from 'react-router-dom'

function WIP() {
	return <div>Work In Progress</div>
}

export function Route(props: { title?: string } = {}) {
	const location = useLocation()
	const params = useParams()
	return (
		<Container>
			<h1>{props.title || ''}</h1>
			{WIP()}
			<pre>Location: {JSON.stringify(location, null, 2)}</pre>
			<pre>Params: {JSON.stringify(params, null, 2)}</pre>
		</Container>
	)
}
export default WIP
